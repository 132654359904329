export default {
  admin: {
    disclaimer: "Aviso Legal",
    enterText: "Digite um texto",
    operations: {
      bulkPriceDataUpdate: {
        benchmarks: "Benchmarks",
        benchmarkName: "Nome do benchmark",
        products: "Produtos",
        productName: "Nome do produto",
        metaInfo: {
          title: "Admin - Operações - Atualização de Preços em Massa",
        },
        dashboard: {
          createdAt: "Criado em",
          createdBy: "Criado por",
          downloadFile: "Descarregar ficheiro",
          fileDownloadError: "Erro ao baixar o documento, por favor, entre em contato conosco.",
          loadingOperations: "Carregando operações ...",
          newUpdate: "Nova atualização...",
          refreshButton: "Atualizar",
          status: "Status",
          uploadedFile: "Arquivo carregado",
          statuses: {
            failed: "Falhou",
            pending: "Pendente",
            running: "Em execução",
            succeeded: "Concluído",
          },
        },
        dryRunError: {
          backToUploadCsvButton: "Voltar para carregar CSV",
          csvParsingErrorTitle: "Erro de Análise do CSV",
          csvParsingErrorText:
            "O arquivo fornecido não é um arquivo CSV válido ou não está no formato correto. Verifique se todas as linhas contêm valores, se as colunas estão corretamente ordenadas e se não há valores ausentes.",
          numberFormatErrorTitle: "Erro de Formato de Número",
          numberFormatErrorText:
            "Os números em seu arquivo não puderam ser analisados. Verifique se os números seguem o formato correto: use apenas caracteres numéricos padrão e um ponto (.) para casas decimais. Evite qualquer outro caractere especial.",
          duplicateDateErrorTitle: "Erro de Data Duplicada",
          duplicateDateErrorText: `Os seguintes IDs contêm datas duplicadas. Cada ID deve ter datas únicas para cada entrada.`,
          unknownIdsTitle: "IDs Desconhecidos",
          unknownIdsText: `Os seguintes IDs não são reconhecidos. Verifique se apenas IDs válidos das tabelas desta página são usados.`,
          unauthorizedTitle: "IDs Não Autorizados",
          unauthorizedText: `Os seguintes IDs não estão permitidos para atualizações.`,
        },
        preparationAndDryRun: {
          backToDashboardButton: "Voltar ao Dashboard",
          idToUseInCsv: "ID para usar no CSV",
          uploadCsvForPreview: "Carregar CSV para pré-visualização...",
          title: "Carregar CSV para Visualização de Dados",
          csvUploadDialog: {
            acceptText: "Gerar Visualização",
            title: "Carregar CSV para Visualização",
            text: "Selecione um arquivo CSV para visualizar as atualizações de preços e benchmarks. Esta simulação permitirá que você revise as alterações na próxima tela antes de finalizá-las.",
          },
          explanation: {
            el1: 'A coluna ID deve conter valores encontrados na coluna "ID para usar no CSV" nas tabelas abaixo.',
            el2: "A coluna DATE deve seguir o formato AAAA-MM-DD (por exemplo, 2023-10-15, 1999-01-31).",
            el3: "O PRICE deve ser um valor decimal.",
            para1:
              "Para atualizar preços e benchmarks em massa, faça o upload de um arquivo CSV com três colunas: ID, DATE e PRICE. A ordem e a presença dos cabeçalhos devem ser respeitadas no CSV.",
            para2:
              "Todos os preços que pode atualizar, quer se trate dos preços de um produto ou de uma referência, são apresentados no(s) quadro(s) abaixo. Se faltar algo que espera ver, contacte-nos.",
            tip: '<em>Dica</em>: Clique <a href="/sample-bulk-price-data-update.csv" download><u>aqui</u></a> para baixar um arquivo CSV de exemplo como referência.',
          },
        },
        reviewAndConfirm: {
          backToUploadCsvButton: "Voltar para carregar CSV",
          confirmAndApplyChangesButton: "Confirmar e Aplicar Alterações",
          firstDate: "Primeira data",
          id: "ID",
          lastDate: "Última data",
          name: "Nome",
          numberOfRows: "Número de linhas",
          reviewAndConfirmText:
            "Segue-se um resumo das actualizações a aplicar. Tenha em atenção que os preços programados para atualização substituirão integralmente os preços existentes, uma vez que os preços antigos serão eliminados antes de serem inseridos os novos. Depois de rever as alterações, confirme no final.",
          title: "Revisar e Confirmar Atualizações de Dados",
          successDialog: {
            title: "Operação lançada",
            text: "A operação foi lançada com sucesso!",
          },
        },
      },
      generateFactsheets: {
        allLanguages: "Todos os idiomas",
        allProducts: "Todos os produtos",
        createdAt: "Criado em",
        factsheetGeneration: "Geração de ficha técnica",
        factsheetGenerationFilters: "Filtros de geração de ficha técnica",
        fileName: "Nome do arquivo",
        generationNotPossibleDate: "A data selecionada é anterior à data de criação do produto.",
        generationNotPossibleLang: "Este idioma não está disponível para o produto selecionado.",
        generationPossible: "Geração de ficha técnica possível.",
        generationWarningFileExists:
          "Já existe um arquivo com este nome e idioma. Continuar irá sobrescrever o arquivo existente.",
        generateSelectedFactsheets: "Gerar fichas técnicas selecionadas",
        generationStatus: "Status da geração",
        backToDashboardButton: "Voltar ao painel",
        backToFiltersButton: "Voltar aos filtros",
        language: "Idioma",
        lastDateToInclude: "Última data a incluir",
        lastDayOfData: "Último dia de dados",
        loadingOperations: "Carregando operações...",
        newButton: "Gerar novas fichas técnicas...",
        preparingFactsheetsGeneration: "Preparando a lista de fichas técnicas para geração...",
        product: "Produto",
        refreshButton: "Atualizar",
        requireLogin: "Login obrigatório para acesso",
        selectFactsheetsForGeneration: "Selecionar Fichas Técnicas para Geração",
        selectLanguages: "Selecionar idiomas",
        selectProducts: "Selecionar produtos",
        setAsLastFactsheet: "Definir como última ficha técnica do produto",
        showAvailableFactsheet: "Mostrar fichas técnicas disponíveis",
        status: "Status",
        langMultiselect: {
          placeholder: "Selecione idiomas para incluir",
          selectLabel: "Pressione Enter para selecionar",
          deselectLabel: "Pressione Enter para remover",
          selectedLabel: "Selecionado",
          limitText: (ctx) => `e mais ${ctx.named("count")}`,
        },
        metaInfo: {
          title: "Admin - Operações - Gerar Fichas Técnicas",
        },
        productsMultiselect: {
          placeholder: "Selecione produtos para incluir",
          selectLabel: "Pressione Enter para selecionar",
          deselectLabel: "Pressione Enter para remover",
          selectedLabel: "Selecionado",
          limitText: (ctx) => `e mais ${ctx.named("count")}`,
        },
        statuses: {
          failed: "Falhou",
          pending: "Pendente",
          running: "Em execução",
          succeeded: "Concluído",
        },
        successDialog: {
          title: "Operação criada",
          text: "A operação foi lançada com sucesso!",
        },
      },
      bulkComponentUpdate: {
        action: "Ação",
        actionIcons: {
          add: "Adicionar",
          edit: "Editar",
          error: "Erro",
        },
        backToDashboardButton: "Voltar ao painel",
        bulkUpdate: "Carregue o CSV e veja as potenciais alterações...",
        bulkUpdateShortDesc:
          "Adicionar ou atualizar componentes genéricos através da importação de CSV do seu dispositivo",
        bulkUpdateDesc: {
          action: "Forneça o CSV para atualizar ou adicionar os componentes genéricos.",
          instructions:
            "O CSV deve ter 7 colunas que correspondem à ID do produto, ID do componente, tipo de componente, idioma, chave, valor e página, por ordem.",
          trigger:
            "Depois de carregar o ficheiro CSV, poderá rever os componentes que serão adicionados ou modificados. Se estiver satisfeito com as alterações propostas, poderá confirmar para finalizar as actualizações dos componentes.",
        },
        componentId: "ID do componente",
        createdAt: "Criado às",
        createdBy: "Criado por",
        errorsIntro: "Eis a lista de regras que o CSV viola:",
        errorListTitle: "O ficheiro CSV fornecido contém erros",
        importing: {
          sucessfulImport: "Os componentes são actualizados.",
          uknownFailure: "Não importação de componentes.",
        },
        importComponentsButton: "Confirmar e aplicar alterações",
        key: "Chave",
        lang: "Língua",
        loadingOperations: "Carregando operações...",
        loadingPreview: "As modificações estão a ser carregadas",
        metaInfo: {
          title: "Admin - Operações - Atualização de componentes a granel",
        },
        numComponents: "Número de componentes",
        numElements: "Número de elementos",
        page: "Página",
        previewTitle: "Síntese das alterações a efetuar",
        product: "Produto",
        rules: {
          allowedLang: "A língua deve ser uma língua existente para o produto correspondente.",
          columns:
            "Devem existir 7 colunas pela seguinte ordem: ID do produto, ID do componente, tipo de componente, idioma, chave, valor, página.",
          invalidFile:
            "O ficheiro CSV não pode ser analisado. Certifique-se de que tem o ficheiro CSV correto.",
          keyDuplication: "As chaves não podem ser duplicadas.",
          linking: "Os componentes ligados não podem ser actualizados simultaneamente.",
          multipleTypes: "Vários tipos especificados para o mesmo componente.",
          naValue: "O CSV fornecido não pode conter valores NaN.",
          nonEditableComponent:
            "Um componente não pode ser editado porque é um componente predefinido ou porque o tipo de componente especificado está errado.",
          nonFloatValue: "A coluna do valor, no caso dos gráficos, tem de conter números.",
          numRowsPerLang: "Deve haver o mesmo número de linhas para cada língua.",
          productExists: "Os IDs dos produtos devem ser válidos.",
          unsupportedPage: "As páginas de componentes permitidas são: 'overview' e 'factsheet'.",
          unsupportedType:
            "Os tipos de componentes permitidos são: 'bar-chart', 'pie-chart', 'donut-chart', 'table'",
        },
        type: "Tipo",
        value: "Valor",
      },
      overrideHoldingData: {
        exportCsv: "Exportar CSV de dados da exploração",
        importCsv: "Importar CSV com substituições",
        onlyShowOverride: "Mostrar apenas linhas com valores alterados:",
        override: (ctx) => `Substituições: ${ctx.named("value")}`,
        saveChanges: "Salvar todas as alterações",
        saveSuccess: "As alterações nos dados de posições foram salvas com sucesso.",
        textUniqueKeys:
          "Esta tabela exibe os valores básicos dos dados de posições para todos os produtos disponíveis na plataforma. Cada entrada única é identificada por uma combinação de 'Nome da posição', 'Moeda' e 'ISIN'. Se esta chave única for compartilhada entre vários produtos, ela aparecerá como uma única entrada aqui.",
        textHowToSingleOverride:
          "Para visualizar ou modificar valores alterados de uma linha, clique nela para expandir os detalhes. Quaisquer valores alterados que diferem dos valores básicos serão destacados: texto verde indica alterações já salvas no sistema, enquanto texto amarelo destaca alterações não salvas.",
        textHowToBulkOverride:
          "Se você deseja fazer alterações em massa, é recomendável baixar os dados atuais como um arquivo CSV. Em seguida, você pode fazer as alterações em uma ferramenta de planilhas (por exemplo, Microsoft Excel) e reimportar o arquivo CSV modificado nesta página. Qualquer CSV carregado exibirá primeiro uma pré-visualização das alterações propostas, e será necessário salvar para aplicá-las.",
        title: "Dados de posições com alterações",
        unsavedChangesAlert:
          "Você tem alterações não salvas. Se sair sem salvar, elas serão perdidas.",
        columns: {
          assetClass: "Classe de ativo",
          assetClassOverride: "Alteração da classe de ativo",
          currency: "Moeda",
          currencyOverride: "Alteração da moeda",
          holdingName: "Nome da posição",
          holdingNameOverride: "Alteração do nome da posição",
          instrumentType: "Tipo de instrumento",
          instrumentTypeOverride: "Alteração do tipo de instrumento",
          invalidValue: "Valor inválido",
          isin: "ISIN",
          isinOverride: "Alteração do ISIN",
          region: "Região",
          regionOverride: "Alteração da região",
          rowIndex: "Índice da linha",
          sector: "Setor",
          sectorOverride: "Alteração do setor",
        },
        uploadCsvErrorsDialog: {
          close: "Fechar",
          nonExistentAssetClass: "Classe de ativo inexistente",
          nonExistentKey: "Chaves inexistentes",
          nonExistentInstrumentType: "Tipo de instrumento inexistente",
          nonExistentRegion: "Região inexistente",
          nonExistentSector: "Setor inexistente",
          selectErrorType: "Selecionar tipo de erro:",
          text: "O arquivo CSV fornecido contém erros. Caso existam chaves inexistentes (uma chave sendo a combinação de nome da posição, ISIN e moeda), verifique se há erros de digitação nesses campos. Caso existam valores desconhecidos nas colunas 'assetClassOverride', 'instrumentTypeOverride', 'sectorOverride' ou 'regionOverride', revise os valores disponíveis para estas colunas.",
          title: "Erros de validação de CSV",
        },
        uploadCsvDialog: {
          cancel: "Cancelar",
          import: "Importar",
          text: "Por favor, importe um ficheiro CSV no formato pretendido. Pode exportar o ficheiro CSV para ver as colunas esperadas.",
          title: "Importar CSV com substituições",
        },
      },
    },
    platformSettings: {
      platformSettings: "Configurações da Plataforma",
      complianceTab: {
        checksActivated: "Verificações ativadas",
        editTitle: "Editar conformidade",
        title: "Conformidade",
        investorProfile: {
          disclaimerTooltip:
            "Este texto será mostrado aos usuários que acessarem a Plataforma e forem solicitados a selecionar seu perfil de investidor (tipo e país). Certifique-se de escrever um aviso legal apropriado.",
          goToInvestorProfilePage: "Abrir página",
          seeInvestorProfilePage: "Ver página do perfil do investidor",
          showLogoOnInvestorProfile: "Mostrar logotipo na página do perfil do investidor",
          title: "Perfil do investidor",
          tooltip:
            'Você pode ativar as verificações de perfil do investidor, o que significa que, ao acessar sua Plataforma pela primeira vez, o usuário precisará selecionar seu tipo de investidor (varejo, profissional, institucional), além de seu país. Depois, você poderá configurar qual tipo de investidor e de qual país está autorizado a ver cada produto/classe de ações; isso pode ser feito na seção Admin > Produtos, na aba "Restrições de acesso", após ativar as verificações aqui.',
        },
        notify: {
          saveSuccessText: "Suas configurações de conformidade foram atualizadas com sucesso.",
          saveSuccessTextWithLang: (ctx) =>
            `Suas configurações de conformidade foram atualizadas com sucesso (idioma: ${ctx.named("lang")}).`,
        },
        ombudsman: {
          title: "Texto do Ombudsman",
          tooltip:
            "Se precisar de uma referência ao seu ombudsman nesta Plataforma, escreva o texto que será mostrado aos usuários que consultarem seu ombudsman. Um link para este texto estará visível no rodapé da Plataforma, próximo à Política de Privacidade.",
        },
        productDisclaimer: {
          title: "Aviso legal padrão do produto",
          tooltip:
            'Defina um aviso legal padrão do produto que será usado para todos os produtos que não possuírem um aviso legal específico. Você pode definir um aviso legal específico para o produto em "Admin > Produtos", selecionando o produto de sua escolha e definindo o aviso legal específico na aba "Geral".',
        },
      },
      rolesTab: {
        editTitle: "Editar funções",
        title: "Funções",
        description:
          'Por padrão, apenas o papel "admin" existe, uma função que concede direitos administrativos sobre todos os produtos e acesso às configurações da plataforma. Pode ser útil ter funções mais detalhadas, por exemplo, concedendo a alguns usuários acesso administrativo apenas a determinados produtos. Para criar essas funções, use a tabela abaixo. Observe que, após salvar as funções, você precisará ir à página {0} para atribuir as funções aos usuários de sua escolha.',
        table: {
          roleNameHeader: "Nome da função",
          productsHeader: "Produtos com direitos de administrador",
          removeRoleTooltip: "Remover função",
          addRoleTooltip: "Adicionar função",
          productsMultiselect: {
            placeholder: "Selecione o produto",
            selectLabel: "Pressione Enter para selecionar",
            deselectLabel: "Pressione Enter para remover",
            selectedLabel: "Selecionado",
            limitText: "e mais {count}",
          },
        },
        notify: {
          saveSuccessText: "Suas funções foram atualizadas com sucesso.",
        },
      },
      metaInfo: {
        title: "Admin - Configurações da Plataforma",
      },
    },
    products: {
      contactUsToFixError:
        "Por favor, entre em contato conosco usando o formulário de contato para corrigir este erro!",
      langTabError: "Alguns campos estão inválidos no idioma:",
      overwriteDataAllShareClasses: "Sobrescrever dados de todas as classes de ações",
      tabError: "Alguns campos estão inválidos",
      unsavedChangesAlert:
        "Você tem alterações não salvas. Se sair sem salvar, elas serão perdidas.",
      accessRestrictionsTab: {
        allCountries: "Todos os países",
        countriesAllowedForInstitutionalInvestors:
          "Países permitidos para investidores institucionais",
        countriesAllowedForProfessionalInvestors:
          "Países permitidos para investidores profissionais",
        countriesAllowedForRetailInvestors: "Países permitidos para investidores de varejo",
        editAccessRestrictions: "Editar restrições de acesso",
        title: "Restrições de acesso",
        warningEnablingAccessRestrictions:
          'Para poder modificar as restrições de acesso, ative as verificações de perfil de investidor em {0}, na aba "Conformidade".',
      },
      contactsTab: {
        addAnyPerson: "Adicione qualquer pessoa que você deseja mostrar na sua ficha técnica.",
        additionalText: "Texto adicional",
        email: "E-mail",
        imageSquare: "Imagem (quadrada)",
        name: "Nome",
        phone: "Telefone",
        role: "Função",
        title: "Contatos",
      },
      detailsTab: {
        editDetails: "Editar detalhes",
        key: "Chave",
        showOnFactsheet: "Mostrar na Ficha Técnica",
        title: "Detalhes",
        value: "Valor",
      },
      dialog: {
        saveFieldInAllShareClasses: {
          title: "Sobrescrever",
          text: "Você vai sobrescrever este campo em todas as outras classes de ações deste produto, não apenas na classe de ações atual. Tem certeza?",
        },
        saveInAllShareClasses: {
          title: "Sobrescrever",
          text: (ctx) =>
            `Você vai sobrescrever os dados de "${ctx.named(
              "tabName"
            )}" de todas as outras classes de ações deste produto com os dados de "${ctx.named(
              "tabName"
            )}" desta classe de ações (não apenas as últimas alterações que você fez). Tem certeza?`,
        },
      },
      followersTab: {
        addedByAdmin: "Adicionado pelo administrador",
        addFollowerButton: "Adicionar um seguidor",
        email: "E-mail",
        exportCsv: "Exportar como CSV",
        importCsv: "Importar do CSV",
        noDataText: "Ninguém está seguindo este produto",
        product: "Produto",
        removeFollowerTooltip: "Remover seguidor para o produto correspondente",
        sendEmail: "Enviar um e-mail",
        showAnyShareClass: "Mostrar seguidores de qualquer classe de ações",
        title: "Seguidores",
        addFollowerPrompt: {
          email: "E-mail do seguidor",
          emailAlreadyExists: "Alguém com este endereço de e-mail já está seguindo este produto.",
          file: "Arquivo CSV",
          language: "Idioma do seguidor",
          text: "Antes de adicionar um seguidor manualmente, você precisa garantir que ele concorda em receber as informações que você planeja enviar.",
          titleAddFollower: "Adicionar seguidor",
          titleImportFollowers: "Importar seguidores",
        },
        notify: {
          newFollowerAddedSuccess: "O novo seguidor foi adicionado com sucesso.",
          newFollowerAddedError:
            "Houve um problema ao adicionar o novo seguidor. Tente novamente ou entre em contato conosco se o problema persistir.",
          noDataInCsv: "Nenhum e-mail para adicionar (após filtrar e-mail(s) já presente(s)).",
          noEmailHeader:
            "Seu arquivo está faltando um cabeçalho de coluna representando os e-mails. Os cabeçalhos de coluna aceitos são os seguintes (sem distinção de maiúsculas e minúsculas): email ou e-mail.",
          followerRemovedSuccess: "O seguidor foi removido com sucesso.",
          followerRemovedError:
            "Houve um problema ao remover o seguidor. Tente novamente ou entre em contato conosco se o problema persistir.",
          wrongFormatEmails: (ctx) =>
            `Seu arquivo contém e-mail(s) em formato incorreto: ${ctx.named("emails")}`,
        },
        removeFollowerPrompt: {
          acceptText: "Remover seguidor",
          text: (ctx) =>
            `Tem certeza de que deseja remover "${ctx.named(
              "followerEmail"
            )}" de seguir o produto "${ctx.named("productName")}"?`,
          title: "Remover seguidor",
        },
      },
      generalTab: {
        editGeneralField: "Editar campo geral",
        hidden: "Oculto",
        hiddenProductTooltip: "Ocultar o produto na plataforma para usuários não administradores.",
        icon: "Ícone",
        isin: "ISIN",
        title: "Geral",
      },
      holdingComponentsTab: {
        barChart: "Gráfico de barras",
        data: "Dados",
        date: "Data",
        donutChart: "Gráfico de rosca",
        header: "Cabeçalho",
        key: "Chave",
        loadingData: "Carregando dados...",
        noData: "Este produto não possui dados de participações.",
        pieChart: "Gráfico de pizza",
        shownOnOverview: "Mostrado na visão geral",
        table: "Tabela",
        title: "Componentes de participações",
        type: "Tipo",
        weightPercent: "Peso (em %)",
        componentTitle: {
          assetClasses: "Classes de ativos",
          currencyExposure: "Exposição cambial",
          instrumentTypes: "Tipos de instrumentos",
          regions: "Regiões",
          sectors: "Setores",
          topHoldings: "Principais participações",
        },
      },
      holdingDataTab: {
        delete: "Excluir",
        deleteSuccessText: "Os dados de posições foram eliminados com êxito.",
        exportCsv: "Exportar como CSV",
        importCsv: "Importar do CSV",
        importSuccessText: "Os dados de posições foram importados com sucesso.",
        title: "Dados de posições",
        loadingData: "Carregando dados...",
        noHoldingData: "Nenhum dado de posições disponível para este produto.",
        columns: {
          assetClass: "Classe de ativos",
          currency: "Moeda",
          holdingName: "Nome da posição",
          instrumentType: "Tipo de instrumento",
          isin: "ISIN",
          region: "Região",
          sector: "Setor",
          value: "Valor",
          weight: "Peso",
        },
        deleteHoldingDataDialog: {
          delete: "Excluir",
          text: (ctx) =>
            `Você está prestes a excluir completamente os dados de posições para a data ${ctx.named(
              "date"
            )}. Se você tiver certeza, clique no botão abaixo para continuar.`,
          title: "Excluir dados de posições",
        },
        errors: {
          csvParseFail:
            "Não foi possível processar o arquivo CSV. Verifique se ele está formatado corretamente e possui a extensão .csv válida.",
          numberFormat:
            "Alguns números no arquivo não puderam ser processados. Isso pode ocorrer devido a separadores decimais inconsistentes (por exemplo, ponto ou vírgula), formatação misturada ou caracteres inesperados nas colunas. Note-se que tanto a coluna 'peso' como a coluna 'valor' são obrigatórias e têm de ser fornecidas para cada linha.",
          missingCurrency:
            "Cada linha no arquivo CSV deve incluir uma moeda. Certifique-se de que todas as linhas tenham uma moeda válida e tente novamente.",
          keyColumnsTupleNotUnique:
            "As três primeiras colunas (nome da posição, moeda e ISIN) devem identificar exclusivamente uma posição. O arquivo CSV fornecido contém linhas duplicadas com os mesmos valores nessas colunas. Certifique-se de que essas linhas sejam únicas ou mescle-as.",
          dateAlreadyExists:
            "A data especificada no seu arquivo CSV já existe no sistema. Para continuar, exclua os dados existentes para essa data pela interface e tente novamente.",
          holdingAttributesValidation:
            "Um ou mais valores nas colunas 'Classe de ativos', 'Tipo de instrumento', 'Setor' ou 'Região' são inválidos. Para obter uma lista completa dos valores aceitáveis, entre em contato com o suporte.",
        },
        uploadCsvDialog: {
          cancel: "Cancelar",
          condition1:
            "As colunas devem estar na seguinte ordem: nome da posição, moeda, ISIN, classe de ativos, tipo de instrumento, setor, região, peso e valor. Todas as colunas são obrigatórias; valores desconhecidos devem ser deixados em branco.",
          condition2:
            "O ficheiro deve incluir uma linha de cabeçalho no topo, utilizando os nomes das colunas especificados na condição anterior.",
          condition3:
            "A coluna de moeda é obrigatória e não pode ser deixada em branco para nenhuma linha.",
          condition4: "Cada linha deve ter uma combinação única de nome da posição, moeda e ISIN.",
          date: "Data:",
          import: "Importar",
          text: "Por favor, envie um arquivo CSV contendo os dados de posições. As seguintes condições devem ser atendidas para que a importação seja bem-sucedida:",
          title: "Importar Dados de Posições de CSV",
        },
        uploadCsvErrorsDialog: {
          close: "Fechar",
          holdingAttribute: "Atributo da Posição",
          invalidValue: "Valor Inválido",
          rowNumber: "Número da Linha",
          title: "Erros no CSV de Dados de Posições",
        },
      },
      keyCharacteristicsTab: {
        header: "Cabeçalho",
        icon: "Ícone",
        paragraph: "Parágrafo",
        title: "Principais características",
      },
      metaInfo: {
        title: "Admin - Configurações de Produtos",
      },
      notify: {
        productSaveSuccess: (ctx) =>
          `A configuração do seu produto '${ctx.named("productName")}' foi atualizada com sucesso.`,
        productSaveSuccessWithLang: (ctx) =>
          `A configuração do seu produto '${ctx.named(
            "productName"
          )}' foi atualizada com sucesso (idioma: ${ctx.named("lang")}).`,
      },
      priceDataTab: {
        title: "Dados sobre preços",
        benchmarks: {
          title: "Referências",
        },
        product: {
          title: "Produto",
        },
      },
      selectCard: {
        loadingProducts: "Produtos estão carregando...",
        loadingShareClasses: "Classes de ações estão carregando...",
        noProducts: "Você não possui produtos.",
        selectProduct: "Selecionar um produto",
        selectShareClass: "Selecionar uma classe de ações",
      },
    },
    users: {
      tabName: "Lista de usuários",
      company: "Empresa",
      country: "País",
      email: "E-mail",
      emailCopied: "O e-mail foi copiado.",
      investorType: "Tipo de investidor",
      name: "Nome",
      lastVisit: "Última visita",
      phone: "Telefone",
      roles: "Funções",
      metaInfo: {
        title: "Admin - Lista de Usuários",
      },
    },
  },
  auth: {
    email: "E-mail",
    errorAlreadyEmailAndPasswordRegister:
      "Você já está registrado com este e-mail com uma senha. Faça login com este e-mail e sua senha.",
    errorInternal: "Ocorreu um erro interno. Por favor, entre em contato conosco.",
    or: "ou",
    password: "Senha",
    login: {
      errorAccountNotExist:
        "Você não tem uma conta nesta plataforma, por favor crie uma conta e tente novamente.",
      errorInvalidLoginCredentials:
        "As credenciais fornecidas não correspondem a nenhuma de nossas contas de usuário. Isso pode ser devido a um e-mail ou senha incorretos, à ausência de uma conta associada ao e-mail inserido, ou à possibilidade de que o e-mail esteja vinculado a um método de login alternativo (como Google). Verifique suas informações e tente novamente ou crie uma conta, se necessário.",
      errorNeedEmailVerification:
        "Você precisa verificar seu e-mail antes de tentar se conectar à nossa plataforma. Verifique seus e-mails (e sua pasta de spam) para encontrar o e-mail. Se você não conseguir encontrá-lo, redefina sua senha.",
      noAccountYet: "Ainda não tem uma conta?",
      rememberMe: "Lembrar de mim",
      welcomeBack: "Bem-vindo de volta, faça login na sua conta.",
      dialog: {
        alreadyLoggedInText: "Você já está logado!",
      },
      metaInfo: { title: "Plataforma - Login" },
      notify: {
        newAccessRight: {
          title: "Novos direitos de acesso",
          text: "Você foi desconectado automaticamente porque recebeu novos direitos de acesso que exigem um novo login para serem validados.",
        },
        registrationSuccess: {
          title: "Registro bem-sucedido",
          text: "Registro bem-sucedido, enviamos uma mensagem para validar seu endereço de e-mail, verifique sua caixa de entrada!",
        },
        requiresRecentLogin: {
          title: "Reautenticação Necessária",
          text: "Para prosseguir com esta ação, faça login novamente, pois sua sessão expirou. Uma vez reautenticado, você pode tentar a ação novamente.",
        },
        unexpectedError: {
          title: "Erro Inesperado",
          text: "Ocorreu um erro inesperado. Faça login novamente. Se o problema continuar, entre em contato com o suporte para assistência.",
        },
      },
    },
    login2fa: {
      enterCode: "Por favor, insira o código de 6 dígitos do seu aplicativo autenticador.",
      incorrectCode: " O código está incorreto. Tente novamente.",
      title: "Autenticação de 2 Fatores",
      verify: "Verificar",
      verifying: "Verificando...",
    },
    register: {
      alreadyRegistered: "Já registrado?",
      confirmPassword: "Confirme a senha",
      createAccount: "Criar conta",
      errorAlreadyEmailAndPasswordRegister:
        "Você já tem uma conta com este e-mail. Faça login na sua conta.",
      errorAlreadyGoogleRegister:
        "Você já está registrado com esta conta do Google. Faça login com esta conta.",
      errorAlreadyMicrosoftRegister:
        "Você já está registrado com esta conta da Microsoft. Faça login com esta conta.",
      fillBelowForm:
        'Preencha o formulário abaixo para criar uma nova conta. Os campos marcados com <span class="text-danger">*</span> são obrigatórios.',
      registerWith: "Registrar-se com: ",
      form: {
        agreeAdvertisementOnly:
          "Eu concordo explicitamente que todas as informações fornecidas nesta plataforma são apenas para fins informativos e publicitários e não devem ser interpretadas como uma oferta, aconselhamento ou recomendação para comprar ou vender qualquer título específico ou para realizar qualquer transação.",
        certifyAdult: "Eu certifico que tenho 18 anos ou mais.",
        company: "Empresa",
        confirmPassword: "Confirme a senha",
        email: "E-mail",
        firstName: "Nome",
        investorProfileCountry: "País",
        investorProfileType: "Tipo de investidor",
        lastName: "Sobrenome",
        needAgree: "Você precisa concordar",
        phone: "Telefone",
        privacyPolicy: "política de privacidade",
        registeringAccept: "Ao se registrar, você aceita nossos {0} e {1}.",
        termsOfUse: "termos de uso",
      },
      dialog: {
        alreadyLoggedInText: "Você já está logado!",
      },
      metaInfo: { title: "Plataforma - Registrar" },
    },
    register2fa: {
      generatingQrCode: "Gerando o código QR...",
      incorrectCode: " O código está incorreto. Tente novamente.",
      need2fa:
        'Para acessar a plataforma, configure a autenticação de 2 fatores (2FA). Escaneie o código QR abaixo ou insira a chave secreta fornecida em um aplicativo autenticador, como "Google Authenticator" ou "Microsoft Authenticator".',
      title: "Configurar Autenticação de 2 Fatores",
      verify: "Verificar",
      verifying: "Verificando...",
    },
    resetPassword: {
      title: "Redefinir senha",
      sendEmail: "Enviar e-mail",
      dialog: {
        internalErrorText: "Ocorreu um erro interno. Por favor, entre em contato conosco.",
        resetEmailSent: {
          title: "E-mail de redefinição enviado",
          text: (ctx) =>
            `Um e-mail de redefinição foi enviado para a caixa de entrada de ${ctx.named(
              "email"
            )}. Se não aparecer em breve, verifique a caixa de spam.`,
        },
        unknownEmail: {
          title: "E-mail não existe",
          text: "O e-mail fornecido para redefinir a senha não existe. Para criar uma conta com este e-mail, vá para a página de registro.",
        },
      },
      metaInfo: { title: "Plataforma - Redefinir Senha" },
    },
  },
  compliance: {
    investorProfile: {
      agreeAndConfirmSelection: "Concordar e confirmar seleção",
      byClickingAcceptDisclaimer:
        "Ao clicar no botão abaixo, confirmo que li e aceito o aviso acima e concordo com a {0} e os {1} desta plataforma.",
      pleaseChooseProfile:
        "Por favor, escolha seu país de residência e perfil de investidor para continuar.",
      privacyPolicy: "política de privacidade",
      termsOfUse: "termos de uso",
      title: "Perfil do investidor",
      metaInfo: {
        title: "Plataforma - Perfil do investidor",
      },
    },
    ombudsman: {
      title: "Ombudsman",
      metaInfo: {
        title: "Plataforma - Ombudsman",
      },
    },
    privacyPolicy: {
      endDate: "Crissier, 31 de agosto de 2023",
      title: "Política de Privacidade",
      metaInfo: { title: "Plataforma - Política de Privacidade" },
      introductionParagraph: {
        title: "Introdução",
        para1:
          "Alquant SA (doravante referida como Alquant, 'nós', 'nos') é a empresa que opera esta plataforma. Alquant SA respeita a privacidade dos usuários de sua presença na web. Isso significa que os dados pessoais são coletados, processados e usados de acordo com as disposições da lei de proteção de dados aplicável e com base nesta política de privacidade.",
        para2:
          "Isso também se aplica - e em particular - à cooperação com parceiros e terceiros que utilizamos para o processamento de contratos.",
        para3:
          "Ao concordar com a seguinte política de privacidade, você consente com os processos de tratamento de dados da Alquant detalhados abaixo em relação à coleta, processamento e uso de dados, em conformidade com as leis de proteção de dados e as disposições a seguir. Este consentimento pode ser revogado a qualquer momento com efeito futuro.",
        para4:
          "Nossa prática de proteção de dados é, portanto, consistente com a nova Lei Federal de Proteção de Dados (nFADP) e, quando aplicável, com o Regulamento Geral de Proteção de Dados da UE (GDPR).",
      },
      accountableAuthorityParagraph: {
        title: "Autoridade responsável / Representante da UE",
        switzerland: "Suíça",
        euRepresentativeSub: {
          title: "Representante da UE",
          para1:
            "Nos casos referidos no Artigo 3, parágrafo 2 do Regulamento (UE) 2016/679 (Regulamento Geral de Proteção de Dados; GDPR) e de acordo com o Artigo 27 do GDPR como representante na UE é designado e em referência ao requisito da função de Oficial de Proteção de Dados da nFADP suíça:",
          para3:
            "Você pode entrar em contato com nosso representante da UE em: info(at)alquant.com",
        },
        accountableAuthoritySub: {
          email: "E-mail",
          phone: "Telefone",
          title: "Autoridade responsável e função de Proteção de Dados de acordo com a nFADP suíça",
          para1: "A responsabilidade pelo processamento de dados pessoais é de:",
          para3:
            "Para qualquer solicitação relacionada à proteção de suas informações pessoais, entre em contato com nosso oficial de privacidade em: compliance(at)alquant.com.",
        },
      },
      personalDataParagraph: {
        title: "Dados Pessoais",
        para1:
          "Dados pessoais são qualquer informação relacionada a uma pessoa natural identificada ou identificável; uma pessoa natural é considerada identificável se puder ser identificada direta ou indiretamente, em particular por referência a um identificador, como um nome, número de identificação, dados de localização, um identificador online ou uma ou mais características especiais que expressem a identidade física, fisiológica, genética, mental, econômica, cultural ou social dessa pessoa natural. Isso inclui, por exemplo, seu nome, número de telefone, endereço, bem como todos os dados fornecidos ao se registrar e criar sua conta de cliente. Dados estatísticos ou anonimizados, que coletamos, por exemplo, ao visitar nossa loja virtual e que não podem ser diretamente vinculados a sua pessoa, não estão cobertos aqui.",
      },
      purposeOfDataCollectionParagraph: {
        title: "Finalidades da Coleta de Dados",
        para1:
          "a) A coleta e o processamento desses dados são realizados para fins de cumprimento de contrato, para permitir o uso de nosso site (estabelecimento de conexão), para garantir a segurança e estabilidade do sistema de forma permanente, para otimizar nossa presença na web e para fins estatísticos internos. Coletamos, armazenamos e processamos dados pessoais exclusivamente de acordo com as regulamentações legais aplicáveis e na medida em que seja necessário e requerido para cumprir e melhorar as obrigações de desempenho contratual entre nós e o usuário/cliente. Isso também inclui o armazenamento de dados para qualquer reivindicação de garantia após o término do contrato.",
        para2:
          "b) Além disso, usamos seus dados dentro do escopo da lei aplicável para nossos próprios fins de marketing, a fim de otimizar nossa presença na web e chamar sua atenção para serviços que possam ser de particular interesse para você.",
        para3:
          "c) Além disso, a coleta, o processamento e o uso de dados pessoais só ocorrem se você tiver dado seu consentimento.",
      },
      newsletterParagraph: {
        title: "Newsletter",
        para1:
          "A Alquant gosta de informá-lo regularmente sobre tópicos atuais e projetos relacionados ao nosso campo de atividade por meio do boletim informativo. Você receberá nosso boletim informativo por e-mail apenas mediante solicitação expressa. Utilizamos o processo de opt-in para enviar o boletim informativo, o que significa que você só receberá nosso boletim por e-mail se tiver previamente se registrado explicitamente para a entrega do boletim. Ao se registrar para o boletim informativo, seu endereço de e-mail será usado com seu consentimento para nossos próprios fins promocionais até que você cancele sua inscrição. Você pode cancelar a inscrição a qualquer momento enviando um e-mail especificando isso para o nosso endereço de contato info(at)alquant.com. Estamos autorizados a contratar terceiros para o manuseio técnico de atividades promocionais e estamos autorizados a passar seus dados para esse fim.",
        para2:
          "Os dados fornecidos por você ao se registrar para o boletim informativo (nome e endereço de e-mail) serão armazenados e usados apenas para enviar o boletim.",
      },
      legalBasisParagraph: {
        title: "Base Legal para Processamento",
        para1:
          "Processamos seus dados pessoais principalmente para cumprir obrigações contratuais e nossas obrigações legais. Além disso, ao aceitar esta política de privacidade, você consente com todas as operações de processamento especificadas nesta política de privacidade. Obviamente, você pode revogar seu consentimento a qualquer momento; uma declaração para compliance(at)alquant.com é suficiente. Qualquer processamento realizado até o momento não será afetado pelo cancelamento.",
      },
      legitimateInterestParagraph: {
        title: "Interesses legítimos para processamento",
        para1:
          "Além da base legal mencionada para o processamento de dados, também processamos dados com base em nossos interesses legítimos. Isso se aplica em particular, mas não se limita a, processamento para fins de marketing e promocionais. Perseguimos, em particular, os seguintes interesses:",
        list1: {
          el1: "garantir o uso potencial do site",
          el2: "prevenir fraudes",
          el3: "garantir a segurança e estabilidade dos sistemas de TI",
          el4: "nossos próprios fins de marketing",
          el5: "fins estatísticos internos",
        },
      },
      disclosureParagraph: {
        title: "Divulgação",
        para1:
          "Só divulgamos seus dados pessoais se você tiver dado seu consentimento expresso, se houver uma obrigação legal para fazê-lo ou se isso for necessário para fazer valer nossos direitos - em particular, para fazer valer reivindicações decorrentes da relação contratual. Além disso, divulgamos seus dados a terceiros, na medida em que for necessário para a execução do contrato. Divulgamos os dados necessários para subsidiárias, empresas de transporte, bancos e outros prestadores de serviços para este fim. Esses prestadores de serviços usam seus dados exclusivamente para o processamento do pedido e não para outros fins. Além disso, utilizamos alguns serviços prestados por terceiros para otimizar sua visita ao nosso site. É garantido (contratualmente) que esses prestadores de serviços tratam seus dados de forma confidencial e de acordo com as regulamentações legais aplicáveis, e que seus dados são usados apenas para a finalidade do serviço respectivo. Qualquer transferência ou revenda dos dados a terceiros pela Alquant, além das disposições desta política de privacidade, é excluída, a menos que o usuário tenha dado seu consentimento expresso para a divulgação de seus dados. Nesse caso, o usuário pode revogar esse consentimento a qualquer momento. Operações de processamento realizadas anteriormente com base em seu consentimento não serão afetadas.",
        para2:
          "A Alquant está autorizada a repassar seus dados pessoais para terceiros no exterior se isso for necessário para a execução do contrato. Esses terceiros estão sujeitos ao mesmo nível de proteção de dados que o provedor. Se o nível de proteção de dados em um país não corresponder ao nível suíço, garantimos contratualmente que a proteção de seus dados pessoais corresponda em todos os momentos à da Suíça.",
      },
      singleSignOnProcedureParagraph: {
        title: "Uso de procedimentos de login único",
        para1:
          "Em nosso site, se você tiver um perfil do Google ou Microsoft, pode se inscrever para criar uma conta de cliente ou se registrar usando o plugin social utilizando a técnica de Single Sign On. Os plugins são identificados com o logotipo do provedor.",
        para2:
          "Quando você acessa nossos sites que contêm tal plugin, seu navegador estabelece uma conexão direta com os computadores do provedor. O conteúdo do plugin é transmitido diretamente do site do provedor para o seu navegador, que o integra ao site. Ao integrar plugins, o provedor recebe a informação de que você acessou nosso site. Se você estiver simultaneamente conectado ao provedor, o provedor pode atribuir a visita ao seu perfil.",
        para3:
          "Se você não quiser que o provedor colete dados sobre você por meio de nosso site, deve se desconectar do provedor antes de visitar nosso site. Mesmo que esteja desconectado, os provedores coletam dados anonimizados por meio de plugins sociais e configuram um cookie para você. Se você fizer login no provedor posteriormente, esses dados podem ser atribuídos ao seu perfil.",
        para4:
          "Se um login for fornecido por meio de um serviço de login social − por exemplo, Google Connect − os dados são trocados entre o provedor e nosso site. No caso do Google Connect, isso pode incluir, por exemplo, dados do seu perfil público do Google. Ao usar esses serviços de login, você concorda com a transferência de dados.",
        para5:
          "Consulte os avisos de proteção de dados do provedor para saber a finalidade e o escopo da coleta de dados e o processamento adicional de seus dados pelo provedor, bem como seus respectivos direitos e opções de configuração para proteger sua privacidade.",
        para7:
          'Se você não quiser que o provedor colete dados sobre você por meio desses cookies, pode selecionar a função "bloquear cookies de terceiros" nas configurações do seu navegador. Então, se houver conteúdo incorporado de outros provedores, o navegador não enviará cookies ao servidor.',
      },
      durationOfRetentionParagraph: {
        title: "Duração da retenção",
        para1:
          "Retemos seus dados pessoais pelo tempo necessário para cumprir as leis aplicáveis, ou conforme julgarmos apropriado, ou enquanto for necessário para os fins para os quais foram coletados. Bloquearemos ou excluiremos seus dados pessoais assim que não forem mais necessários e, definitivamente, no final do período máximo de retenção legal.",
        para2:
          "Você pode solicitar que seus dados pessoais sejam excluídos a qualquer momento, enviando-nos uma solicitação correspondente para compliance(at)alquant.com. Atenderemos prontamente sua solicitação, desde que não sejamos obrigados a reter os dados por outros motivos ou precisemos deles, por exemplo, para a execução de reivindicações.",
      },
      furtherDataSourcesParagraph: {
        title: "Outras fontes de dados",
        para1:
          "Além dos dados que coletamos diretamente de você, também obtemos dados de registros públicos (como o Registro Comercial) e de terceiros.",
        para2:
          "Como as fontes de dados variam de acordo com o cliente, não é possível fornecer informações gerais sobre essas fontes. Se desejado, estamos dispostos a divulgar nossas fontes de dados em detalhes.",
      },
      userRightsParagraph: {
        title: "Direitos do usuário",
        rightRevocationSub: {
          title: "Direito de Revogação",
          para1:
            "Na medida em que você nos deu consentimento para coletar, armazenar e usar dados pessoais em relação ao site ou aos nossos serviços, você pode revogar esse consentimento a qualquer momento. A revogação pode ser enviada por e-mail ou por escrito para o contato acima na seção 2: Autoridade Responsável. Os efeitos da revogação são limitados ao armazenamento e uso de dados pessoais, que podem não ser armazenados e usados sem seu consentimento prévio com base em permissão legal. A revogação também não afeta os procedimentos de processamento anteriores realizados com base no consentimento.",
        },
        furtherRightsSub: {
          title: "Outros direitos",
          informationSub: {
            title: "Informação",
            para1:
              "Mediante solicitação por escrito, forneceremos informações a qualquer momento sobre seus dados pessoais armazenados. A solicitação de informações deve ser feita por escrito e com prova de identidade (cópia de RG, passaporte ou outro documento oficial de identificação). O exercício deste direito é gratuito uma vez por ano; para qualquer solicitação adicional, reservamo-nos o direito de cobrar pelos custos incorridos.",
          },
          correctionSub: {
            title: "Correção, exclusão, restrição de processamento",
            para1:
              "Além disso, você tem a opção de corrigir, bloquear ou excluir seus dados pessoais a qualquer momento. Entre em contato com a autoridade interna, cujos detalhes podem ser encontrados na seção 2. Excluídos da exclusão estão apenas os dados que precisamos para a conclusão de tarefas pendentes ou para a execução de direitos e reivindicações existentes, além dos dados que somos obrigados a manter devido a regulamentações legais. Tais dados serão bloqueados.",
          },
          rightAppealSub: {
            title: "Direito de apelação",
            para1:
              "Damos grande importância à proteção dos direitos de nossos clientes e, portanto, também à proteção de seus dados pessoais. No entanto, se você acreditar que processamos seus dados de forma ilegal, pode nos contatar a qualquer momento pelo número +41 41 661 23 45 ou pelo e-mail compliance(at)alquant.com com detalhes sobre sua preocupação. Faremos o possível para resolver suas preocupações.",
            para2:
              "Se, ao contrário das expectativas, nosso serviço ao cliente não puder fornecer assistência satisfatória, você pode enviar sua preocupação ao Supervisor Federal de Proteção de Dados a qualquer momento como uma reclamação formal, que será investigada.",
          },
          dataPortabilitySub: {
            title: "Portabilidade de dados",
            para1:
              "Mediante solicitação por escrito, você também tem o direito de receber seus dados pessoais em um formato estruturado, atual e legível por máquina (arquivo pdf) para transmissão a um terceiro. Reservamo-nos o direito de cobrar pelos custos incorridos.",
          },
        },
      },
      cookiesParagraph: {
        title: "Uso de Cookies",
        para1:
          "Para melhorar nossa gama de produtos, usamos cookies em nosso site. Um cookie é um pequeno arquivo de texto que é transferido para o seu computador quando você navega em nossas páginas. Um cookie só pode conter informações que enviamos para seu dispositivo eletrônico – eles não podem ser usados para ler dados pessoais. Se você aceitar cookies em nosso site, não teremos acesso às suas informações pessoais, mas, com a ajuda de cookies, podemos identificar seu computador. Não há dados pessoais armazenados nos cookies usados, de modo que não podemos tirar conclusões sobre sua identidade por meio da tecnologia de cookies. Apenas informações pseudonimizadas são geradas.",
        para2: "Usamos cookies para verificar se você cumpre nossos Termos de Uso.",
        para3:
          "Usamos cookies temporários baseados em sessões: esses não permanecem no seu computador. Quando você sai de nossas páginas, o cookie temporário também será descartado. Com a ajuda das informações coletadas, podemos analisar os padrões de uso e as estruturas de nosso site e otimizá-lo melhorando o conteúdo ou personalizando e simplificando o uso.",
        para4:
          "Além disso, usamos cookies persistentes: esses permanecem no seu computador para simplificar compras, personalização e serviços de registro em sua próxima visita. Por exemplo, esses cookies podem registrar o que você escolheu comprar enquanto continua comprando. Além disso, você só precisará digitar sua senha uma vez nas páginas que exigem login. Cookies persistentes podem ser removidos manualmente pelo usuário. Eles se excluem após um certo tempo.",
        para5:
          "Os cookies também são usados por alguns de nossos parceiros publicitários (cookies de terceiros). Esses cookies nos ajudam a adaptar nosso site às necessidades específicas de nossos clientes, por exemplo, exibindo anúncios que possam realmente interessar a você. O uso desses cookies nos permite coletar informações sobre quais produtos você visualizou ou pesquisou ou quais páginas visitou anteriormente. Esses cookies são armazenados no seu disco rígido e se excluem após um certo tempo. Também não há informações pessoais armazenadas nos cookies usados por nossos parceiros publicitários. O uso é pseudonimizado, de modo que não é possível tirar conclusões sobre sua identidade.",
        para6:
          "A maioria dos navegadores (como Firefox, Chrome, Internet Explorer, Safari, etc.) aceita cookies por padrão. Você pode permitir ou bloquear cookies temporários e persistentes nas configurações de segurança - independentemente um do outro. No entanto, devemos apontar que certos recursos não estão disponíveis em nosso site e alguns sites podem não ser exibidos corretamente se você desativar os cookies.",
        para7:
          "Os dados armazenados em nossos cookies não serão vinculados aos seus dados pessoais (nome, endereço etc.) sem seu consentimento expresso.",
      },
      liabilityForLinksParagraph: {
        title: "Responsabilidade por links",
        para1:
          "A Alquant declara expressamente que, no momento da criação dos links, nenhum conteúdo ilegal foi identificado nas páginas a serem vinculadas. Não temos influência sobre o design atual e futuro, conteúdo ou autoria das páginas vinculadas. Portanto, nos distanciamos expressamente de todos os conteúdos de todas as páginas vinculadas que forem alteradas após a criação do link. Esta declaração aplica-se a todos os links e referências em nossa presença na web. No que diz respeito a conteúdos ilegais, incorretos ou incompletos e, em particular, por danos resultantes do uso ou não uso de tais informações, apenas o provedor da página à qual foi feita referência é responsável, e não aquele que simplesmente coloca o link para a respectiva publicação.",
      },
      webAnalysisParagraph: {
        title: "Análise da Web",
        googleAnalyticsSub: {
          title: "Google Analytics",
          para1:
            "Este site utiliza o Google Analytics, um serviço de análise da web fornecido pela Google Inc. (Google) para design de sites com base nas necessidades. Recebemos apenas avaliações de dados sem referências pessoais.",
          para2:
            "O Google Analytics usa cookies: arquivos de texto que são armazenados em seu computador e que permitem a análise do uso do site. As informações geradas pelo cookie sobre o uso deste site (incluindo seu endereço IP) serão transmitidas para e armazenadas nos servidores do Google nos Estados Unidos. A anonimização de IP foi ativada neste site para que o endereço IP dos usuários do Google nos Estados Membros da União Europeia ou em outros estados contratantes do Acordo sobre o Espaço Econômico Europeu seja truncado antes da transmissão. Apenas em casos excepcionais o endereço IP completo será enviado a um servidor do Google nos EUA e truncado lá. O Google usará essas informações em nome do operador do site para avaliar o uso do site pelos usuários, compilar relatórios sobre a atividade do site e fornecer outros serviços ao operador do site relacionados à atividade do site e ao uso da internet. O Google também transmitirá essas informações a terceiros, se for exigido por lei ou se terceiros processarem esses dados em nome do Google. O Google nunca associará seu endereço IP a outros dados do Google.",
          para3:
            "Você pode impedir a instalação de cookies configurando seu software de navegação de forma adequada. No entanto, devemos ressaltar que, nesse caso, você pode não conseguir usar todas as funções deste site em sua totalidade. Ao usar este site, você concorda com o processamento dos dados coletados sobre você pelo Google na forma e para os fins estabelecidos acima.",
          para4:
            'Além disso, você pode impedir que o Google colete os dados gerados pelo cookie e relacionados ao uso do site (incluindo seu endereço IP) e o processamento desses dados pelo Google baixando e instalando o plug-in do navegador disponível em <a href="https://tools.google.com/dlpage/gaoptout" >https://tools.google.com/dlpage/gaoptout</a >. Para mais informações sobre os Termos de Uso do Google Analytics e a Política de Privacidade, consulte',
          para5:
            '<a href="https://www.google.com/analytics/terms/" >https://www.google.com/analytics/terms/</a > e',
          para6:
            '<a href="https://policies.google.com/privacy?hl=en" >https://policies.google.com/privacy?hl=en</a >',
        },
        googleFontsSub: {
          title: "Google Fonts",
          para1:
            'Este site também utiliza o Google Fonts (Open Scans). Por razões técnicas, seu endereço IP deve ser transmitido ao Google para que as fontes possam ser transferidas para o seu navegador. Para mais informações sobre o Google Fonts, visite <a class="break-all" href="https://developers.google.com/fonts/faq#what_does_using_the_google_fonts_api_mean_for_the_privacy_of_my_users" >https://developers.google.com/fonts/faq#what_does_using_the_google_fonts_api_mean_for_the_privacy_of_my_users</a > .',
        },
      },
      securityMeasureParagraph: {
        title: "Medidas de Segurança",
        para1:
          "Aplicamos medidas de segurança técnicas e organizacionais para proteger adequadamente suas informações pessoais contra acesso não autorizado, abuso, manipulação acidental ou intencional, perda ou destruição. Para proteger sua privacidade, utilizamos uma conexão segura para nosso site, que você pode ver pelo 'https' (https significa Hypertext Transfer Protocol Secure) no início da barra de endereços do seu navegador. Dessa forma, a conexão é criptografada via SSL/TSL e seus dados não podem ser interceptados por terceiros. Medidas de segurança técnicas para proteger seus dados pessoais são verificadas regularmente e, se necessário, atualizadas. Esses princípios também se aplicam a empresas que processam e usam dados pessoais em nosso nome e de acordo com nossas instruções.",
      },
      legalBasisGeneralDataProtectionParagraph: {
        title: "Base Legal Regulamento Geral de Proteção de Dados",
        para1:
          "De acordo com as disposições do Regulamento Geral de Proteção de Dados (GDPR) aplicável a partir de 25 de maio de 2018, informamos que seu consentimento para o envio ao endereço de e-mail fornecido será dado com base no Art. 6, parágrafo 1, alínea a e Art. 7 do GDPR. A lista de envio correspondente é usada com base em nossos interesses legítimos, de acordo com o Art. 6, parágrafo 1, alínea f do GDPR. Estamos interessados em usar um site e sistema de fácil utilização e seguro, que atenda aos interesses comerciais, mas também às expectativas de nossos usuários e clientes.",
      },
      changesParagraph: {
        title: "Alterações",
        para1:
          "Reservamo-nos o direito de alterar esta política de privacidade a qualquer momento. A política de privacidade não constitui qualquer relação legal contratual ou outra formal com ou em nome de qualquer parte.",
      },
    },
    termsOfUse: {
      endDate: "Crissier, 3 de fevereiro de 2022",
      title: "Termos de Uso",
      metaInfo: { title: "Plataforma - Termos de Uso" },
      declarationOfAcceptanceParagraph: {
        title: "Declaração de aceitação pelo usuário",
        para1:
          'Este site é operado pela Alquant SA. Ao utilizar este site (ou uma página desviada para este endereço da web; doravante referido em sua totalidade como o "site da Alquant"), você confirma que leu e entendeu e aceita os seguintes Termos de Uso, incluindo as Condições Legais. Se você não aceitar estes Termos de Uso, por favor, não visite o site da Alquant novamente.',
      },
      limitedAccessParagraph: {
        title: "Acesso limitado",
        para1:
          "O site da Alquant não é destinado a pessoas jurídicas e naturais que, por razões de nacionalidade, sede comercial, local de residência ou por quaisquer outras razões, estejam sujeitas a um sistema jurídico que proíba ou restrinja, em particular,",
        list1: {
          el1: "a publicação dos conteúdos,",
          el2: "o acesso ao site da Alquant,",
          el3: "as atividades de um prestador de serviços financeiros estrangeiro,",
          el4: "a admissão de produtos financeiros (por exemplo, certificados ou fundos).",
        },
        para3:
          "Além disso, não se destina ao uso por pessoas jurídicas ou naturais em países onde a Alquant SA ou quaisquer empresas a ela associadas não possuem o registro e autorização necessários para operar.",
        para4:
          "Pessoas sujeitas a qualquer uma ou todas essas restrições não podem acessar o site da Alquant. Isso se aplica, em particular, aos cidadãos dos EUA, Reino Unido, Canadá, Japão, China e Cingapura, ou pessoas residentes nos países mencionados. Informe-se sobre as restrições legais correspondentes em seu país.",
        para5: "Aviso para investidores institucionais",
        para6:
          'Os produtos financeiros para investidores profissionais estão disponíveis apenas para investidores qualificados. Nos termos do Art. 10, parágrafo 3, da Lei de Investimentos Coletivos de Capital, juntamente com o Art. 6 da Portaria de Investimentos Coletivos de Capital, investidores qualificados incluem investidores institucionais com uma tesouraria profissional, nomeadamente bancos e corretores de valores (incluindo seus clientes que têm um contrato de gestão de ativos por escrito), fundos de pensão, companhias de seguros, gestores de fundos e autoridades locais e cantonais, bem como empresas industriais e comerciais. Ao selecionar "Investidores profissionais", você confirma que é um investidor qualificado de acordo com a definição acima.',
      },
      propertyRightsParagraph: {
        title: "Direitos de Propriedade",
        para1:
          "Todos os elementos do site da Alquant são protegidos como propriedade intangível e pertencem à Alquant SA ou a terceiros. A utilização do site da Alquant não implica a transferência de quaisquer direitos, em particular, quaisquer direitos relacionados a software, marcas comerciais ou elementos do site da Alquant. As indicações de copyright e marcas comerciais não podem ser alteradas ou removidas. Os elementos do site da Alquant não podem ser reproduzidos em sua totalidade ou em parte sem referência à fonte completa. Os conteúdos do site da Alquant não podem ser reproduzidos ou utilizados para fins públicos ou comerciais sem o consentimento prévio por escrito da Alquant SA.",
      },
      consultingReplacementParagraph: {
        title: "Não substituição de consultoria",
        para1:
          "As informações e opiniões publicadas no site da Alquant são de caráter geral. Elas não são destinadas nem adequadas para substituir consultoria individual por profissionais especializados que levarão em consideração as circunstâncias reais prevalecentes em um caso individual. Por esse motivo, elas não representam aconselhamento jurídico, fiscal, financeiro, econômico ou qualquer outro e não devem ser utilizadas como auxílio a investimentos.",
      },
      noOffersParagraph: {
        title: "Sem ofertas",
        para1:
          "As informações e opiniões publicadas no site da Alquant não constituem publicidade ou recomendações e não devem ser interpretadas como uma demanda, uma oferta ou um convite para apresentar uma oferta:",
        list1: {
          el1: "para aquisição ou venda de instrumentos de investimento,",
          el2: "para realização de qualquer tipo de transação,",
          el3: "para completar qualquer outro tipo de negócio jurídico.",
        },
        para3: "O único propósito do site da Alquant é fornecer informações.",
      },
      futurePerformanceParagraph: {
        title: "Desempenho futuro (indicação de risco)",
        para1:
          "O desempenho futuro de um instrumento de investimento não pode ser deduzido a partir dos movimentos passados na taxa, ou seja, o título pode subir ou cair. A forma como o título se comportou no passado não é, portanto, garantia de seu valor futuro. No caso de certos instrumentos de investimento, a perda total do capital investido também é possível em casos extremos. Além disso, flutuações nas taxas de câmbio também podem afetar o investimento.",
      },
      noGuaranteeParagraph: {
        title: "Sem garantias",
        para1:
          "A Alquant SA não oferece garantias (incluindo responsabilidade para com terceiros) nem assegura a",
        list1: {
          el1: "atualidade, precisão, confiabilidade ou integridade dos conteúdos do site da Alquant,",
          el2: "os resultados obtidos utilizando o site da Alquant,",
          el3: "os conteúdos de terceiros que são encontrados ou acessíveis via o site da Alquant.",
        },
        para3: "Além disso, a Alquant SA não se responsabiliza nem garante que",
        list2: {
          el1: "as funções no site da Alquant funcionam sem falhas e estão disponíveis sem interrupção,",
          el2: "o site da Alquant e outros componentes utilizados através do acesso ao site da Alquant estão livres de vírus, etc.",
        },
        para5: "A Alquant SA também não oferece garantias para",
        list3: {
          el1: "o aumento ou estabilidade do valor do capital investido em instrumentos de investimento,",
          el2: "o nível de dividendos futuros.",
        },
      },
      linksParagraph: {
        title: "Links",
        para1:
          "A Alquant SA não verificou os sites de terceiros vinculados ao site da Alquant e não pode ser responsabilizada pela atualidade, precisão, confiabilidade, integridade ou legalidade dos conteúdos e ofertas nesses sites. O usuário, portanto, acessa tais páginas por sua conta e risco.",
        para2:
          "Links da web só podem ser incluídos em sites fora de um site da Alquant com o consentimento prévio da Alquant SA.",
      },
      serviceChangesParagraph: {
        title: "Alterações no serviço",
        para1:
          "A Alquant SA pode alterar, suspender ou descontinuar qualquer aspecto dos Serviços a qualquer momento, incluindo a disponibilidade de qualquer recurso, banco de dados ou conteúdo dos Serviços. A Alquant SA também pode impor limites a certos recursos e serviços ou restringir o acesso do usuário a todos ou a partes dos Serviços sem aviso prévio.",
        para2:
          "A Alquant SA reserva-se o direito, a seu exclusivo critério, de encerrar o acesso do usuário aos Serviços sem aviso prévio.",
      },
      useOfInformationParagraph: {
        title: "Uso de informações",
        para1:
          "O usuário pode usar as Informações publicadas no site da Alquant apenas para uso comercial interno ou uso pessoal, não comercial. Em nenhum caso o usuário publicará, venderá, arrendará, disseminará, retransmitirá, redistribuirá, transmitirá, circulará ou de qualquer outra forma reproduzirá, fornecerá ou permitirá o acesso a qualquer Informação da Alquant SA em qualquer formato para qualquer pessoa; no entanto, o usuário pode fornecer Informações a usuários, de forma não contínua, utilizando apenas os seguintes métodos limitados: (a) Em anúncios escritos, correspondências ou outras literaturas, ou (b) durante conversas telefônicas de voz que não envolvem voz computacional, sistemas de consulta de informações automatizadas ou tecnologias semelhantes.",
        para2:
          "O uso de ferramentas de script automatizadas para rastrear o site ou tentar contornar a API é estritamente proibido e pode resultar em bloqueio do acesso aos dados do site da Alquant. Solicitações excessivas podem resultar em limitação do acesso da sua conta aos dados.",
        para3:
          "O uso de formas de automação não baseadas em API, como scripts do site, é proibido. O uso dessas técnicas pode resultar na suspensão permanente da sua conta.",
      },
      indemnificationParagraph: {
        title: "Indenização",
        para1:
          "O usuário (e o fundo do usuário, se o usuário for um representante de um fundo) concordam solidariamente em indenizar e isentar a Alquant SA, e seus agentes, provedores de informações e seus agentes, representantes, diretores, executivos, acionistas, parceiros, predecessores, sucessores e cessionários, de e contra qualquer reivindicação ou demanda, processo, danos, responsabilidades, perdas ou despesas, decorrentes de reivindicações feitas por terceiros, decorrentes do uso das informações da Alquant SA pelo usuário que constitui uma violação dos Termos de Uso ou um ato ou omissão imprópria.",
        para2:
          "Se um usuário fizer uso dos Serviços ou de qualquer Conteúdo além do expressamente permitido por estes Termos de Uso, ele ou ela poderá violar leis de direitos autorais e outras leis e poderá estar sujeito a penalidades. Sem limitar as sentenças precedentes, o usuário não deverá:",
        para3:
          "Engajar-se ou permitir qualquer revenda, exibição, reprodução, cópia, tradução, modificação, adaptação, criação de obras derivadas, distribuição, transmissão, transferência, republicação, compilação, descompilação ou engenharia reversa dos Serviços, de qualquer Conteúdo, ou de qualquer porção dele, ou de quaisquer dados, outros conteúdos ou informações fornecidos ao usuário em conexão com o uso dos Serviços pela Alquant SA ou por qualquer uma de suas fontes terceirizadas, em qualquer forma, mídia ou tecnologia existente agora ou desenvolvida posteriormente, ou em qualquer rede, incluindo uma rede de área local; Remover, obscurecer ou alterar qualquer aviso, isenção de responsabilidade ou outra divulgação afixada ou contida dentro dos Serviços, incluindo qualquer aviso de direitos autorais, marca comercial ou outro aviso de direitos de propriedade ou qualquer outro aviso legal afixado ou exibido em associação com o Conteúdo; Criar uma moldura ou usar técnicas de moldura para delimitar qualquer parte dos Serviços ou do Conteúdo, sem o consentimento prévio por escrito da Alquant SA;",
        para4:
          "Personificar qualquer pessoa, ou declarar falsamente ou de outra forma deturpar sua afiliação com qualquer pessoa, em conexão com qualquer uso dos Serviços; Violar ou tentar violar a segurança dos Serviços ou de qualquer rede, servidores, dados ou computadores ou outros hardwares relacionados ou usados em conexão com os Serviços ou qualquer Conteúdo, seja operado pela Alquant SA ou por terceiros; nem usar ou distribuir através dos Serviços qualquer software ou outras ferramentas ou dispositivos projetados para interferir ou comprometer a privacidade, segurança ou uso dos Serviços por terceiros ou as operações ou ativos de qualquer pessoa;",
        para5:
          "Violar qualquer lei aplicável, incluindo, mas não limitado a leis de valores mobiliários e leis que regem a atividade de investimento em qualquer país; Restringir ou inibir qualquer outro usuário autorizado de usar e aproveitar os Serviços; Publicar, transmitir, reproduzir, distribuir ou de qualquer forma explorar qualquer Conteúdo ou outras informações ou materiais obtidos através dos Serviços para fins comerciais, exceto conforme expressamente permitido por estes Termos de Uso ou por permissão prévia por escrito da Alquant SA.",
      },
      exclusionLiabilityParagraph: {
        title: "Exclusão de responsabilidade",
        para1:
          "A Alquant SA ou quaisquer empresas, parceiros, conselhos de administração, funcionários ou representantes a ela vinculados rejeitam toda a responsabilidade (incluindo para com terceiros) por danos diretos ou indiretos ou danos consequenciais que possam ocorrer em conexão direta ou indireta com a utilização do site da Alquant.",
      },
      reservationsParagraph: {
        title: "Reservas",
        para1:
          "A Alquant SA reserva-se o direito de modificar qualquer informação, opiniões, desempenho ou outros dados, links, etc. a qualquer momento, sem aviso prévio aos usuários.",
        para2:
          "Além disso, os Termos de Uso também podem ser alterados e o uso do site da Alquant também pode ser restrito ou encerrado sem aviso prévio.",
      },
      placeOfJurisdictionParagraph: {
        title: "Foro Competente",
        para1:
          "Na medida em que uma posição legal surgir entre a Alquant SA e os usuários do site da Alquant, esta estará sujeita à legislação suíça. O foro exclusivo será Lausanne, Suíça.",
      },
    },
  },
  errors: {
    backHome: "Voltar para a página inicial",
    error403: {
      paragraphWhenLoggedIn:
        "Você não tem permissão para acessar esta página. Se o seu perfil for aceito, um administrador logo lhe concederá os direitos de acesso. Por favor, verifique novamente nos próximos 1-2 dias úteis.",
      paragraphWhenLoggedInWithContact: (ctx) =>
        `Você não tem permissão para acessar esta página. Se o seu perfil for aceito, um administrador logo lhe concederá os direitos de acesso. Por favor, verifique novamente nos próximos 1-2 dias úteis. Se você ainda não tiver recebido o acesso até lá, entre em contato conosco: ${ctx.named(
          "contactEmail"
        )}.`,
      paragraphWhenNotLoggedIn:
        "Esta página exige que você esteja autenticado para acessá-la. Por favor, clique no botão abaixo para fazer login na sua conta. Se você ainda não tem uma conta, registre-se clicando no botão abaixo.",
      title: "403 - Acesso proibido",
      metaInfo: { title: "Plataforma - 403" },
    },
    error404: {
      paragraph: "A página que você está tentando acessar não existe.",
      title: "404 - Página não encontrada",
      metaInfo: { title: "Plataforma - 404" },
    },
    unauthorizedInvestorProfile: {
      modifyInvestorProfile: "Modificar perfil de investidor",
      title: "Perfil de investidor não autorizado",
      unfortunately:
        "Infelizmente, você não pode acessar esta página devido ao seu perfil de investidor.",
      metaInfo: { title: "Plataforma - Perfil de Investidor Não Autorizado" },
    },
  },
  help: {
    contact: {
      address: "Endereço",
      introText:
        "Não hesite em usar este formulário para entrar em contato conosco. Teremos o prazer de responder a quaisquer sugestões ou responder a qualquer tipo de dúvida que você possa ter relacionada a esta plataforma e nossos produtos.",
      recaptchaPrivacyPolicy: "Política de Privacidade",
      recaptchaProtect:
        "Este site é protegido pelo reCAPTCHA e aplicam-se a {0} e os {1} do Google.",
      recaptchaTermsOfService: "Termos de Serviço",
      switzerland: "Suíça",
      title: "Contato",
      dialog: {
        confirmMessage: {
          title: "Confirmar",
          text: "Você deseja enviar esta mensagem?",
        },
      },
      form: {
        country: "País",
        email: "E-mail",
        enterMessage: "Digite sua mensagem",
        exampleEmail: "joao.silva@exemplo.com",
        exampleName: "João Silva",
        investorProfileType: "Tipo de investidor",
        name: "Nome",
        sendEmail: "Enviar e-mail",
      },
      metaInfo: { title: "Plataforma - Contato" },
      notify: {
        errorMessage: {
          title: "Erro ao enviar mensagem",
          text: "Desculpe, a mensagem não pôde ser enviada. Tente novamente mais tarde ou entre em contato diretamente por e-mail.",
        },
        messageSent: {
          title: "Mensagem enviada",
          text: "A mensagem foi enviada com sucesso. Entraremos em contato com você em 1-2 dias úteis.",
        },
      },
    },
    faq: {
      subtitle: "Veja a lista a seguir para todas as nossas perguntas frequentes.",
      title: "Tem Alguma Pergunta?",
      metaInfo: { title: "Plataforma - FAQ" },
    },
    knowledgeBase: {
      investmentStrategyTechniques: "Técnicas de Estratégia de Investimento",
      metaInfoTerm: (ctx) => `Base de Conhecimento - ${ctx.named("term")}`,
      monthlyDataBasis: "Calculado com base em dados mensais.",
      noResults: "Nenhum resultado encontrado.",
      performanceMetrics: "Métricas de Desempenho",
      search: "Buscar",
      source: "Fonte",
      subtitle:
        "Encontre explicações sobre termos técnicos gerais, bem como algumas de nossas técnicas exibidas nesta plataforma.",
      title: "Bem-vindo à nossa Base de Conhecimento",
      alpha: {
        title: "Alfa",
        para1:
          "{0} é usado para medir o desempenho superior que uma estratégia ou seu gestor consegue gerar em comparação com um benchmark. O {1} descreve o desempenho após o desconto para o ambiente de retorno livre de risco e o retorno relacionado ao mercado (prêmio de mercado * {2}).",
        para2:
          'O gráfico acima mostra um valor de <span class="font-bold">alfa</span> de 4%, o que significa que, dada a dependência da estratégia em relação ao mercado e o ambiente atual de taxas de juros (retorno livre de risco), foi capaz de gerar um desempenho adicional de 4% em comparação ao que se poderia esperar.',
        para3:
          'Como indicado no gráfico acima, o componente <span class="font-bold">alfa</span> é o único motor de desempenho que pode ser atribuído à habilidade do gestor. Além disso, indica que o <span class="font-bold">alfa</span> pode se tornar um número negativo, o que significa que o desempenho foi perdido devido à gestão ativa do investimento. Dito isso, um componente <span class="font-bold">alfa</span> positivo pode ser visto como o componente pelo qual o investidor está disposto a pagar uma taxa.',
        para4:
          "Quanto maior o alfa, melhor a estratégia. Um alfa elevado significa que os gestores de ativos conseguiram criar valor.",
      },
      annualizedReturn: {
        title: "Retorno Anualizado",
        para1:
          '<span class="font-bold">Retornos anualizados</span> são retornos cumulativos redimensionados para um período de um ano. Isso permite que os investidores comparem melhor os retornos de diferentes ativos que possuíram por diferentes períodos de tempo.',
        para2:
          'Alternativamente, os <span class="font-bold">retornos anualizados</span> também podem ser interpretados como o retorno médio anual que um investidor teria obtido ao investir durante um determinado período.',
        para3: "Tudo o mais constante, quanto maior o retorno anualizado, melhor.",
      },
      annualizedVolatility: {
        title: "Volatilidade Anualizada",
        para1:
          '<span class="font-bold">Volatilidade anualizada</span> é a volatilidade de um investimento que é redimensionada para um período de um ano. A <span class="font-bold">volatilidade</span> em si mede a magnitude dos movimentos de preço. Mais tecnicamente, a <span class="font-bold">volatilidade</span> é uma medida estatística da dispersão de retornos (especificamente o desvio padrão dos retornos) ao longo de um período. Quanto maior a <span class="font-bold">volatilidade</span>, mais arriscado é o investimento, porque quanto maior a <span class="font-bold">volatilidade</span>, maior a probabilidade de ocorrerem oscilações maiores.',
      },
      beta: {
        title: "Beta",
        para1:
          '<span class="font-bold">Beta</span> mede a dependência dos retornos. Geralmente, é usado para medir a dependência de um título ou uma estratégia de investimento no retorno do mercado. Um <span class="font-bold">beta</span> mais alto indica que o título está se movendo de forma mais agressiva do que o mercado, enquanto um <span class="font-bold">beta</span> mais baixo indica que o título captura apenas parcialmente os retornos do mercado.',
        para2:
          'Embora raramente encontrado, valores de <span class="font-bold">beta</span> negativos existem e indicariam uma relação inversa com o mercado.',
        para3:
          "Ao analisar uma estratégia de investimento, os investidores buscam estratégias bem-sucedidas com um beta baixo. Essa constelação indica que o desempenho do gestor depende menos dos retornos do mercado e mais da habilidade do gestor.",
      },
      calmarRatio: {
        title: "Índice de Calmar",
        para1:
          "Um índice usado para determinar o retorno em relação ao risco de drawdown (queda) em um produto de investimento.",
        para2: "Tudo o mais sendo igual, quanto maior o índice, melhor o investimento.",
      },
      correlation: {
        title: "Correlação",
        para1:
          "O coeficiente de correlação de Pearson (PCC) é uma medida estatística que avalia a força e a direção de uma relação linear entre dois conjuntos de dados. É calculado como a razão entre a covariância das duas variáveis e o produto de seus desvios padrão. Essa normalização torna o PCC um valor entre -1 e 1, onde -1 indica uma correlação linear negativa perfeita, 1 uma correlação linear positiva perfeita e 0 nenhuma correlação linear. É importante notar que o PCC detecta apenas relações lineares, não outros tipos de correlações.",
        para2:
          "Dado um conjunto de dados pareados {(X,Y)} de n pares, o PCC será calculado usando a seguinte fórmula:",
      },
      cumulativeReturn: {
        title: "Retorno acumulado",
        para1:
          'Apresentado como uma porcentagem, o <span class="font-bold">retorno acumulado</span> em um investimento é o valor total que o investimento ganhou ou perdeu ao longo do tempo.',
        para2:
          'O <span class="font-bold">retorno acumulado</span> pode ser facilmente deduzido de um gráfico que mostra o crescimento de um investimento, como o mostrado acima. Usando apenas os valores inicial e final do gráfico, podemos obter o retorno acumulado total para um determinado período.',
      },
      informationRatio: {
        title: "Índice de Informação",
        para1:
          'As estratégias de investimento geralmente são medidas em comparação com um benchmark específico, que na maioria das vezes consiste em um ou mais índices. Quando um gestor de investimentos supera o benchmark, é interessante para o investidor saber a que custo esse objetivo foi alcançado. O <span class="font-bold">índice de informação</span> é uma medida que fornece uma comparação entre o retorno excedente em relação ao benchmark e o erro de rastreamento, que é uma medida da consistência entre a estratégia e o benchmark.',
        para2:
          'O <span class="font-bold">índice de informação</span> é calculado da mesma forma que o índice de Sharpe, assumindo que o benchmark equivale a um retorno livre de risco com uma volatilidade de 0%.',
        para3:
          "Tudo o mais constante, se esse índice for positivo, quanto maior for, mais robusto é o investimento. Um índice elevado significa que as flutuações diárias nos retornos excedentes em relação ao benchmark são pequenas em comparação ao retorno médio excedente.",
      },
      kurtosis: {
        title: "Curtose",
        para: "A curtose indica a acentuação de uma distribuição. Para uma distribuição normal, a curtose é 0. Quanto maior a curtose de uma distribuição, mais concentrada ela é em torno de sua média.",
      },
      m2RiskAdjustedPerf: {
        title: "Medida M2",
        para1:
          "O desempenho ajustado ao risco M2, também conhecido como medida M2, avalia os retornos ajustados ao risco de uma carteira de investimentos. Ele avalia os retornos da carteira, levando em consideração seu risco em comparação com um benchmark, como o mercado. Essencialmente, ele representa a diferença no retorno excedente ajustado de uma carteira (P) em relação ao mercado, ajustando a carteira para corresponder à volatilidade do mercado. Originada do índice de Sharpe, oferece um benefício substancial ao expressar os resultados em retornos percentuais, ao contrário do índice de Sharpe, que é um valor não negativo e menos prático para muitos investidores. Essa característica da medida Modigliani torna-o significativamente mais fácil de entender e aplicar.",
        para2:
          "Tudo o mais constante, se o retorno do investimento for maior do que a taxa livre de risco, quanto maior a medida M2, melhor o investimento. De fato, nesse caso, a medida M2 aumenta quando o retorno da estratégia aumenta ou quando a volatilidade da estratégia diminui em relação à do benchmark.",
      },
      maximumDrawdown: {
        title: "Máxima Queda",
        para1:
          'A <span class="font-bold">máxima queda</span> é a maior perda observada de um pico a um vale de uma carteira, antes que um novo pico seja atingido. <span class="font-bold">Máxima queda</span> é um indicador de risco de queda em um período especificado.',
        para2:
          '<span class="font-bold">Máxima queda</span> é um indicador usado para avaliar o risco relativo de uma estratégia em comparação a outra, pois foca na preservação de capital, uma preocupação importante para a maioria dos investidores. Uma baixa máxima queda é preferida, pois indica que as perdas dos investimentos foram pequenas. Se um investimento nunca perdeu um centavo, a <span class="font-bold">máxima queda</span> seria zero. A pior <span class="font-bold">máxima queda</span> possível seria 100%, significando que o investimento se tornou completamente inútil.',
        para3:
          "Tudo o mais sendo igual, quanto menor o valor absoluto da máxima queda (ou seja, quanto mais próximo de 0%), mais confiável é o investimento.",
        keyPoints: "Pontos chave",
        keyPoint1:
          "Máxima queda é uma medida da maior queda no preço de um ativo, de um pico a um vale.",
        keyPoint2:
          "Máxima queda é considerada um indicador de risco de queda, com grandes quedas sugerindo que os movimentos descendentes podem ser voláteis.",
        keyPoint3:
          "Embora a máxima queda meça a maior perda, ela não leva em conta a frequência das perdas, nem o tamanho de qualquer ganho.",
      },
      metaInfo: { title: "Plataforma - Base de Conhecimento" },
      omegaRatio: {
        title: "Índice Ômega",
        para1:
          "O índice Ômega é uma medida de desempenho risco-retorno. Ele calcula a razão ponderada pela probabilidade de ganhos versus perdas.",
        para2: "Tudo o mais sendo igual, quanto maior o índice, melhor o investimento.",
      },
      returnVolatilityRatio: {
        title: "Índice de Retorno para Volatilidade",
        para1:
          'O <span class="font-bold">índice de retorno para volatilidade</span> representa o retorno médio obtido por unidade de volatilidade (desvio padrão), que pode ser interpretada como uma unidade de risco. Portanto, ele informa quão bem um investidor é recompensado pelo risco assumido (quanto maior o <span class="font-bold">índice de retorno para volatilidade</span>, maior a recompensa pelo risco).',
        para2:
          'Tipicamente, qualquer <span class="font-bold">índice de retorno para volatilidade</span> maior que 1,0 por um período prolongado é considerado bom, pois significa que as recompensas são maiores do que o risco assumido. Por exemplo, se a volatilidade anualizada for menor do que o retorno anualizado, o <span class="font-bold">índice de retorno para volatilidade</span> será maior que 1,0.',
        para3:
          'Assim, o <span class="font-bold">índice de retorno para volatilidade</span> permite que os investidores comparem investimentos muito diferentes pelos mesmos critérios. O <span class="font-bold">índice de retorno para volatilidade</span> permite comparações de "maçãs com laranjas".',
        keyPoints: "Pontos chave",
        keyPoint1:
          "Um <span class='font-bold'>índice de retorno para volatilidade</span> alto indica quão bem um investidor é recompensado pelo risco assumido.",
        keyPoint2:
          "O <span class='font-bold'>índice de retorno para volatilidade</span> ajusta o desempenho passado de uma carteira ao risco assumido pelo investidor e, portanto, permite uma comparação entre investimentos muito diferentes.",
      },
      sharpeRatio: {
        title: "Índice de Sharpe",
        para1:
          'O <span class="font-bold">índice de Sharpe</span> é uma medida de retorno ajustado ao risco. Ele compara o retorno excedente acima de um investimento livre de risco ao risco que foi assumido para alcançar esse retorno, onde o risco para o investidor é definido como volatilidade. O índice ajuda a entender o retorno obtido pela unidade de risco e pode ser usado para comparar várias oportunidades de investimento.',
        para2:
          "Como nenhum investimento é totalmente livre de risco, qualquer título com riscos mínimos pode ser usado como um substituto para retornos livres de risco. Geralmente, títulos do governo de países estáveis são adequados para esse fim. Usamos taxas LIBOR de curto prazo para calcular o retorno livre de risco.",
        para3:
          "Tudo o mais sendo igual, se esse índice for positivo, quanto maior ele for, mais robusto será o investimento. Um índice elevado significa que as flutuações diárias nos retornos excedentes são pequenas em comparação ao retorno médio excedente.",
      },
      skewness: {
        title: "Assimetria",
        para1:
          'A frequência de ocorrência de grandes retornos em uma determinada direção é medida pela <span class="font-bold">assimetria</span>. Se a frequência de ocorrência de grandes retornos negativos comparados aos grandes retornos positivos for maior, a distribuição exibe <span class="font-bold">assimetria</span> negativa. Caso a frequência de grandes retornos positivos exceda a de grandes retornos negativos, a distribuição exibe <span class="font-bold">assimetria</span> positiva.',
        para2:
          'Os investidores devem observar a <span class="font-bold">assimetria</span> porque ela considera os extremos dos retornos em vez de se concentrar apenas na média. Investidores de curto e médio prazo, em particular, precisam observar os extremos, pois são menos propensos a manter uma estratégia por tempo suficiente para confiar que a média se estabilizará.',
      },
      sortinoRatio: {
        title: "Índice de Sortino",
        para1:
          "O índice de Sortino é semelhante ao índice de Sharpe, exceto que usa o risco de queda (volatilidade de queda) no denominador. Como a variabilidade para cima não é necessariamente uma coisa ruim, o índice de Sortino é às vezes mais preferido do que o índice de Sharpe.",
        para2: "Tudo o mais sendo igual, quanto maior o índice, melhor o investimento.",
      },
      trackingError: {
        title: "Erro de Rastreamento",
        para1:
          'O <span class="font-bold">erro de rastreamento</span> é uma medida do risco de uma carteira de investimentos devido às decisões de seu gestor de se desviar de seu benchmark. Quanto maior for, mais ativo o gestor está em escolher se desviar de seu benchmark.',
        para2:
          "Matematicamente, uma maneira relevante de estimá-lo é calcular o desvio padrão da diferença de retorno entre a carteira e o benchmark. O que apresentamos nesta plataforma é anualizado.",
        para3:
          'É importante lembrar que ele não representa a relevância das decisões do gestor, mas sim o impacto que elas têm na carteira final. Assim, duas carteiras com desempenho excelente ou ruim podem ter altos <span class="font-bold">erros de rastreamento</span> em comparação com seu benchmark.',
        para4:
          "Um erro de rastreamento baixo implica que um investimento não se desvia muito de seu benchmark. Um erro de rastreamento alto significa que o investimento se desvia muito de seu benchmark.",
      },
      treynorRatio: {
        title: "Índice de Treynor",
        para: 'Semelhante ao índice de Sharpe, o índice de Treynor é uma medida de eficiência utilizando a relação entre o retorno ajustado ao risco anualizado e o risco. Ao contrário do índice de Sharpe, o índice de Treynor utiliza o risco de "mercado" (beta) em vez do risco total (desvio padrão). A boa eficiência de desempenho é medida por um índice alto.',
      },
      valueAtRisk: {
        title: "Valor em Risco (VaR)",
        para1:
          "VaR é uma estatística que quantifica a extensão das possíveis perdas dentro de uma carteira ao longo de um período específico e com uma probabilidade definida. VaR 1% em 1 mês = x% de perda em 1 mês com 1% de probabilidade. Quanto menor, melhor!",
        para2:
          "Na prática, é o quantil dos retornos da estratégia correspondente ao p-valor (1% ou 5%).",
      },
    },
  },
  home: {
    metaInfo: { title: (ctx) => `${ctx.named("orgName")} - Plataforma` },
    modifyInvestorProfile: "Modificar perfil de investidor",
    noProductsAvailable: "Nenhum produto disponível para o seu papel e perfil de investidor.",
    search: "Buscar",
    showMoreProducts: "Mostrar mais produtos",
  },
  products: {
    analysis: {
      analysisPeriod: "Período de análise",
      backtest: "Backtest",
      backtestOrLive: "Backtest ou live",
      benchmarks: "Benchmarks",
      live: "Ao vivo",
      liveProduct: (ctx) => `${ctx.named("productName")} ao vivo`,
      shareClass: "Classe de ações",
      graphs: {
        considerApproximation: "Esses valores devem ser considerados como uma aproximação.",
        weHave: "Nós temos",
        weWant: "Nós queremos",
        titles: {
          assetClassesWeights: "Pesos das classes de ativos",
          beta: "Beta",
          constituents: "Constituintes",
          contango: "Contango",
          diffMonthlyReturns: "Dif. dos retornos mensais",
          drawdowns: "Quedas",
          exposureIndices: "Exposição a índices",
          exposureOverTime: "Exposição ao longo do tempo",
          indicators: "Indicadores",
          monthlyReturns: "Retornos mensais",
          performance: "Desempenho",
          ratioVixFutures: "Relação dos futuros do VIX",
          recentDataOverview: "Visão geral dos dados recentes",
          relativeDrawdowns: "Quedas relativas",
          relativePerformance: "Desempenho relativo",
          riskAdjustedPerformance: "Desempenho ajustado ao risco",
          rollingVRatioLevel: "Nível de rolagem do V-Ratio",
          statistics: "Estatísticas",
          returnProfile: "Perfil de retorno",
          top10ConstituentsWeight: "10 maiores constituintes atuais",
          top5ConstituentsWeight: "Peso dos 5 principais constituintes",
          vegaIndicatorConditions: "Condições do indicador Vega",
          vix: "VIX",
          vixPremium: "Prêmio do VIX",
          volatility: "Volatilidade",
          weightedPerformance: "Desempenho ponderado",
          yearlyReturns: "Retornos anuais",
        },
        tooltips: {
          beta: "Este gráfico mostra o beta do nosso produto em comparação com o nosso benchmark. Para calculá-lo, usamos dados do último ano disponível, por isso a métrica está ausente durante o primeiro ano de vida do nosso produto.",
          relativePerformance:
            "Este gráfico mostra uma comparação entre o desempenho do nosso produto e o desempenho do nosso benchmark. Para isso, nossos retornos cumulativos são divididos pelos retornos cumulativos do nosso benchmark. Isso significa que em 100%, ambos os desempenhos são semelhantes; caso contrário, quanto maior o número, melhor o desempenho do nosso produto em comparação ao benchmark.",
          riskAdjustedPerformance:
            "Este gráfico mostra o desempenho ajustado ao risco. Para calculá-lo, usamos dados do último ano disponível, por isso a métrica está ausente durante o primeiro ano de vida do nosso produto. O desempenho ajustado ao risco é definido como a relação entre o retorno anualizado e a volatilidade anualizada.",
          volatility:
            "Este gráfico mostra a volatilidade anualizada (ou seja, desvio padrão). Para calculá-la, usamos dados do último ano disponível, por isso a métrica está ausente durante o primeiro ano de vida do nosso produto.",
        },
      },
      metaInfo: { title: (ctx) => `${ctx.named("productName")} - Análise` },
      tab: {
        historical: "Histórico",
        historicalText:
          'Para produtos que já foram lançados, distinguimos a seção "Análise" entre "Análise ao Vivo" e "Análise Histórica". A seção "Análise Histórica" visa ilustrar o desempenho do produto antes de seu lançamento. O desempenho é baseado em uma simulação realista da estratégia. No entanto, nenhum ativo sob gestão estava envolvido durante o período correspondente.',
        internal: "Interno",
        live: "Ao vivo",
        liveText:
          'Para produtos que já foram lançados, distinguimos a seção "Análise" entre "Análise ao Vivo" e "Análise Histórica". A seção "Análise ao Vivo" ilustra o desempenho desde o lançamento do produto.',
      },
    },
    documentDownload: {
      failure:
        "O arquivo não pôde ser baixado, por favor entre em contato conosco se isso for inesperado.",
      needAuth: "Você precisa estar autenticado para baixar este documento. Faça login.",
      preparing: "O arquivo está sendo preparado para download...",
      seeProductOverview: "Ver visão geral do produto",
      success: "O arquivo foi baixado com sucesso.",
      metaInfo: {
        title: (ctx) => `${ctx.named("productName")} - Download de Documentos`,
      },
    },
    documents: {
      addFile: "Adicionar um arquivo",
      document: "Documento",
      nameValidationError:
        "O nome já está sendo usado por outro documento, por favor escolha um nome diferente.",
      noDocument: "Nenhum documento fornecido para este produto.",
      noArchivedDocument: "Nenhum documento arquivado para este produto.",
      noteDragAndDrop: "Nota: Arraste e solte as linhas para reordenar os documentos.",
      shareClass: "Classe de ações",
      archiveIconTooltip: "Arquivar",
      unarchiveIconTooltip: "Desarquivar",
      archivedDocumentsCardTitle: "Documentos arquivados",
      dialog: {
        fileDeletion: {
          title: "Exclusão de arquivo",
          text: "Você está prestes a excluir completamente o arquivo e não poderá acessá-lo novamente. Tem certeza?",
        },
        fileArchiving: {
          title: "Arquivamento de arquivo",
          text: 'Você está prestes a arquivar este arquivo, o que significa que ele será movido para seus "documentos arquivados" (logo abaixo dos documentos). Note que será possível desarquivá-lo. Deseja arquivá-lo?',
        },
        fileUnarchiving: {
          title: "Desarquivamento de arquivo",
          text: "Você está prestes a desarquivar este arquivo, o que significa que ele voltará para seus documentos normais. Deseja desarquivá-lo?",
        },
        needLogin: {
          text: "Para baixar este documento, você precisa estar autenticado.",
          title: "Você precisa estar autenticado",
        },
      },
      metaInfo: { title: (ctx) => `${ctx.named("productName")} - Documentos` },
      notify: {
        savingDocumentsError:
          "Ocorreu um erro ao tentar atualizar os documentos. Tente novamente mais tarde, ou entre em contato conosco.",
        savingDocumentsSuccess: "Documentos atualizados com sucesso.",
        fileDownloadError: "Erro ao baixar o documento, por favor entre em contato conosco.",
      },
      prompt: {
        fileDisplayName: "Nome de exibição do arquivo",
        loginRequired: "Login obrigatório?",
        uploadFile: {
          acceptText: "Fazer upload do arquivo",
          file: "Arquivo",
          fileLanguage: "Idioma do arquivo",
          shouldConsiderLastFactsheet:
            "Este arquivo deve ser considerado como o último factsheet para o produto?",
          title: "Fazer upload de arquivo",
        },
        editFile: {
          title: "Editar documento",
        },
      },
    },
    embedded: {
      perfGraph: {
        metaInfo: {
          title: (ctx) => `${ctx.named("productName")} - Gráfico de Desempenho Incorporado`,
        },
      },
      perfTable: {
        currency: "Moeda",
        inceptionDate: "Data de início",
        name: "Nome",
        lastPriceAsOf: "Último preço em",
        mtd: "MTD",
        si: "SI",
        y1: "-1 Ano",
        y3: "-3 Anos",
        ytd: "YTD",
        metaInfo: {
          title: (ctx) => `${ctx.named("productName")} - Tabela de Desempenho Incorporada`,
        },
      },
      statsTable: {
        feesIncluded: "As taxas estão incluídas.",
        metaInfo: {
          title: (ctx) => `${ctx.named("productName")} - Tabela de Estatísticas Incorporada`,
        },
      },
    },
    events: {
      covidCrisis: "Crise da COVID-19",
      crisis2022: "Crise de 2022",
      december2018Crisis: "Crise de dezembro de 2018",
      euroCrisis: "Crise do Euro",
      financialCrisis: "Crise financeira",
      alvolaLive: "Alvola Ao Vivo",
      convexusLive: "Convexus Ao Vivo",
      lstsLive: "LSTS Ao Vivo",
      protechLive: "ProTech Ao Vivo",
      sinceInception: "Desde o início",
      ynnovationLive: "Ynnovation Ao Vivo",
      calmgard: {
        calmgardCovid19Crisis: "Crise da Covid-19",
        calmgardEuroCrisisOne: "Crise do Euro I",
        calmgardEuroCrisisTwo: "Crise do Euro II",
        calmgardGreekAndChinaCrisis: "Crise da Grécia e da China",
        calmgardInterestRateSpeculationOne: "Especulação de Taxa de Juros I",
        calmgardInterestRateSpeculationTwo: "Especulação de Taxa de Juros II",
        calmgardPostFinancialCrisis: "Pós-Crise Financeira",
        calmgardUkraineWar: "Guerra da Ucrânia",
      },
      falgom: {
        falgomCoronavirusPandemic: "Pandemia de Coronavírus",
        falgomFedQuantitativeTapering: "Aperto Quantitativo do FED",
        falgomGlobalFinancialCrisis: "Crise Financeira Global",
        falgomGrowthSlowdownChina: "Desaceleração do Crescimento na China",
        falgomRecessionEurope: "Recessão na Europa",
        falgomVolmageddon: "Volmageddon",
      },
    },
    factsheet: {
      firstDateToInclude: "Primeira data a incluir (aaaa-mm-dd)",
      lastDateToInclude: "Última data a incluir (aaaa-mm-dd)",
      nameValidationError:
        "O nome já está sendo usado por outro de seus documentos, por favor escolha um diferente.",
      shareClass: "Classe de ações",
      year: "Ano",
      cardTitle: {
        assetClasses: "Classes de ativos",
        contacts: "Contatos",
        currencyExposure: "Exposição cambial",
        description: "Descrição",
        disclaimer: "Aviso legal",
        drawdowns: "Quedas",
        monthlyComment: "Comentário mensal",
        monthlyPerformance: "Desempenho mensal",
        monthlyReturns: "Retornos mensais",
        historicalPrices: "Preços históricos",
        instrumentTypes: "Tipos de instrumentos",
        productDetails: "Detalhes do produto",
        regions: "Regiões",
        relativePerformance: "Desempenho relativo",
        riskProfile: "Perfil de risco",
        sectors: "Setores",
        shareClasses: "Classes de ações",
        topHoldings: "Principais participações",
        volatility: "Volatilidade",
        yearlyPerformance: "Desempenho anual",
      },
      dragAndDrop: {
        addGenericComponent: "Adicionar componente",
        availableGenericComponents: "Componentes genéricos disponíveis",
        availablePredefinedComponents: "Componentes predefinidos",
        editFactsheet: "Editar factsheet",
        componentTooBig: "O componente é muito grande para ser contido em uma única página.",
        saveSuccessText: "A configuração do factsheet foi atualizada com sucesso.",
        stopEdit: "Parar edição",
        footer: {
          title: "Rodapé",
          page: "Página",
        },
        forcedElementDeletionWarning: {
          componentsWereRemoved:
            "Alguns componentes foram removidos do seu layout devido ao seu tamanho exceder a área exibível de uma única página ou sua coluna ser muito grande. Os IDs dos componentes removidos são:",
          resolveSolution:
            "Para resolver esse problema, reduza a altura dos componentes afetados, coloque o componente em uma nova linha única ou entre em contato conosco.",
          title: "Remoção forçada de componentes do layout",
        },
        header: {
          title: "Cabeçalho",
        },
        style: {
          title: "Estilo",
        },
      },
      generateFactsheet: {
        downloadFactsheet: "Baixar factsheet",
        generatingFactsheet: "Gerando factsheet ...",
        saveFactsheet: "Salvar factsheet em Documentos",
        notify: {
          generatingError: "Erro ao gerar o factsheet, por favor entre em contato conosco.",
          savedInDocumentsSection: "Seu factsheet foi salvo na seção Documentos.",
        },
        prompt: {
          acceptText: "Salvar factsheet",
          considerLastFactsheet:
            "Este arquivo deve ser considerado como o último factsheet para o produto?",
          fileDisplayName: "Nome de exibição do arquivo",
          loginRequired: "Login obrigatório?",
          title: "Gerar e enviar factsheet",
        },
      },
      metaInfo: { title: (ctx) => `${ctx.named("productName")} - Factsheet` },
      shareClassesTable: {
        bloomberg: "Bloomberg",
        class: "Classe",
        currency: "Moeda",
        inception: "Início",
        isin: "ISIN",
        mgmtFee: "Taxa de gestão",
        mtdPerf: "Desemp. MTD",
        nav: "NAV",
        navDate: "Data do NAV",
        perfFee: "Taxa de desempenho",
        siPerf: "Desemp. SI",
        ytdPerf: "Desemp. YTD",
      },
      statistics: {
        title: "Estatísticas",
        periods: {
          1: "1M",
          3: "3M",
          6: "6M",
          ytd: "YTD",
          12: "1A",
          36: "3A",
          60: "5A",
          max: "MAX",
        },
      },
      statisticsWithPeriods: {
        title: "Desempenho",
        periods: {
          1: "1M",
          3: "3M",
          6: "6M",
          ytd: "YTD",
          12: "1A",
          36: "3A",
          60: "5A",
          max: "MAX",
        },
      },
    },
    months: {
      short: {
        jan: "Jan",
        feb: "Fev",
        mar: "Mar",
        apr: "Abr",
        may: "Mai",
        jun: "Jun",
        jul: "Jul",
        aug: "Ago",
        sep: "Set",
        oct: "Out",
        nov: "Nov",
        dec: "Dez",
      },
    },
    overview: {
      assetClasses: "Classes de ativos",
      currencyExposure: "Exposição cambial",
      keyCharacteristics: "Características principais",
      historicalPrices: "Preços históricos",
      instrumentTypes: "Tipos de instrumentos",
      itd: "Retorno ITD",
      itdDescription: (ctx) =>
        `ITD (inception-to-date) é um período que começa a partir do início do produto (${ctx.named(
          "date"
        )}) e continua até o presente.`,
      lastChange: "Última mudança",
      lastDayChange: "Mudança no último dia",
      lastWeekChange: "Mudança na última semana",
      lastMonthChange: "Mudança no último mês",
      lastQuarterChange: "Mudança no último trimestre",
      lastYearChange: "Mudança no último ano",
      monthlyReturns: "Retornos mensais",
      lastPrice: "Preço",
      performance: "Desempenho",
      productDetails: "Detalhes do produto",
      productTimeline: "Linha do tempo do produto",
      regions: "Regiões",
      sectors: "Setores",
      shareClass: "Classe de ações",
      topHoldings: "Principais participações",
      yearlyReturns: "Retornos anuais",
      ytd: "Retorno YTD",
      ytdDescription:
        "YTD (year-to-date) é um período que começa no início do ano calendário atual e continua até o presente.",
      dragAndDrop: {
        addGenericComponent: "Adicionar componente",
        availableGenericComponents: "Componentes genéricos",
        availablePredefinedComponents: "Componentes predefinidos",
        editOverview: "Editar visão geral",
        saveSuccessText: "A configuração da visão geral foi atualizada com sucesso.",
        stopEdit: "Parar edição",
      },
      follow: {
        buttonText: "Seguir",
        buttonTextFollowing: "Seguindo",
        notify: {
          error:
            "Ocorreu um erro. Tente novamente ou entre em contato conosco se o problema persistir.",
          followSuccess: (ctx) => `Agora você está seguindo ${ctx.named("productName")}.`,
          unfollowSuccess: (ctx) => `Você não está mais seguindo ${ctx.named("productName")}.`,
        },
        promptNotFollowing: {
          acceptText: "Seguir",
          text: "Ao seguir este produto, você aceita receber informações relacionadas a ele de tempos em tempos. Você sempre poderá deixar de seguir para parar de receber essas informações.",
          title: "Seguir produto",
        },
        promptFollowing: {
          acceptText: "Deixar de seguir",
          text: "Você está atualmente seguindo este produto. Deseja deixar de segui-lo, o que significa que não receberá mais informações relacionadas ao produto?",
          title: "Deixar de seguir produto",
        },
        promptNotLoggedIn: {
          cancelText: "Criar uma conta",
          text: "Se você deseja seguir este produto, precisa estar logado em sua conta. Se não tiver uma conta, pode criar uma gratuitamente.",
          title: "Seguir produto",
        },
      },
      metaInfo: { title: (ctx) => `${ctx.named("productName")} - Visão Geral` },
      statsOptions: {
        1: "1 mês",
        3: "3 meses",
        6: "6 meses",
        ytd: "Ano até a data",
        12: "1 ano",
        36: "3 anos",
        60: "5 anos",
        max: "Desde o início",
      },
    },
    useCase: {
      allocationAlvola: "Alocação para Alvola",
      biggestDrawdowns: (ctx) => `Maiores quedas de ${ctx.named("benchmark")}`,
      description:
        "Nosso caso de uso ilustra como adicionar a estratégia Alquant Alvola a um portfólio de ações existente pode aumentar os retornos a longo prazo enquanto reduz a queda máxima. Selecione seu portfólio de ações desejado e alocação para a estratégia Alquant Alvola e descubra como a combinação teria se comportado no passado (antes do início de nosso produto em 02 de junho de 2021). Além disso, você também encontrará o desempenho durante os 10 maiores períodos de queda do seu portfólio de ações selecionado.",
      drawdowns: "Quedas",
      equityPortfolio: "Portfólio de ações",
      historicalResults: "Resultados históricos",
      portfolioAllocation: "Alocação de portfólio",
      protectPortfolio: "Proteja seu portfólio com Alvola",
      metaInfo: { title: (ctx) => `${ctx.named("productName")} - Caso de Uso` },
    },
  },
  settings: {
    metaInfo: { title: (ctx) => `Configurações - ${ctx.named("name")}` },
    changePassword: {
      buttonReset: "Enviar um e-mail para repor a palavra-passe",
      title: "Alterar senha",
      notify: {
        success: {
          title: "E-mail de redefinição enviado",
          text: (ctx) =>
            `Um e-mail de redefinição foi enviado para a caixa de entrada de ${ctx.named(
              "email"
            )}. Se não aparecer em breve, verifique a caixa de spam.`,
        },
        failure:
          "Não foi possível alterar sua senha, por favor entre em contato conosco usando o formulário de contato para corrigir esse erro!",
      },
    },
    deleteAccount: {
      deleteYourAccount: "Excluir sua conta",
      title: "Excluir conta",
      notify: {
        success: "Sua conta foi excluída com sucesso.",
      },
      dialog: {
        permanentlyDeleteData: {
          text: "Excluir sua conta excluirá permanentemente todos os seus dados.",
          title: "Confirmar",
        },
        recentLoginNeeded: "É necessário um login recente",
      },
    },
    editProfile: {
      company: "Empresa",
      email: "E-mail",
      firstName: "Nome",
      investorProfileCountry: "País",
      investorProfileType: "Tipo de investidor",
      lastName: "Sobrenome",
      phone: "Telefone",
      title: "Editar perfil",
      requiredFields: 'Campos marcados com <span class="text-danger">*</span> são obrigatórios.',
      dialog: {
        askValidation: {
          title: "Verificação de detalhes pessoais",
          text: "Por favor, verifique seus dados pessoais e salve-os quando tiver terminado.",
        },
      },
      notify: {
        errorText:
          "Não foi possível atualizar seus dados, por favor entre em contato conosco usando o formulário de contato para corrigir esse erro!",
        successText: "A atualização foi um sucesso",
      },
    },
    overview: {
      email: "E-mail",
      name: "Nome",
      title: "Visão geral da conta",
    },
  },
};
