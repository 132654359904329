export default {
  admin: {
    disclaimer: "Clause de non-responsabilité",
    enterText: "Saisissez un texte",
    operations: {
      bulkPriceDataUpdate: {
        benchmarks: "Benchmarks",
        benchmarkName: "Nom du benchmark",
        products: "Produits",
        productName: "Nom du produit",
        metaInfo: {
          title: "Admin - Opérations - Mise à jour des prix en masse",
        },
        dashboard: {
          createdAt: "Créé le",
          createdBy: "Créé par",
          downloadFile: "Télécharger le fichier",
          fileDownloadError: "Erreur lors du téléchargement du document, veuillez nous contacter.",
          loadingOperations: "Chargement des opérations ...",
          newUpdate: "Nouvelle mise à jour...",
          refreshButton: "Actualiser",
          status: "Statut",
          uploadedFile: "Fichier téléchargé",
          statuses: {
            failed: "Échoué",
            pending: "En attente",
            running: "En cours",
            succeeded: "Réussi",
          },
        },
        dryRunError: {
          backToUploadCsvButton: "Retour au téléchargement CSV",
          csvParsingErrorTitle: "Erreur d'analyse du CSV",
          csvParsingErrorText:
            "Le fichier fourni n'est pas un fichier CSV valide ou n'est pas au bon format. Veuillez vérifier que toutes les lignes contiennent des valeurs, que les colonnes sont correctement ordonnées et qu'il n'y a pas de valeurs manquantes.",
          numberFormatErrorTitle: "Erreur de format de nombre",
          numberFormatErrorText:
            "Les nombres dans votre fichier n'ont pas pu être analysés. Veuillez vous assurer que les nombres respectent le format requis : utilisez uniquement des caractères numériques standard, et utilisez un point (.) pour les décimales. Évitez tout autre caractère spécial.",
          duplicateDateErrorTitle: "Erreur de date en double",
          duplicateDateErrorText: `Les IDs suivants contiennent des dates en double. Chaque ID doit avoir des dates uniques pour chaque entrée.`,
          unknownIdsTitle: "IDs inconnus",
          unknownIdsText: `Les IDs suivants ne sont pas reconnus. Veuillez vous assurer que seuls les IDs valides provenant des tableaux de cette page sont utilisés.`,
          unauthorizedTitle: "IDs non autorisés",
          unauthorizedText: `Les IDs suivants ne sont pas autorisés pour les mises à jour.`,
        },
        preparationAndDryRun: {
          backToDashboardButton: "Retour au tableau de bord",
          idToUseInCsv: "ID à utiliser dans le CSV",
          uploadCsvForPreview: "Télécharger un fichier CSV pour prévisualisation...",
          title: "Télécharger le CSV pour prévisualisation des données",
          csvUploadDialog: {
            acceptText: "Générer la prévisualisation",
            title: "Télécharger le CSV pour prévisualisation",
            text: "Sélectionnez un fichier CSV pour prévisualiser les mises à jour de prix et de benchmark. Cette simulation vous permettra de revoir les modifications à l'écran suivant avant de les finaliser.",
          },
          explanation: {
            el1: 'La colonne ID doit contenir les valeurs trouvées dans la colonne "ID à utiliser dans le CSV" dans les tableaux ci-dessous.',
            el2: "La colonne DATE doit suivre le format AAAA-MM-JJ (par exemple, 2023-10-15, 1999-01-31).",
            el3: "Le PRICE doit être une valeur décimale.",
            para1:
              "Pour mettre à jour les prix et les benchmarks en masse, veuillez télécharger un fichier CSV avec trois colonnes : ID, DATE et PRICE. L'ordre des colonnes et la présence des en-têtes doivent être respectés.",
            para2:
              "Tous les prix que vous êtes autorisé à mettre à jour, qu'il s'agisse des prix d'un produit ou d'une référence, sont affichés dans le(s) tableau(x) ci-dessous. Si un élément que vous vous attendez à voir n'est pas présent, veuillez nous contacter.",
            tip: '<em>Astuce</em>: Cliquez <a href="/sample-bulk-price-data-update.csv" download><u>ici</u></a> pour télécharger un fichier CSV d\'exemple en référence.',
          },
        },
        reviewAndConfirm: {
          backToUploadCsvButton: "Retour au téléchargement CSV",
          confirmAndApplyChangesButton: "Confirmer et appliquer les modifications",
          firstDate: "Première date",
          id: "ID",
          lastDate: "Dernière date",
          name: "Nom",
          numberOfRows: "Nombre de lignes",
          reviewAndConfirmText:
            "Voici un résumé des mises à jour à appliquer. Veuillez noter que les prix prévus pour la mise à jour remplaceront entièrement les prix existants, car les anciens prix seront supprimés avant que les nouveaux ne soient insérés. Une fois que vous aurez pris connaissance des modifications, veuillez confirmer en bas de page.",
          title: "Revoir et confirmer les mises à jour des données",
          successDialog: {
            title: "Opération lancée",
            text: "L'opération a été lancée avec succès !",
          },
        },
      },
      generateFactsheets: {
        allLanguages: "Toutes les langues",
        allProducts: "Tous les produits",
        createdAt: "Créé le",
        factsheetGeneration: "Génération de factsheet",
        factsheetGenerationFilters: "Filtres de génération de factsheet",
        fileName: "Nom du fichier",
        generationNotPossibleDate:
          "La date sélectionnée est antérieure à la date de création du produit.",
        generationNotPossibleLang: "Cette langue n'est pas disponible pour le produit sélectionné.",
        generationPossible: "La génération de factsheet est possible.",
        generationWarningFileExists:
          "Un fichier portant ce nom et cette langue existe déjà. Continuer écrasera le fichier existant.",
        generateSelectedFactsheets: "Générer les factsheets sélectionnées",
        generationStatus: "Statut de la génération",
        backToDashboardButton: "Retour au tableau de bord",
        backToFiltersButton: "Retour aux filtres",
        language: "Langue",
        lastDateToInclude: "Dernière date à inclure",
        lastDayOfData: "Dernier jour des données",
        loadingOperations: "Chargement des opérations ...",
        newButton: "Générer nouvelles factsheets...",
        preparingFactsheetsGeneration:
          "Préparation de la liste des factsheets pour la génération...",
        product: "Produit",
        refreshButton: "Rafraîchir",
        requireLogin: "Connexion requise pour l'accès",
        selectFactsheetsForGeneration: "Sélectionner les factsheets pour la génération",
        selectLanguages: "Sélectionner les langues",
        selectProducts: "Sélectionner les produits",
        setAsLastFactsheet: "Définir comme dernière factsheet pour le produit",
        showAvailableFactsheet: "Afficher les factsheets disponibles",
        status: "Statut",
        langMultiselect: {
          placeholder: "Sélectionner les langues à inclure",
          selectLabel: "Appuyez sur Entrée pour sélectionner",
          deselectLabel: "Appuyez sur Entrée pour supprimer",
          selectedLabel: "Sélectionné",
          limitText: (ctx) => `et ${ctx.named("count")} de plus`,
        },
        metaInfo: {
          title: "Admin - Opérations - Générer des factsheets",
        },
        productsMultiselect: {
          placeholder: "Sélectionner les produits à inclure",
          selectLabel: "Appuyez sur Entrée pour sélectionner",
          deselectLabel: "Appuyez sur Entrée pour supprimer",
          selectedLabel: "Sélectionné",
          limitText: (ctx) => `et ${ctx.named("count")} de plus`,
        },
        statuses: {
          failed: "Échoué",
          pending: "En attente",
          running: "En cours",
          succeeded: "Réussi",
        },
        successDialog: {
          title: "Opération créée",
          text: "L'opération a été lancée avec succès !",
        },
      },
      bulkComponentUpdate: {
        action: "Action",
        actionIcons: {
          add: "Ajout",
          edit: "Modification",
          error: "Erreur",
        },
        backToDashboardButton: "Retour au tableau de bord",
        bulkUpdate: "Téléchargez un fichier CSV et voyez les changements potentiels...",
        bulkUpdateShortDesc:
          "Ajouter ou mettre à jour les composants génériques en important le fichier CSV de votre appareil",
        bulkUpdateDesc: {
          action: "Fournir le CSV pour mettre à jour ou ajouter les composants génériques.",
          instructions:
            "Le fichier CSV doit comporter 7 colonnes correspondant à l'ID du produit, l'ID du composant, le type de composant, la langue, la clé, la valeur et la page dans l'ordre.",
          trigger:
            "Après avoir téléchargé le fichier CSV, vous pourrez examiner les composants qui seront ajoutés ou modifiés. Si vous êtes satisfait des changements proposés, vous pourrez confirmer pour finaliser les mises à jour des composants.",
        },
        createdAt: "Créé le",
        createdBy: "Créé par",
        componentId: "ID du composant",
        errorsIntro: "Voici la liste des règles que le CSV viole :",
        errorListTitle: "Le fichier CSV fourni contient des erreurs",
        importing: {
          sucessfulImport: "Les composants sont mis à jour.",
          uknownFailure: "L'importation du composant a échoué.",
        },
        importComponentsButton: "Confirmer et appliquer les modifications",
        key: "Clé",
        lang: "Langue",
        loadingOperations: "Chargement des opérations...",
        loadingPreview: "Les modifications sont en cours de chargement...",
        metaInfo: {
          title: "Admin - Operations - Bulk Components Update",
        },
        numComponents: "Nombre de composants",
        numElements: "Nombre d'éléments",
        page: "Page",
        previewTitle: "Aperçu des changements qui seront apportés",
        product: "Produit",
        rules: {
          allowedLang: "La langue doit être une langue existante pour le produit correspondant.",
          columns:
            "Il doit y avoir 7 colonnes dans le CSV correspondant à dans l'ordre : le ID du produit, le ID du composant, le type du composant, le langue, le clé, la valeur, la page.",
          invalidFile:
            "Le fichier CSV ne peut pas être analysé. Assurez-vous que vous avez le bon fichier CSV.",
          keyDuplication: "Les clés ne peuvent pas être dupliqués.",
          linking: "Les composants liés ne peuvent pas être mis à jour simultanément.",
          multipleTypes: "Plusieurs types spécifiés pour le même composant.",
          naValue: "Le fichier CSV fourni ne peut pas contenir de valeurs NaN.",
          nonEditableComponent:
            "Un composant ne peut pas être édité car il s'agit d'un composant prédéfini ou le type de composant spécifié est erroné.",
          nonFloatValue:
            "Dans le cas des graphiques, la colonne des valeurs doit contenir des nombres.",
          numRowsPerLang: "Il doit y avoir le même nombre de lignes pour chaque langue.",
          productExists: "Les IDs des produits doivent être valides.",
          unsupportedPage: "Les pages de composants autorisées sont : 'overview' and 'factsheet'.",
          unsupportedType:
            "Les types de composants possibles sont les suivants: 'bar-chart', 'pie-chart', 'donut-chart', 'table'",
        },
        type: "Type",
        value: "valeur",
      },
      overrideHoldingData: {
        exportCsv: "Exporter un CSV des données des positions",
        importCsv: "Importer un CSV avec des modifications",
        onlyShowOverride: "Afficher uniquement les lignes avec des valeurs modifiées :",
        override: (ctx) => `Remplace: ${ctx.named("value")}`,
        saveChanges: "Enregistrer toutes les modifications",
        saveSuccess:
          "Les modifications des données des positions ont été enregistrées avec succès.",
        textUniqueKeys:
          "Ce tableau affiche les valeurs de base des données des positions pour tous les produits disponibles sur la plateforme. Chaque entrée unique est identifiée par une combinaison de 'Nom de la position', 'Devise' et 'ISIN'. Si cette clé unique est partagée entre plusieurs produits, elle apparaîtra comme une seule entrée ici.",
        textHowToSingleOverride:
          "Pour voir ou modifier les valeurs modifiées d'une ligne, cliquez dessus pour afficher les détails. Les valeurs modifiées qui diffèrent des valeurs de base seront mises en évidence : le texte vert indique des modifications déjà enregistrées dans le système, et le texte jaune indique des modifications non enregistrées.",
        textHowToBulkOverride:
          "Si vous souhaitez effectuer des modifications en masse, il est recommandé de télécharger les données des positions actuelles au format CSV. Vous pouvez ensuite effectuer des modifications avec un outil tableur (par ex. Microsoft Excel) et re-télécharger le fichier CSV modifié sur cette page. Tout fichier CSV téléchargé affichera d'abord un aperçu des modifications proposées, que vous devrez enregistrer pour les appliquer.",
        title: "Données des positions avec modifications",
        unsavedChangesAlert:
          "Vous avez des modifications non sauvegardées. Si vous partez sans les sauvegarder, elles seront perdues.",
        columns: {
          assetClass: "Classe d'actifs",
          assetClassOverride: "Modification de la classe d'actifs",
          currency: "Devise",
          currencyOverride: "Modification de la devise",
          holdingName: "Nom de la position",
          holdingNameOverride: "Modification du nom de la position",
          instrumentType: "Type d'instrument",
          instrumentTypeOverride: "Modification du type d'instrument",
          invalidValue: "Valeur invalide",
          isin: "ISIN",
          isinOverride: "Modification de l'ISIN",
          region: "Région",
          regionOverride: "Modification de la région",
          rowIndex: "Index de la ligne",
          sector: "Secteur",
          sectorOverride: "Modification du secteur",
        },
        uploadCsvErrorsDialog: {
          close: "Fermer",
          nonExistentAssetClass: "Classe d'actifs inexistante",
          nonExistentKey: "Clés inexistantes",
          nonExistentInstrumentType: "Type d'instrument inexistant",
          nonExistentRegion: "Région inexistante",
          nonExistentSector: "Secteur inexistant",
          selectErrorType: "Sélectionner le type d'erreur :",
          text: "Le fichier CSV que vous avez fourni contient des erreurs. Si des clés inexistantes (une clé étant la combinaison du nom de la position, de l'ISIN et de la devise) sont détectées, vérifiez les fautes de frappe dans ces champs. Si des valeurs modifiées inconnues apparaissent dans les colonnes 'assetClassOverride', 'instrumentTypeOverride', 'sectorOverride' ou 'regionOverride', vérifiez les valeurs disponibles pour ces colonnes.",
          title: "Erreurs de validation du CSV",
        },
        uploadCsvDialog: {
          cancel: "Annuler",
          import: "Importer",
          text: "Veuillez importer un fichier CSV au format requis. Vous pouvez exporter un exemple de CSV pour voir les colonnes attendues.",
          title: "Importer un CSV avec des modifications",
        },
      },
    },
    platformSettings: {
      platformSettings: "Paramètres de la plateforme",
      complianceTab: {
        checksActivated: "Vérifications activées",
        editTitle: "Éditer la conformité",
        title: "Conformité",
        investorProfile: {
          disclaimerTooltip:
            "Ce texte sera affiché aux utilisateurs qui arrivent sur la plate-forme et à qui l'on demande quel est leur profil d'investisseur (type et pays). Veillez à rédiger une clause de non-responsabilité appropriée et légale.",
          goToInvestorProfilePage: "Ouvrir la page",
          seeInvestorProfilePage: "Voir la page du profil d'investisseur",
          showLogoOnInvestorProfile: "Afficher le logo sur la page du profil d'investisseur",
          title: "Profil d'investisseur",
          tooltip:
            "Vous pouvez activer les contrôles du profil de l'investisseur, ce qui signifie que lorsqu'un utilisateur vient sur votre plateforme pour la première fois, il devra sélectionner son type d'investisseur (particulier, professionnel, institutionnel), ainsi que son pays. Vous pouvez ensuite configurer quel type d'investisseur et de quel pays est autorisé à voir chacun de vos produits/classes d'actions ; ceci peut être fait dans la section Admin>Produits, sous l'onglet \"Restrictions d'accès\", une fois que vous avez activé les contrôles ici.",
        },
        notify: {
          saveSuccessText: "Vos paramètres de conformité ont été mis à jour avec succès.",
          saveSuccessTextWithLang: (ctx) =>
            `Vos paramètres de conformité ont été mis à jour avec succès (language: ${ctx.named(
              "lang"
            )}).`,
        },
        ombudsman: {
          title: "Texte du Médiateur",
          tooltip:
            "Si vous avez besoin d'une référence à votre médiateur sur cette Plateforme, rédigez le texte qui sera montré aux utilisateurs vérifiant votre médiateur. Un lien vers ce texte sera visible dans le pied de page de la Plateforme, à côté de la Politique de confidentialité.",
        },
        productDisclaimer: {
          title: "Clause de non-responsabilité par défaut du produit",
          tooltip:
            'Définissez une clause de non-responsabilité par défaut qui sera utilisé pour tous les produits qui n\'ont pas de clause de non-responsabilité spécifique. Vous pouvez définir une clause de non-responsabilité spécifique à un produit en allant dans "Admin > Produits", puis en sélectionnant le produit de votre choix et en définissant la clause de non-responsabilité spécifique au produit dans l\'onglet "Général".',
        },
      },
      rolesTab: {
        editTitle: "Éditer les rôles",
        title: "Rôles",
        description:
          "Par défaut, seul le rôle \"admin\" existe, un rôle qui donne des droits d'administration sur tous les produits ainsi que l'accès aux paramètres de la plateforme. Il peut être utile de disposer de rôles plus fins, par exemple en donnant à certains utilisateurs des droits d'administration sur certains produits seulement. Afin de créer de tels rôles, utilisez le tableau suivant. Notez qu'une fois les rôles enregistrés, vous devrez vous rendre sur la page {0} pour attribuer les rôles aux utilisateurs de votre choix.",
        table: {
          roleNameHeader: "Nom du rôle",
          productsHeader: "Produits avec des droits d'administrateur",
          removeRoleTooltip: "Supprimer le rôle",
          addRoleTooltip: "Ajouter un rôle",
          productsMultiselect: {
            placeholder: "Sélectionnez un produit",
            selectLabel: "Appuyez sur la touche Entrée pour sélectionner",
            deselectLabel: "Appuyez sur la touche Entrée pour supprimer",
            selectedLabel: "Sélectionné",
            limitText: "et {count} de plus",
          },
        },
        notify: {
          saveSuccessText: "Vos rôles ont été mis à jour avec succès.",
        },
      },
      metaInfo: {
        title: "Admin - Paramètres de la plateforme",
      },
    },
    products: {
      contactUsToFixError:
        "Veuillez nous contacter en utilisant le formulaire de contact pour corriger cette erreur!",
      langTabError: "Certaines données sont invalides pour la langue:",
      overwriteDataAllShareClasses: "Écraser les données de toutes les classes d'actions",
      tabError: "Certaines données sont invalides",
      unsavedChangesAlert:
        "Vous avez des modifications non sauvegardées. Si vous partez sans les sauvegarder, elles seront perdues.",
      accessRestrictionsTab: {
        allCountries: "Tous les pays",
        countriesAllowedForInstitutionalInvestors:
          "Pays autorisés pour les investisseurs institutionnels",
        countriesAllowedForProfessionalInvestors:
          "Pays autorisés pour les investisseurs professionnels",
        countriesAllowedForRetailInvestors: "Pays autorisés pour les investisseurs particuliers",
        editAccessRestrictions: "Editer les restrictions d'accès",
        title: "Restrictions d'accès",
        warningEnablingAccessRestrictions:
          "Pour pouvoir modifier les restrictions d'accès, veuillez activer les contrôles du profil d'investisseur dans le {0}, sous l'onglet \"Conformité\".",
      },
      contactsTab: {
        addAnyPerson:
          "Ajoutez toute personne que vous souhaitez faire apparaître sur votre factsheet.",
        additionalText: "Texte supplémentaire",
        email: "Email",
        imageSquare: "Image (carré)",
        name: "Nom",
        phone: "Téléphone",
        role: "Rôle",
        title: "Contacts",
      },
      detailsTab: {
        editDetails: "Editer les détails",
        key: "Clé",
        showOnFactsheet: "Afficher sur la factsheet",
        title: "Détails",
        value: "Valeur",
      },
      dialog: {
        saveFieldInAllShareClasses: {
          title: "Écraser",
          text: `Vous allez écraser le champ dans toutes les autres classes d'actions, pas seulement de cette classe d'action. Êtes-vous sûr ?`,
        },
        saveInAllShareClasses: {
          title: "Écraser",
          text: (ctx) =>
            `Vous allez écraser les données "${ctx.named(
              "tabName"
            )}" de toutes les autres classes d'actions de ce produit avec les données "${ctx.named(
              "tabName"
            )}" de cette classe d'actions (pas seulement les dernières modifications que vous avez apportées). Êtes-vous sûr ?`,
        },
      },
      followersTab: {
        addedByAdmin: "Ajouté par un admin",
        addFollowerButton: "Ajouter un follower",
        email: "E-mail",
        exportCsv: "Exporter en CSV",
        importCsv: "Importer depuis un CSV",
        noDataText: "Personne ne suit ce produit",
        product: "Produit",
        removeFollowerTooltip: "Supprimer le follower pour le produit correspondant",
        sendEmail: "Envoyer un e-mail",
        showAnyShareClass: "Afficher les followers de n'importe quelle classe d'action",
        title: "Followers",
        addFollowerPrompt: {
          email: "E-mail du follower",
          emailAlreadyExists: "Il y a déjà un follower avec cette adresse e-mail.",
          file: "Fichier CSV",
          language: "Langue du follower",
          text: "Avant d'ajouter un follower à la main, vous devez être sûr qu'il ou elle soit d'accord de recevoir le genre d'informations que vous planifiez de leur envoyer.",
          titleAddFollower: "Ajouter un follower",
          titleImportFollowers: "Importer des followers",
        },
        notify: {
          newFollowerAddedSuccess: "Le nouveau follower a été ajouté avec succès.",
          newFollowerAddedError:
            "Il y a eu une erreur lors de l'ajout du nouveau follower. Essayez encore ou contactez-nous si le problème persiste.",
          noDataInCsv: "Aucun email à ajouter (après filtrage des emails déjà présents).",
          noEmailHeader:
            "Il manque à votre fichier un en-tête de colonne représentant les emails. Les en-têtes de colonne acceptés sont les suivants (insensibles à la casse) : email ou e-mail.",
          followerRemovedSuccess: "Le follower a été supprimé avec succès.",
          followerRemovedError:
            "Il y a eu une erreur lors de la suppression de ce follower. Essayez encore ou contactez-nous si le problème persiste.",
          wrongFormatEmails: (ctx) =>
            `Votre fichier contient des emails au mauvais format : ${ctx.named("emails")}`,
        },
        removeFollowerPrompt: {
          acceptText: "Supprimer le follower",
          text: (ctx) =>
            `Êtes-vous sûrs de vouloir supprimer "${ctx.named(
              "followerEmail"
            )}" suivant le produit "${ctx.named("productName")}"?`,
          title: "Supprimer le follower",
        },
      },
      generalTab: {
        editGeneralField: "Editer un champ général",
        hidden: "Caché",
        hiddenProductTooltip:
          "Cachez le produit sur la plateforme pour les utilisateurs non administrateurs.",
        icon: "Icône",
        isin: "ISIN",
        title: "Général",
      },
      holdingComponentsTab: {
        barChart: "Diagramme à barres",
        data: "Donnée",
        date: "Date",
        donutChart: "Graphique en donut",
        header: "En-tête",
        key: "Légende",
        loadingData: "Chargement des données...",
        noData: "Ce produit n'a pas de données sur les avoirs.",
        pieChart: "Graphique circulaire",
        shownOnOverview: "Affiché sur l'aperçu",
        table: "Tableau",
        title: "Composants de positions",
        type: "Type",
        weightPercent: "Poids (en %)",
        componentTitle: {
          assetClasses: "Classes d'actifs",
          currencyExposure: "Risque d'exposition",
          instrumentTypes: "Types d'instruments",
          regions: "Régions",
          sectors: "Secteurs",
          topHoldings: "Principales positions",
        },
      },
      holdingDataTab: {
        delete: "Supprimer",
        deleteSuccessText: "Les données de position ont été supprimées avec succès.",
        exportCsv: "Exporter en CSV",
        importCsv: "Importer depuis un CSV",
        importSuccessText: "Les données de position ont été importées avec succès.",
        title: "Données de position",
        loadingData: "Chargement des données...",
        noHoldingData: "Aucune donnée de position n'est disponible pour ce produit.",
        columns: {
          assetClass: "Classe d'actifs",
          currency: "Devise",
          holdingName: "Nom de la position",
          instrumentType: "Type d'instrument",
          isin: "ISIN",
          region: "Région",
          sector: "Secteur",
          value: "Valeur",
          weight: "Poids",
        },
        deleteHoldingDataDialog: {
          delete: "Supprimer",
          text: (ctx) =>
            `Vous êtes sur le point de supprimer complètement les données de position pour la date ${ctx.named(
              "date"
            )}. Si vous êtes sûr, continuez en cliquant sur le bouton ci-dessous.`,
          title: "Supprimer les données de position",
        },
        errors: {
          csvParseFail:
            "Le fichier CSV n'a pas pu être analysé. Assurez-vous qu'il est correctement formaté et qu'il possède une extension .csv valide.",
          numberFormat:
            "Certains nombres du fichier n'ont pas pu être analysés. Cela peut être dû à des séparateurs décimaux incohérents (par exemple, points ou virgules), à un formatage mixte ou à des caractères inattendus dans les colonnes. Notez que les colonnes 'weight' et 'value' sont obligatoires et doivent être fournies pour chaque ligne.",
          missingCurrency:
            "Chaque ligne du fichier CSV doit inclure une devise. Veuillez fournir une devise valide pour toutes les lignes et réessayez.",
          keyColumnsTupleNotUnique:
            "Les trois premières colonnes (nom de la position, devise et ISIN) doivent identifier de manière unique une position. Le fichier CSV fourni contient des lignes dupliquées avec les mêmes valeurs pour ces colonnes. Veuillez rendre ces lignes uniques ou les fusionner.",
          dateAlreadyExists:
            "La date spécifiée dans votre fichier CSV existe déjà dans le système. Pour continuer, veuillez supprimer les données existantes pour cette date via l'interface utilisateur et réessayez.",
          holdingAttributesValidation:
            "Une ou plusieurs valeurs dans les colonnes 'classe d'actifs', 'type d'instrument', 'secteur' ou 'région' sont invalides. Pour obtenir une liste complète des valeurs acceptables, veuillez contacter le support.",
        },
        uploadCsvDialog: {
          cancel: "Annuler",
          condition1:
            "Les colonnes doivent être dans l'ordre suivant : nom de la position, devise, ISIN, classe d'actifs, type d'instrument, secteur, région, poids et valeur. Toutes les colonnes sont obligatoires; les valeurs inconnues doivent être laissées vides.",
          condition2:
            "Le fichier doit inclure une ligne d'en-tête en haut, en utilisant les noms de colonnes spécifiés dans la condition précédente.",
          condition3:
            "La colonne de devise est obligatoire et ne doit pas être laissée vide pour une ligne.",
          condition4:
            "Chaque ligne doit avoir une combinaison unique de nom de la position, devise et ISIN.",
          date: "Date:",
          import: "Importer",
          text: "Veuillez fournir un fichier CSV contenant les données de position. Les conditions suivantes doivent être remplies pour réussir l'importation:",
          title: "Importer des données de position depuis un CSV",
        },
        uploadCsvErrorsDialog: {
          close: "Fermer",
          holdingAttribute: "Attribut de position",
          invalidValue: "Valeur invalide",
          rowNumber: "Numéro de ligne",
          title: "Erreurs dans le CSV des données de position",
        },
      },
      keyCharacteristicsTab: {
        header: "En-tête",
        icon: "Icône",
        paragraph: "Paragraphe",
        title: "Caractéristiques clés",
      },
      metaInfo: {
        title: "Admin - Paramètres des produits",
      },
      notify: {
        productSaveSuccess: (ctx) =>
          `Votre configuration du produit '${ctx.named(
            "productName"
          )}' a été mise à jour avec succès.`,
        productSaveSuccessWithLang: (ctx) =>
          `Votre configuration du produit '${ctx.named(
            "productName"
          )}' a été mise à jour avec succès (langue: ${ctx.named("lang")}).`,
      },
      priceDataTab: {
        title: "Données de prix",
        benchmarks: {
          title: "Benchmarks",
        },
        product: {
          title: "Product",
        },
      },
      selectCard: {
        loadingProducts: "Les produits sont en cours de chargement...",
        loadingShareClasses: "Les classes d'actions sont en cours de chargement...",
        noProducts: "Vous n'avez pas de produits.",
        selectProduct: "Sélectionner un produit",
        selectShareClass: "Sélectionnez une classe d'actions",
      },
    },
    users: {
      tabName: "Liste des utilisateurs",
      company: "Entreprise",
      country: "Pays",
      email: "Email",
      emailCopied: "L'email a été copié",
      investorType: "Type d'investisseur",
      name: "Nom",
      lastVisit: "Dernière visite",
      phone: "Téléphone",
      roles: "Rôles",
      metaInfo: {
        title: "Admin - Liste des utilisateurs",
      },
    },
  },
  auth: {
    email: "Email",
    errorAlreadyEmailAndPasswordRegister:
      "Vous êtes déjà inscrit avec cette adresse électronique et un mot de passe. Veuillez vous connecter avec cette adresse électronique et votre mot de passe.",
    errorInternal: "Une erreur interne s'est produite. Veuillez nous contacter.",
    or: "ou",
    password: "Mot de passe",
    login: {
      errorAccountNotExist:
        "Vous n'avez pas de compte sur cette plateforme, veuillez créer un compte et réessayer.",
      errorInvalidLoginCredentials:
        "Les informations d'identification fournies ne correspondent à aucun de nos comptes d'utilisateur. Cette divergence peut être due à une erreur d'email ou de mot de passe, à l'absence de compte associé à l'email saisi ou à la possibilité que l'email soit lié à une autre méthode de connexion (telle que Google). Veuillez vérifier vos informations et réessayer ou créer un compte si nécessaire.",
      errorNeedEmailVerification:
        "Vous devez vérifier votre email avant d'essayer de vous connecter sur notre plateforme. Veuillez vérifier vos emails (et votre dossier spam) pour trouver l'email. Si vous ne le trouvez pas, réinitialisez votre mot de passe.",
      noAccountYet: "Pas encore de compte ?",
      rememberMe: "Se souvenir de moi",
      welcomeBack: "Bienvenue, veuillez vous connecter à votre compte.",
      dialog: {
        alreadyLoggedInText: "Vous êtes déjà connecté.",
      },
      metaInfo: { title: "Plateforme - Connexion" },
      notify: {
        newAccessRight: {
          title: "Nouveaux droits d'accès",
          text: "Vous avez été déconnecté automatiquement parce que vous avez reçu de nouveaux droits d'accès qui nécessitent une nouvelle connexion pour être validés.",
        },
        registrationSuccess: {
          title: "Succès de l'inscription",
          text: "Inscription réussie, nous vous avons envoyé un message pour valider votre adresse e-mail, vérifiez votre boîte de réception!",
        },
        requiresRecentLogin: {
          title: "Réauthentification requise",
          text: "Pour poursuivre cette action, veuillez vous connecter à nouveau car votre session a expiré. Une fois réauthentifié, vous pourrez réessayer l'action.",
        },
        unexpectedError: {
          title: "Erreur inattendue",
          text: "Une erreur inattendue s'est produite. Veuillez vous reconnecter. Si le problème persiste, contactez le service d'assistance pour obtenir de l'aide.",
        },
      },
    },
    login2fa: {
      enterCode: "Veuillez saisir le code à 6 chiffres de votre application d'authentification.",
      incorrectCode: " Le code n'est pas correct. Veuillez réessayer.",
      title: "Authentification à 2 facteurs",
      verify: "Vérifier",
      verifying: "Vérification...",
    },
    register: {
      alreadyRegistered: "Déjà inscrit?",
      createAccount: "Créer un compte",
      errorAlreadyEmailAndPasswordRegister:
        "Vous avez déjà un compte avec cette adresse électronique. Veuillez vous connecter à votre compte.",
      errorAlreadyGoogleRegister:
        "Vous êtes déjà inscrit avec ce compte Google. Veuillez vous connecter avec ce compte.",
      errorAlreadyMicrosoftRegister:
        "Vous êtes déjà inscrit avec ce compte Microsoft. Veuillez vous connecter avec ce compte.",
      fillBelowForm:
        'Remplissez le formulaire ci-dessous pour créer un nouveau compte. Les champs marqués d\'un <span class="text-danger">*</span> sont obligatoires.',
      registerWith: "S'inscrire avec: ",
      form: {
        agreeAdvertisementOnly:
          "J'accepte explicitement que toutes les informations fournies sur cette plateforme le sont uniquement à des fins d'information et de publicité et ne doivent pas être interprétées comme une offre, un conseil ou une recommandation d'acheter ou de vendre un titre particulier ou de conclure une quelconque transaction.",
        certifyAdult: "Je certifie que j'ai 18 ans ou plus.",
        company: "Entreprise",
        confirmPassword: "Confirmer le mot de passe",
        email: "Email",
        firstName: "Prénom",
        investorProfileCountry: "Pays",
        investorProfileType: "Type d'investisseur",
        lastName: "Nom de famille",
        needAgree: "Vous devez accepter",
        phone: "Téléphone",
        privacyPolicy: "politique de confidentialité",
        registeringAccept: "En vous inscrivant, vous acceptez nos {0} et {1}.",
        termsOfUse: "conditions d'utilisation",
      },
      dialog: {
        alreadyLoggedInText: "Vous êtes déjà connecté.",
      },
      metaInfo: { title: "Platform - Inscription" },
    },
    register2fa: {
      generatingQrCode: "Générer le code QR...",
      incorrectCode: "Le code n'est pas correct. Veuillez réessayer.",
      need2fa:
        'Pour accéder à la plateforme, veuillez configurer l\'authentification à 2 facteurs (2FA). Scannez le code QR ci-dessous ou entrez la clé secrète fournie dans une application d\'authentification, telle que "Google Authenticator" ou "Microsoft Authenticator"',
      title: "Configurer l'authentification à 2 facteurs",
      verify: "Vérifier",
      verifying: "Vérification...",
    },
    resetPassword: {
      title: "Réinitialiser le mot de passe",
      sendEmail: "Envoyer un email",
      dialog: {
        internalErrorText: "Une erreur interne s'est produite. Veuillez nous contacter.",
        resetEmailSent: {
          title: "Email de réinitialisation envoyé",
          text: (ctx) =>
            `Un email de réinitialisation a été envoyé à la boîte de réception de ${ctx.named(
              "email"
            )}. S'il n'apparaît pas rapidement, vérifiez la boîte à spam.`,
        },
        unknownEmail: {
          title: "L'email n'existe pas.",
          text: "L'email que vous avez fourni pour réinitialiser le mot de passe n'existe pas. Pour créer un compte avec cet email, allez à la page d'inscription.",
        },
      },
      metaInfo: { title: "Plateforme - Réinitialiser le mot de passe" },
    },
  },
  compliance: {
    investorProfile: {
      agreeAndConfirmSelection: "Accepter et confirmer la sélection",
      byClickingAcceptDisclaimer:
        "En cliquant sur le bouton ci-dessous, je confirme que j'ai lu et accepté la clause de non-responsabilité ci-dessus et que je suis d'accord avec les {0} et la {1} de cette plateforme.",
      pleaseChooseProfile:
        "Veuillez choisir votre pays de domicile et votre profil d'investisseur pour continuer.",
      privacyPolicy: "politique de confidentialité",
      termsOfUse: "conditions d'utilisation",
      title: "Profil d'investisseur",
      metaInfo: {
        title: "Plateforme - Profil d'investisseur",
      },
    },
    ombudsman: {
      title: "Médiateur",
      metaInfo: {
        title: "Plateforme - Médiateur",
      },
    },
    privacyPolicy: {
      endDate: "Crissier, 31 août 2023",
      title: "Politique de confidentialité",
      metaInfo: { title: "Plateforme - Politique de confidentialité" },
      introductionParagraph: {
        title: "Introduction",
        para1:
          "Alquant SA (ci-après dénommée Alquant, 'nous', 'notre') est la société qui exploite cette plateforme. Alquant SA respecte la vie privée des utilisateurs de sa présence sur le web. Cela signifie que les données personnelles sont collectées, traitées et utilisées conformément aux dispositions du droit applicable en matière de protection des données et sur la base de la présente politique de confidentialité.",
        para2:
          "Ceci s'applique également - et en particulier - à la coopération avec les partenaires et les tiers auxquels nous faisons appel pour le traitement des contrats.",
        para3:
          "En acceptant la politique de confidentialité suivante, vous consentez aux processus de traitement des données d'Alquant détaillés ci-dessous concernant la collecte, le traitement et l'utilisation des données conformément aux lois sur la protection des données et aux dispositions suivantes. Ce consentement peut être révoqué à tout moment avec effet futur.",
        para4:
          "Notre pratique en matière de protection des données est donc conforme à la nouvelle loi fédérale sur la protection des données (nFADP) et, le cas échéant, au règlement général sur la protection des données (RGPD) de l'UE.",
      },
      accountableAuthorityParagraph: {
        title: "Autorité responsable / Représentant de l'UE",
        switzerland: "Suisse",
        euRepresentativeSub: {
          title: "Représentant de l'UE",
          para1:
            "Dans les cas visés à l'article 3, paragraphe 2, du règlement (UE) 2016/679 (règlement général sur la protection des données ; RGPD) et conformément à l'article 27 du RGPD en tant que représentant dans l'UE est désigné et en référence à l'exigence suisse nFADP d'une fonction de délégué à la protection des données:",
          para3:
            "Vous pouvez joindre notre représentant UE à l'adresse suivante : info(at)alquant.com",
        },
        accountableAuthoritySub: {
          email: "Email",
          phone: "Téléphone",
          title: "Autorité responsable et fonction de protection des données selon le PFDA suisse",
          para1: "La responsabilité du traitement des données à caractère personnel incombe à:",
          para3:
            "Pour toute demande relative à la protection de vos informations personnelles, veuillez contacter notre responsable de la protection de la vie privée à l'adresse suivante: compliance(at)alquant.com.",
        },
      },
      personalDataParagraph: {
        title: "Données personnelles",
        para1:
          "Les données personnelles sont toutes les informations relatives à une personne physique identifiée ou identifiable ; une personne physique est considérée comme identifiable si elle peut être identifiée directement ou indirectement, notamment par référence à un identifiant tel qu'un nom, un numéro d'identification, des données de localisation, un identifiant en ligne ou une ou plusieurs caractéristiques particulières exprimant l'identité physique, physiologique, génétique, mentale, économique, culturelle ou sociale de cette personne physique. Il s'agit, par exemple, de votre nom, de votre numéro de téléphone, de votre adresse, ainsi que de toutes les données d'inventaire que vous nous fournissez lors de l'inscription et de la création de votre compte client. Les données statistiques ou les données anonymes, que nous collectons par exemple lorsque vous visitez notre boutique en ligne et qui ne peuvent pas être directement liées à votre personne, ne sont pas couvertes ici.",
      },
      purposeOfDataCollectionParagraph: {
        title: "Objectifs de la collecte de données",
        para1:
          "a) La collecte et le traitement de ces données sont effectués dans le but de respecter le contrat, de permettre l'utilisation de notre site Internet (établissement de la connexion), d'assurer en permanence la sécurité et la stabilité du système, d'optimiser notre présence sur Internet et à des fins statistiques internes. Nous collectons, enregistrons et traitons les données personnelles uniquement dans le respect des dispositions légales applicables et dans la mesure où cela est à la fois nécessaire et requis pour remplir et améliorer les obligations d'exécution contractuelles entre nous et l'utilisateur / le client. Cela inclut également le stockage des données pour d'éventuelles demandes de garantie après la fin du contrat.",
        para2:
          "b) En outre, nous utilisons vos données dans le cadre du droit applicable à nos propres fins de marketing afin d'optimiser notre présence sur le web et d'attirer votre attention sur des services qui pourraient vous intéresser particulièrement.",
        para3:
          "c) En outre, la collecte, le traitement et l'utilisation des données personnelles n'ont lieu que si vous avez donné votre consentement.",
      },
      newsletterParagraph: {
        title: "Newsletter",
        para1:
          "Alquant aime vous informer régulièrement sur les sujets et projets actuels autour de notre domaine d'activité via la newsletter. Vous recevrez une newsletter de notre part uniquement sur demande expresse. Nous utilisons le processus d'opt-in pour l'envoi de la newsletter, ce qui signifie que vous recevrez notre newsletter par email à condition que vous vous soyez préalablement inscrit explicitement à l'envoi de la newsletter. Lorsque vous vous êtes inscrit à la newsletter, votre adresse email sera utilisée, avec votre consentement, à nos propres fins promotionnelles jusqu'à ce que vous vous désinscriviez de la newsletter. Vous pouvez vous désabonner à tout moment en envoyant un email le précisant à notre adresse de contact info(at)alquant.com. Nous avons le droit de mandater des tiers pour le traitement technique des activités promotionnelles et sommes autorisés à transmettre vos données à cette fin.",
        para2:
          "Les données que vous avez fournies lors de votre inscription à la newsletter (nom et email) seront stockées et utilisées uniquement pour l'envoi de la newsletter.",
      },
      legalBasisParagraph: {
        title: "Base légale pour le traitement",
        para1:
          "Nous traitons vos données personnelles principalement pour remplir des obligations contractuelles et nos obligations légales. En outre, en acceptant cette politique de confidentialité, vous consentez à toutes les opérations de traitement spécifiées dans cette politique de confidentialité. Bien entendu, vous pouvez révoquer votre consentement à tout moment ; une déclaration à compliance(at)alquant.com est suffisante. Tout traitement effectué jusqu'à ce moment ne sera pas affecté par votre annulation.",
      },
      legitimateInterestParagraph: {
        title: "Intérêts légitimes pour le traitement",
        para1:
          "En plus de la base légale susmentionnée pour le traitement des données, nous traitons également les données sur la base de nos intérêts légitimes. Cela s'applique en particulier, mais sans s'y limiter, au traitement à des fins de marketing et de promotion. Nous poursuivons, en particulier, les intérêts suivants:",
        list1: {
          el1: "assurer l'utilisation potentielle du site web",
          el2: "prévenir la fraude",
          el3: "assurer la sécurité et la stabilité des systèmes informatiques",
          el4: "nos propres objectifs de marketing",
          el5: "des objectifs statistiques internes",
        },
      },
      disclosureParagraph: {
        title: "Divulgation",
        para1:
          "Nous ne divulguons vos données personnelles que si vous avez donné votre consentement explicite, s'il existe une obligation légale de le faire ou si cela est nécessaire pour faire valoir nos droits - en particulier pour faire valoir les droits découlant de la relation contractuelle. En outre, nous communiquons vos données à des tiers, dans la mesure où cela est nécessaire à l'exécution du contrat. À cette fin, nous communiquons les données requises à des filiales, des entreprises de transport, des banques et d'autres prestataires de services. Ces prestataires de services utilisent vos données exclusivement pour le traitement des commandes et non à d'autres fins. En outre, nous utilisons certains services fournis par des prestataires externes pour optimiser votre visite sur notre site web. Il est garanti (contractuellement) que ces prestataires de services traitent vos données de manière confidentielle et conformément aux dispositions légales applicables, et que vos données ne sont utilisées qu'aux fins du service respectif. Tout transfert ou revente des données à des tiers par Alquant au-delà des dispositions de la présente politique de confidentialité est exclu, sauf si l'utilisateur a donné son consentement explicite à la divulgation de ses données. Dans ce cas, l'utilisateur peut révoquer ce consentement à tout moment. Les opérations de traitement effectuées précédemment sur la base de votre consentement ne seront pas affectées.",
        para2:
          "Alquant est en droit de transmettre vos données personnelles à des tiers à l'étranger si cela est nécessaire à l'exécution du contrat. Ces tiers sont tenus de respecter le même niveau de protection des données que le fournisseur. Si le niveau de protection des données dans un pays ne correspond pas au niveau suisse, nous garantissons contractuellement que la protection de vos données personnelles correspond en tout temps à celle de la Suisse.",
      },
      singleSignOnProcedureParagraph: {
        title: "Utilisation de procédures d'authentification unique",
        para1:
          "Sur notre site web, si vous disposez d'un profil Google ou Microsoft, vous pouvez vous inscrire pour créer un compte client ou vous enregistrer à l'aide du plugin social en utilisant la procédures d'authentification unique. Les plugins sont marqués du logo du fournisseur.",
        para2:
          "Lorsque vous appelez nos sites web qui contiennent un tel plugin, votre navigateur établit une connexion directe avec les ordinateurs du fournisseur. Le contenu du plugin est transmis par le site du fournisseur directement à votre navigateur, qui l'intègre au site web. En intégrant les plugins, le fournisseur reçoit l'information que vous avez appelé notre site web. Si vous êtes simultanément connecté au fournisseur, ce dernier peut attribuer la visite à votre profil.",
        para3:
          "Si vous ne souhaitez pas que le fournisseur collecte des données vous concernant via notre site web, vous devez vous déconnecter du fournisseur avant de visiter notre site web. Même si vous êtes déconnecté, les fournisseurs collectent des données anonymes via des plugins sociaux qui créent un cookie pour vous. Si vous vous connectez ultérieurement au fournisseur, ces données peuvent être attribuées à votre profil.",
        para4:
          "Si une connexion est fournie via un service de connexion sociale - Ex. Google Connect - des données sont échangées entre le fournisseur et notre site web. Dans le cas de Google Connect, il peut s'agir, par exemple, de données provenant de votre profil Google public. En utilisant de tels services de connexion, vous acceptez le transfert de données.",
        para5:
          "Veuillez consulter les avis de protection des données du fournisseur pour connaître l'objectif, l'étendue de la collecte des données et le traitement ultérieur de vos données par le fournisseur, ainsi que vos droits respectifs et les options de configuration pour protéger votre vie privée.",
        para7:
          "Si vous ne souhaitez pas que le fournisseur collecte des données vous concernant via ces cookies, vous pouvez sélectionner la fonction \"bloquer les cookies des fournisseurs tiers\" dans les paramètres de votre navigateur. Ensuite, s'il y a du contenu intégré provenant d'autres fournisseurs, le navigateur n'envoie pas de cookies au serveur.",
      },
      durationOfRetentionParagraph: {
        title: "Durée de la rétention",
        para1:
          "Nous conservons vos données personnelles aussi longtemps que nécessaire pour nous conformer aux lois applicables, ou que nous jugeons approprié, ou encore aussi longtemps qu'elles sont nécessaires aux fins pour lesquelles elles ont été collectées. Nous bloquerons ou supprimerons vos données personnelles dès qu'elles ne seront plus nécessaires et définitivement à l'expiration de la période de conservation maximale légale.",
        para2:
          "Vous pouvez demander à tout moment la suppression de vos données personnelles en nous envoyant une demande correspondante à compliance(at)alquant.com. Nous nous conformerons rapidement à votre demande, à condition que nous ne soyons pas tenus de les conserver pour d'autres raisons ou que nous en ayons besoin, par exemple pour faire valoir des droits.",
      },
      furtherDataSourcesParagraph: {
        title: "Autres sources de données",
        para1:
          "Outre les données que nous recueillons directement auprès de vous, nous obtenons également des données de registres publics (tels que le registre du commerce) et de fournisseurs tiers.",
        para2:
          "Comme les sources de données varient selon les clients, aucune information générale ne peut être donnée sur ces sources. Si vous le souhaitez, nous sommes bien entendu disposés à vous divulguer nos sources de données en détail.",
      },
      userRightsParagraph: {
        title: "Droits des utilisateurs",
        rightRevocationSub: {
          title: "Droit de révocation",
          para1:
            "Dans la mesure où vous nous avez donné votre accord pour collecter, stocker et utiliser des données personnelles en rapport avec le site web ou nos services, vous pouvez révoquer cet accord à tout moment. La révocation peut être envoyée par e-mail ou par écrit au contact mentionné ci-dessus dans la section 2 : Autorité responsable. Les effets de la révocation sont limités au stockage et à l'utilisation des données personnelles, qui ne peuvent être stockées et utilisées sans votre consentement préalable fondé sur une autorisation légale. La révocation n'a également aucun effet sur les procédures de traitement antérieures effectuées sur la base du consentement.",
        },
        furtherRightsSub: {
          title: "Droits supplémentaires",
          informationSub: {
            title: "Information",
            para1:
              "Sur demande écrite, nous vous donnerons à tout moment des informations sur vos données personnelles enregistrées. La demande de renseignements doit être faite par écrit et accompagnée d'une preuve d'identité (copie de la carte d'identité, du passeport ou d'un autre document d'identification officiel). L'exercice de ce droit est gratuit une fois par an ; pour toute autre demande, nous nous réservons le droit de facturer les frais encourus.",
          },
          correctionSub: {
            title: "Correction, suppression, restriction du traitement",
            para1:
              "En outre, vous avez la possibilité de faire corriger, bloquer ou supprimer vos données personnelles à tout moment. Veuillez contacter l'autorité interne, dont les coordonnées figurent à la section 2. Sont exclues de l'effacement uniquement les données dont nous avons besoin pour l'exécution de tâches en suspens ou pour faire valoir des droits et des prétentions existants, ainsi que les données que nous sommes tenus de conserver en raison de dispositions légales. Ces données seront bloquées.",
          },
          rightAppealSub: {
            title: "Droit de recours",
            para1:
              "Nous attachons une grande importance à la sauvegarde des droits de nos clients et donc aussi à la protection de vos données personnelles. Néanmoins, si vous estimez que nous traitons vos données de manière illégale, vous pouvez nous contacter à tout moment au +41 41 661 23 45 ou par e-mail compliance(at)alquant.com en précisant votre problème. Nous répondrons à vos préoccupations au mieux de nos capacités.",
            para2:
              "Si, contrairement à ce que vous attendiez, notre service clientèle ne peut vous fournir une assistance satisfaisante, vous pouvez à tout moment soumettre votre problème au contrôleur fédéral de la protection des données sous la forme d'une plainte formelle, qui fera l'objet d'une enquête.",
          },
          dataPortabilitySub: {
            title: "Portabilité des données",
            para1:
              "Sur demande écrite, vous avez également le droit de recevoir les données personnelles vous concernant dans un format structuré, courant et lisible par machine (fichier pdf) pour les transmettre à un tiers. Nous nous réservons le droit de facturer les frais encourus.",
          },
        },
      },
      cookiesParagraph: {
        title: "Utilisation des cookies",
        para1:
          "Pour améliorer notre gamme de produits, nous utilisons des cookies sur notre site web. Un cookie est un petit fichier texte qui est transféré sur votre ordinateur lorsque vous naviguez sur nos pages. Un cookie peut uniquement contenir des informations que nous envoyons à votre appareil électronique - ils ne peuvent pas être utilisés pour lire des données personnelles. Si vous acceptez les cookies sur notre site, nous n'avons pas accès à vos informations personnelles mais, grâce aux cookies, nous pouvons identifier votre ordinateur. Aucune donnée personnelle n'est stockée dans les cookies utilisés, de sorte que nous ne pouvons pas tirer de conclusions sur votre identité au moyen de la technologie des cookies. Seules des informations pseudonymisées sont générées.",
        para2:
          "Nous utilisons des cookies pour vérifier que vous respectez nos conditions d'utilisation.",
        para3:
          "Nous utilisons des cookies temporaires, basés sur la session : ils ne restent pas sur votre ordinateur. Lorsque vous quittez nos pages, le cookie temporaire est également supprimé. Grâce aux informations recueillies, nous pouvons analyser les modes d'utilisation et les structures de notre site web et l'optimiser en améliorant le contenu ou la personnalisation et en simplifiant l'utilisation.",
        para4:
          "En outre, nous utilisons des cookies persistants : ils restent sur votre ordinateur pour simplifier les services d'achat, de personnalisation et d'enregistrement lors de votre prochaine visite. Par exemple, ces cookies peuvent enregistrer ce que vous avez choisi d'acheter pendant que vous poursuivez vos achats. De même, vous ne devez saisir votre mot de passe qu'une seule fois sur les pages web qui nécessitent une connexion. Les cookies persistants peuvent être supprimés manuellement par l'utilisateur. Ils s'effacent d'eux-mêmes au bout d'un certain temps.",
        para5:
          "Des cookies sont également utilisés par certains de nos partenaires publicitaires (cookies tiers). Ces cookies nous aident à adapter notre site aux besoins spécifiques de nos clients, par exemple en montrant des publicités qui pourraient réellement vous intéresser. L'utilisation de ces cookies nous permet de recueillir des informations sur les produits que vous avez consultés ou recherchés ou sur les pages que vous avez visitées précédemment. Ces cookies sont stockés sur votre disque dur et s'effacent après un certain temps. Aucune information personnelle n'est également stockée dans les cookies utilisés par nos partenaires publicitaires. L'utilisation est pseudonymisée afin qu'aucune conclusion ne soit tirée sur votre identité.",
        para6:
          "La plupart des navigateurs (tels que Firefox, Chrome, Internet Explorer, Safari, etc.) acceptent les cookies par défaut. Vous pouvez autoriser ou bloquer les cookies temporaires et persistants dans les paramètres de sécurité - indépendamment les uns des autres. Nous attirons toutefois votre attention sur le fait que certaines fonctionnalités ne sont pas disponibles sur notre site web et que certains sites web peuvent ne pas s'afficher correctement si vous désactivez les cookies.",
        para7:
          "Les données stockées dans nos cookies ne seront pas liées à vos données personnelles (nom, adresse, etc.) sans votre consentement explicite.",
      },
      liabilityForLinksParagraph: {
        title: "Responsabilité pour les liens",
        para1:
          "Alquant déclare expressément par la présente qu'au moment de la création des liens, aucun contenu illégal n'était identifiable sur les pages à lier. Nous n'avons aucune influence sur la conception actuelle et future, le contenu ou la paternité des pages liées. C'est pourquoi nous nous distançons expressément par la présente de tous les contenus de toutes les pages liées qui ont été modifiées après la mise en place du lien. Cette déclaration s'applique à tous les liens et références de notre site Internet. En ce qui concerne les contenus illégaux, erronés ou incomplets et, en particulier, les dommages résultant de l'utilisation ou de la non-utilisation de telles informations, seul le fournisseur de la page à laquelle il a été fait référence est responsable et non celui qui se contente d'établir un lien vers la publication concernée.",
      },
      webAnalysisParagraph: {
        title: "Analyse web",
        googleAnalyticsSub: {
          title: "Google Analytics",
          para1:
            "Ce site Web utilise Google Analytics, un service d'analyse Web fourni par Google Inc. (Google) pour la conception de sites Web en fonction des besoins. Nous ne recevons que des évaluations de données, sans références personnelles.",
          para2:
            "Google Analytics utilise des cookies : des fichiers texte qui sont stockés sur votre ordinateur et qui permettent d'analyser votre utilisation du site web. Les informations générées par les cookies concernant votre utilisation du présent site web (y compris votre adresse IP) sont transmises et stockées sur les serveurs de Google aux États-Unis. L'anonymisation IP a été activée sur ce site web de sorte que l'adresse IP des utilisateurs de Google dans les États membres de l'Union européenne ou dans d'autres États contractants de l'accord sur l'Espace économique européen est tronquée avant la transmission. Ce n'est que dans des cas exceptionnels que l'adresse IP complète sera envoyée à un serveur de Google aux États-Unis et y sera tronquée. Google utilisera ces informations pour le compte de l'exploitant du site Internet afin d'évaluer l'utilisation du site par les utilisateurs, de compiler des rapports sur l'activité du site et de fournir à l'exploitant du site d'autres services liés à l'activité du site et à l'utilisation d'Internet. Google transmettra également ces informations à des tiers si la loi l'exige ou si des tiers traitent ces données pour le compte de Google. Google n'associera jamais votre adresse IP à d'autres données de Google.",
          para3:
            "Vous pouvez empêcher l'installation des cookies en paramétrant votre logiciel de navigation en conséquence. Nous attirons toutefois votre attention sur le fait que, dans ce cas, vous ne pourrez peut-être pas utiliser pleinement toutes les fonctions de ce site Internet. En utilisant ce site Web, vous consentez au traitement des données collectées sur vous par Google de la manière et aux fins décrites ci-dessus.",
          para4:
            "En outre, vous pouvez empêcher Google de collecter les données générées par les cookies et liées à votre utilisation du site (y compris votre adresse IP) et le traitement de ces données par Google en téléchargeant et en installant le plugin de navigateur disponible à l'adresse suivante <a href=\"https://tools.google.com/dlpage/gaoptout\" >https://tools.google.com/dlpage/gaoptout</a >. Pour plus d'informations sur les conditions d'utilisation et les règles de confidentialité de Google Analytics, consultez",
          para5:
            '<a href="https://www.google.com/analytics/terms/" >https://www.google.com/analytics/terms/</a > et',
          para6:
            '<a href="https://policies.google.com/privacy?hl=en" >https://policies.google.com/privacy?hl=en</a >',
        },
        googleFontsSub: {
          title: "Google Fonts",
          para1:
            'Ce site web utilise également Google Fonts (Open Scans). Pour des raisons techniques, votre adresse IP doit être transmise à Google afin que les polices puissent être transférées à votre navigateur. Pour plus d\'informations sur Google Fonts, consultez <a class="break-all" href="https://developers.google.com/fonts/faq#what_does_using_the_google_fonts_api_mean_for_the_privacy_of_my_users" >https://developers.google.com/fonts/faq#what_does_using_the_google_fonts_api_mean_for_the_privacy_of_my_users</a > .',
        },
      },
      securityMeasureParagraph: {
        title: "Mesures de sécurité",
        para1:
          "Nous appliquons des mesures de sécurité techniques et organisationnelles pour protéger de manière appropriée vos informations personnelles contre tout accès non autorisé, abus, manipulation accidentelle ou intentionnelle, perte ou destruction. Afin de protéger votre vie privée, nous utilisons une connexion sécurisée pour notre site web, ce que vous pouvez constater par la mention 'https' (https signifie Hypertext Transfer Protocol Secure) au tout début de la barre d'adresse de votre navigateur. De cette manière, la connexion est cryptée via SSL/TSL et vos données ne peuvent pas être interceptées par des tiers. Les mesures de sécurité techniques visant à protéger vos données personnelles sont régulièrement vérifiées et, si nécessaire, mises à jour. Ces principes s'appliquent également aux entreprises qui traitent et utilisent les données personnelles en notre nom et conformément à nos instructions.",
      },
      legalBasisGeneralDataProtectionParagraph: {
        title: "Base légale règlement général sur la protection des données",
        para1:
          "Conformément aux dispositions du Règlement général sur la protection des données (RGPD) applicable à partir du 25 mai 2018, nous vous informons que votre consentement à l'envoi à l'adresse électronique que vous fournissez sera donné sur la base de l'art. 6 para. 1 lit. a et de l'art. 7 GDPR. La liste d'envoi correspondante est utilisée sur la base de nos intérêts légitimes conformément à l'art. 6 para. 1 lit. f du GDPR. Nous sommes intéressés par l'utilisation d'un site web et d'un système convivial et sécurisé qui sert nos intérêts commerciaux mais répond également aux attentes de nos utilisateurs et clients.",
      },
      changesParagraph: {
        title: "Changements",
        para1:
          "Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. La politique de confidentialité ne constitue pas une relation contractuelle ou autre relation juridique formelle avec ou au nom d'une quelconque partie.",
      },
    },
    termsOfUse: {
      endDate: "Crissier, 3 février 2022",
      title: "Conditions d'utilisation",
      metaInfo: { title: "Plateforme - Conditions d'utilisation" },
      declarationOfAcceptanceParagraph: {
        title: "Déclaration d'acceptation par l'utilisateur",
        para1:
          "Ce site web est exploité par Alquant SA. En utilisant ce site web (ou une page détournée vers cette adresse web, ci-après dénommée \"site web d'Alquant\"), vous confirmez que vous avez lu, compris et accepté les conditions d'utilisation suivantes, y compris les conditions juridiques. Si vous n'acceptez pas ces conditions d'utilisation, veuillez ne plus visiter le site web d'Alquant.",
      },
      limitedAccessParagraph: {
        title: "Accès restreint",
        para1:
          "Le site Internet d'Alquant n'est pas destiné aux personnes morales et physiques qui, en raison de leur nationalité, de leur siège social, de leur lieu de résidence ou pour toute autre raison, sont soumises à un système juridique qui interdit ou restreint notamment",
        list1: {
          el1: "la publication des contenus,",
          el2: "accès au site web d'Alquant,",
          el3: "les activités d'un prestataire de services financiers étranger,",
          el4: "l'admission de produits financiers (ex. certificats ou fonds).",
        },
        para3:
          "En outre, il n'est pas destiné à être utilisé par des personnes morales ou physiques dans des pays où Alquant SA ou toute société qui lui est associée ne dispose pas de l'enregistrement et de l'autorisation nécessaires pour opérer.",
        para4:
          "Les personnes qui sont soumises à l'une ou à l'ensemble de ces restrictions ne peuvent pas accéder au site Web d'Alquant. Cela s'applique en particulier aux citoyens des États-Unis, du Royaume-Uni, du Canada, du Japon, de la Chine et de Singapour, ou aux personnes résidant dans les pays susmentionnés. Veuillez vous renseigner sur les restrictions légales correspondantes dans votre pays.",
        para5: "Avis pour les investisseurs institutionnels",
        para6:
          "Les produits financiers destinés aux investisseurs professionnels ne sont disponibles que pour les investisseurs qualifiés. Aux termes de l'art. 10, al. 3, de la loi sur les placements collectifs de capitaux ainsi que de l'art. 6 de l'Ordonnance sur les placements collectifs de capitaux, les investisseurs qualifiés comprennent les investisseurs institutionnels ayant une trésorerie professionnelle, à savoir les banques et les négociants en valeurs mobilières (y compris leurs clients ayant un contrat écrit de gestion de fortune), les caisses de pension, les compagnies d'assurance, les directions de fonds et les autorités communales et cantonales, ainsi que les entreprises industrielles et commerciales. En sélectionnant \"Investisseurs professionnels\", vous confirmez que vous êtes un investisseur qualifié selon la définition donnée ci-dessus.",
      },
      propertyRightsParagraph: {
        title: "Droits de propriété",
        para1:
          "Tous les éléments du site Internet d'Alquant sont protégés en tant que propriété incorporelle et sont détenus par Alquant SA ou par des tiers. L'utilisation du site Internet d'Alquant n'entraîne le transfert d'aucun droit, en particulier aucun droit relatif aux logiciels, aux marques ou aux éléments du site Internet d'Alquant. Les indications de copyright et les noms de marque ne peuvent pas être modifiés ou supprimés. Les éléments du site Internet d'Alquant ne peuvent être reproduits dans leur intégralité ou en partie sans référence à la source complète. Le contenu du site Web d'Alquant ne peut être reproduit ou utilisé à des fins publiques ou commerciales sans l'accord écrit préalable d'Alquant SA.",
      },
      consultingReplacementParagraph: {
        title: "Ne remplace pas la consultation",
        para1:
          "Les informations et opinions publiées sur le site d'Alquant sont de nature générale. Elles ne sont ni destinées ni adaptées pour remplacer une consultation individuelle par un personnel spécialisé qui prendra en compte les circonstances réelles prévalant dans un cas individuel donné. Pour cette raison, ils ne représentent pas un conseil juridique, fiscal, financier, économique ou autre et ne peuvent être utilisés comme une aide à l'investissement.",
      },
      noOffersParagraph: {
        title: "Aucune offre",
        para1:
          "Les informations et les opinions publiées sur le site web d'Alquant ne constituent pas une publicité ou des recommandations et ne doivent pas être interprétées comme une demande, une offre ou une invitation à soumettre une offre:",
        list1: {
          el1: "pour l'acquisition ou la vente d'outils d'investissement,",
          el2: "pour effectuer toutes sortes de transactions,",
          el3: "pour accomplir tout autre type d'activité légale.",
        },
        para3: "Le site Internet d'Alquant a pour unique objet de fournir des informations.",
      },
      futurePerformanceParagraph: {
        title: "Performance future (indication du risque)",
        para1:
          "La performance future d'un outil d'investissement ne peut être déduite de l'évolution passée du taux, c'est-à-dire que le titre peut monter ou descendre. Le comportement du titre dans le passé n'est donc pas une garantie pour sa valeur future. Dans le cas de certains outils d'investissement, la perte de la totalité du capital investi est également possible dans des cas extrêmes. En outre, les fluctuations des taux de change peuvent également affecter l'investissement.",
      },
      noGuaranteeParagraph: {
        title: "Aucune garantie",
        para1:
          "Alquant SA n'offre aucune garantie (y compris la responsabilité envers les tiers) ni assurance pour",
        list1: {
          el1: "l'actualité, la précision, la fiabilité ou l'exhaustivité du contenu du site Web d'Alquant,",
          el2: "les résultats obtenus en utilisant le site web d'Alquant,",
          el3: "les contenus de tiers qui se trouvent sur le site web d'Alquant ou qui sont accessibles via celui-ci.",
        },
        para3: "En outre, Alquant SA ne peut être tenue pour responsable et ne peut garantir que",
        list2: {
          el1: "les fonctions du site web d'Alquant fonctionnent sans défaut et sont disponibles sans interruption,",
          el2: "le site Web d'Alquant et les autres composants utilisés pour accéder au site Web d'Alquant sont exempts de virus, etc..",
        },
        para5: "Alquant SA n'offre également aucune garantie pour",
        list3: {
          el1: "l'augmentation ou la stabilité de la valeur du capital investi dans les outils d'investissement,",
          el2: "le niveau des dividendes futurs.",
        },
      },
      linksParagraph: {
        title: "Liens",
        para1:
          "Alquant SA n'a pas vérifié les sites web de tiers liés au site Web d'Alquant et ne peut être tenu responsable de l'actualité, de la précision, de la fiabilité, de l'exhaustivité ou de la légalité des contenus et des offres de ces sites web. L'utilisateur accède donc à ces pages web à ses propres risques.",
        para2:
          "Les liens web ne peuvent être inclus dans des sites web en dehors d'un site web d'Alquant qu'avec l'accord préalable d'Alquant SA.",
      },
      serviceChangesParagraph: {
        title: "Changements de service",
        para1:
          "Alquant SA peut modifier, suspendre ou interrompre tout aspect des services à tout moment, y compris la disponibilité de toute fonction, base de données ou contenu des services. Alquant SA peut également imposer des limites sur certaines fonctions et services ou restreindre l'accès de l'utilisateur à tout ou partie des services sans préavis.",
        para2:
          "Alquant SA se réserve le droit, à sa seule discrétion, de mettre fin à l'accès de l'utilisateur aux services sans préavis.",
      },
      useOfInformationParagraph: {
        title: "Utilisation de l'information",
        para1:
          "L'utilisateur peut utiliser les informations publiées sur le site Web d'Alquant uniquement pour un usage professionnel interne ou un usage personnel et non commercial. En aucun cas l'utilisateur ne doit publier, vendre, louer, diffuser, retransmettre, redistribuer, diffuser, faire circuler ou autrement reproduire, fournir ou permettre l'accès à toute information d'<b>Alquant SA</b> dans n'importe quel format à quiconque ; à condition, toutefois, que l'utilisateur puisse fournir des informations aux utilisateurs, sur une base non continue, en utilisant uniquement les méthodes limitées suivantes : (a) dans les publicités écrites, la correspondance ou autre littérature, ou (b) au cours de conversations téléphoniques vocales ne comportant pas de voix informatisée, de systèmes de demande d'information automatisés ou de technologies similaires.",
        para2:
          "L'utilisation d'outils de script automatisés pour explorer le site ou tenter de contourner l'API est strictement interdite et peut entraîner le blocage du site web d'Alquant. Des demandes excessives peuvent entraîner un ralentissement de l'accès aux données de votre compte.",
        para3:
          "L'utilisation de formes d'automatisation non basées sur l'API, telles que le scriptage du site Web, est interdite. L'utilisation de ces techniques peut entraîner la suspension permanente de votre compte.",
      },
      indemnificationParagraph: {
        title: "Indemnisation",
        para1:
          "L'utilisateur (et le fonds de l'utilisateur, si l'utilisateur est un représentant d'un fonds) accepte conjointement et solidairement d'indemniser et de dégager de toute responsabilité <b>Alquant SA</b>, et ses agents, fournisseurs d'informations et leurs agents, représentants, directeurs, officiers, actionnaires, partenaires, prédécesseurs, successeurs et assignés, de et contre toute réclamation ou demande, procédure, dommages, responsabilités, pertes ou dépenses, de réclamations faites par un tiers, résultant de l'utilisation par l'utilisateur des informations de <b>Alquant SA</b> qui constitue une violation des conditions d'utilisation ou un acte inapproprié ou une omission.",
        para2:
          "Si un utilisateur fait usage des services ou de tout contenu autrement que de la manière expressément autorisée par les présentes conditions d'utilisation, il risque de violer les droits d'auteur et d'autres lois et peut être soumis à des sanctions. Sans limiter les phrases précédentes, l'utilisateur ne doit pas:",
        para3:
          "S'engager dans ou permettre la revente, l'affichage, la reproduction, la copie, la traduction, la modification, l'adaptation, la création d'œuvres dérivées, la distribution, la transmission, le transfert, la republication, la compilation, la décompilation ou l'ingénierie inverse des services, de tout contenu, de toute partie de celui-ci, ou de toute donnée, de tout autre contenu ou information fournis à l'utilisateur en relation avec l'utilisation des services par Alquant SA ou l'une de ses sources tierces, sous toute forme, média ou technologie existant actuellement ou développé ultérieurement ou sur tout réseau, y compris un réseau local ; supprimer, masquer ou modifier tout avis, clause de non-responsabilité ou autre divulgation apposé sur ou contenu dans les services, y compris tout avis de droit d'auteur, marque de commerce ou autre avis de droits de propriété ou tout autre avis juridique apposé sur ou affiché en association avec le contenu; créer un cadre ou utiliser des techniques de cadrage pour enfermer toute partie des services ou du contenu, sans le consentement écrit préalable exprès de Alquant SA;",
        para4:
          "usurper l'identité d'une personne, ou déclarer faussement ou représenter faussement son affiliation avec une personne, en relation avec toute utilisation des services ; violer ou tenter de violer la sécurité des services ou de tout réseau, serveurs, données, ou ordinateurs ou autre matériel relatif ou utilisé en relation avec les services ou tout contenu, qu'ils soient ou non exploités par Alquant SA ou un tiers; ni utiliser avec les services ou distribuer par leur intermédiaire tout logiciel ou autre outil ou dispositif conçu pour interférer ou compromettre la confidentialité, la sécurité ou l'utilisation des services par d'autres ou les opérations ou les actifs de toute personne;",
        para5:
          "violer toute loi applicable, y compris, mais sans s'y limiter, les lois sur les valeurs mobilières et les lois régissant l'activité d'investissement dans tout pays ; restreindre ou empêcher tout autre utilisateur autorisé d'utiliser et de profiter des services ; afficher, publier, transmettre, reproduire, distribuer ou exploiter de quelque manière que ce soit tout contenu ou toute autre information ou tout autre matériel obtenu par le biais des services à des fins commerciales autres que celles expressément autorisées par les présentes conditions d'utilisation ou par une autorisation préalable expresse de Alquant SA.",
      },
      exclusionLiabilityParagraph: {
        title: "Exclusion de la responsabilité",
        para1:
          "Alquant SA ou toutes les sociétés, partenaires, conseils d'administration, employés ou représentants qui lui sont liés refusent toute responsabilité (y compris pour négligence et envers les tiers) pour les dommages directs ou indirects ou les dommages consécutifs qui peuvent survenir en relation directe ou indirecte avec l'utilisation du site web d'Alquant.",
      },
      reservationsParagraph: {
        title: "Réserves",
        para1:
          "Alquant SA se réserve le droit de modifier toute information, opinion, performance ou autre donnée, lien, etc. à tout moment sans préavis aux utilisateurs.",
        para2:
          "En outre, les conditions d'utilisation peuvent également être modifiées et l'utilisation du site web d'Alquant peut également être limitée ou résiliée sans préavis.",
      },
      placeOfJurisdictionParagraph: {
        title: "Lieu de juridiction",
        para1:
          "Dans la mesure où une situation juridique se présente entre Alquant SA et les utilisateurs du site Web d'Alquant, elle est soumise à la législation suisse. Le seul lieu de juridiction est Lausanne, Suisse.",
      },
    },
  },
  errors: {
    backHome: "Retour à l'accueil",
    error403: {
      paragraphWhenLoggedIn:
        "Vous n'avez pas la permission d'accéder à cette page. Si votre profil est accepté, un administrateur vous donnera bientôt les droits d'accès à cette page. Veuillez vérifier dans les 1-2 prochains jours ouvrables.",
      paragraphWhenLoggedInWithContact: (ctx) =>
        `Vous n'avez pas la permission d'accéder à cette page. Si votre profil est accepté, un administrateur vous donnera bientôt les droits d'accès à cette page. Veuillez vérifier dans les 1-2 prochains jours ouvrables. Si vous n'avez toujours pas reçu l'autorisation d'accès, veuillez nous contacter: ${ctx.named(
          "contactEmail"
        )}.`,
      paragraphWhenNotLoggedIn:
        "Pour accéder à cette page, vous devez vous authentifier. Veuillez cliquer sur le bouton ci-dessous pour vous connecter à votre compte. Si vous n'avez pas encore de compte, veuillez vous inscrire en cliquant sur le bouton ci-dessous.",
      title: "403 - Accès interdit",
      metaInfo: { title: "Plateforme - 403" },
    },
    error404: {
      paragraph: "La page à laquelle vous essayez d'accéder n'existe pas.",
      title: "404 - Page non trouvée",
      metaInfo: { title: "Plateforme - 404" },
    },
    unauthorizedInvestorProfile: {
      modifyInvestorProfile: "Modifier le profil d'investisseur",
      title: "Profil d'investisseur non autorisé",
      unfortunately:
        "Malheureusement, vous ne pouvez pas avoir accès à cette page en raison de votre profil d'investisseur.",
      metaInfo: { title: "Platform - Profil d'investisseur non autorisé" },
    },
  },
  help: {
    contact: {
      address: "Adresse",
      introText:
        "N'hésitez pas à utiliser ce formulaire pour entrer en contact avec nous. Nous serons heureux de vous faire part de vos suggestions ou de répondre à toutes vos questions concernant cette plateforme et nos produits.",
      recaptchaPrivacyPolicy: "Politique de confidentialité",
      recaptchaProtect:
        "Ce site est protégé par reCAPTCHA et les règles {0} et {1} de Google s'appliquent.",
      recaptchaTermsOfService: "Conditions d'utilisation",
      switzerland: "Suisse",
      title: "Contact",
      dialog: {
        confirmMessage: {
          title: "Confirmation",
          text: "Voulez-vous envoyer ce message?",
        },
      },
      form: {
        country: "Pays",
        email: "Email",
        enterMessage: "Saisissez votre message",
        exampleEmail: "john.doe@exemple.com",
        exampleName: "John Doe",
        investorProfileType: "Type d'investisseur",
        name: "Nom",
        sendEmail: "Envoyer un email",
      },
      metaInfo: { title: "Plateforme - Contact" },
      notify: {
        errorMessage: {
          title: "Erreur lors de l'envoi du message",
          text: "Désolé, le message n'a pas pu être envoyé. Réessayez plus tard ou contactez-nous directement par e-mail.",
        },
        messageSent: {
          title: "Message envoyé",
          text: "Le message a été envoyé avec succès. Nous vous répondrons dans un délai de 1 à 2 jours ouvrables.",
        },
      },
    },
    faq: {
      subtitle:
        "Vous trouverez dans la liste suivante toutes les questions les plus fréquemment posées.",
      title: "Vous avez des questions ?",
      metaInfo: { title: "Plateforme - FAQ" },
    },
    knowledgeBase: {
      investmentStrategyTechniques: "Techniques de stratégie d'investissement",
      metaInfoTerm: (ctx) => `Base de connaissances - ${ctx.named("term")}`,
      noResults: "Aucun résultat trouvé.",
      performanceMetrics: "Mesures de la performance",
      search: "Recherche",
      source: "Source",
      subtitle:
        "Trouvez des explications sur des termes techniques généraux ainsi que sur certaines de nos techniques qui sont présentées sur cette plateforme.",
      title: "Bienvenue dans notre base de connaissances",
      alpha: {
        title: "Alpha",
        para1:
          "Le {0} est utilisé pour mesurer la performance supérieure qu'une stratégie ou que son gestionnaire est capable de générer par rapport à un benchmark. Le {1} décrit la performance après déduction des rendements sans risque et du rendement lié au marché (prime de marché * {2}).",
        para2:
          "Le graphique ci-dessus montre une valeur <span class=\"font-bold\">alpha</span> de 4 %, ce qui signifie que, compte tenu de la dépendance de la stratégie vis-à-vis du marché et de l'environnement actuel des taux d'intérêt (rendement sans risque), elle a pu générer une performance supplémentaire de 4 % par rapport à ce que l'on peut attendre.",
        para3:
          'Comme l\'indique le graphique ci-dessus, la composante <span class="font-bold">alpha</span> est le seul facteur de performance qui peut être attribué à la compétence du gestionnaire. En outre, il indique que l\'<span class="font-bold">alpha</span> peut devenir un nombre négatif, ce qui signifie que la performance a été perdue par la gestion active de l\'investissement. Cela dit, une composante <span class="font-bold">alpha</span> positive peut être considérée comme la composante pour laquelle un investisseur est prêt à payer des frais.',
        para4:
          "Plus l'alpha est élevé, meilleure est la stratégie. Un alpha élevé signifie que les asset managers ont réussi à créer de la valeur.",
      },
      annualizedReturn: {
        title: "Rendement annualisé",
        para1:
          "<span class=\"font-bold\">Les rendements annualisés</span> sont des rendements cumulatifs ramenés à une période d'un an. Cela permet aux investisseurs de mieux comparer les rendements de différents actifs qu'ils ont détenus pendant des périodes de temps différentes.",
        para2:
          'Alternativement, les <span class="font-bold">rendements annualisés</span> peuvent également être interprétés comme le rendement annuel qu\'un investisseur aurait obtenu en moyenne en investissant pendant une période donnée.',
        para3:
          "Toutes choses égales par ailleurs, plus le rendement annualisé est élevé, meilleur est l'investissement.",
      },
      annualizedVolatility: {
        title: "Volatilité annualisée",
        para1:
          "La <span class=\"font-bold\">volatilité annualisée</span> est la volatilité d'un investissement ramenée à une période d'un an. La volatilité elle-même mesure l'ampleur des mouvements de prix. Plus techniquement, la volatilité est une mesure statistique de la dispersion des rendements (plus précisément l'écart type des rendements) sur une période donnée. Plus la volatilité est élevée, plus l'investissement est risqué, car plus la volatilité est élevée, plus il est probable que des fluctuations importantes se produisent.",
      },
      beta: {
        title: "Beta",
        para1:
          "Le<span class=\"font-bold\">beta</span> mesure la dépendance des rendements. Il est généralement utilisé pour mesurer la dépendance d'un titre ou d'une stratégie d'investissement par rapport au rendement du marché. Un beta élevé indique que le titre évolue de manière plus agressive que le marché, tandis qu'un beta faible indique que le titre ne capte que partiellement les rendements du marché. Le graphique ci-dessous montre les lignes de tendance d'un investissement à beta élevé et d'un investissement à beta faible et leur performance par rapport au marché, la pente de la ligne de tendance correspondant à la valeur du <span class=\"font-bold\">beta</span>. L'investissement à faible beta présente un <span class=\"font-bold\">beta</span> < 1, ce qui signifie qu'il évolue généralement moins que le marché mais dans la même direction, tandis que l'investissement à beta élevé présente un <span class=\"font-bold\">beta</span> > 1 et génère donc des rendements plus extrêmes que le marché. Ainsi, les rendements dans le graphique sont plus largement répartis que pour l'investissement à faible beta.",
        para2:
          'Bien que rarement trouvées, les valeurs négatives de <span class="font-bold">beta</span> existent et indiqueraient une relation inverse au marché.',
        para3:
          "Lorsqu'ils analysent une stratégie d'investissement, les investisseurs recherchent des stratégies performantes avec un faible beta. Cette constellation indique que la performance du gestionnaire dépend moins des rendements du marché que de ses compétences.",
      },
      calmarRatio: {
        title: "Calmar ratio",
        para1:
          "Un ratio utilisé pour déterminer le rendement par rapport au risque de baisse (drawdown) dans un produit d'investissement.",
        para2:
          "Toutes choses égales par ailleurs, plus le ratio est élevé, meilleur est l'investissement.",
      },
      correlation: {
        title: "Corrélation",
        para1:
          "Le coefficient de corrélation de Pearson (CCP) est une mesure statistique qui évalue la force et la direction d'une relation linéaire entre deux ensembles de données. Il est calculé comme le rapport entre la covariance des deux variables et le produit de leurs écarts types. Cette normalisation donne au PCC une valeur comprise entre -1 et 1, où -1 indique une corrélation linéaire négative parfaite, 1 une corrélation linéaire positive parfaite et 0 aucune corrélation linéaire. Il est important de noter que la PCC ne détecte que les relations linéaires, et non les autres types de corrélations",
        para2:
          "Étant donné des données appariées {(X,Y)} de n paires, la PCC sera calculée à l'aide de la formule suivante :",
      },
      cumulativeReturn: {
        title: "Rendement cumulé",
        para1:
          "Présenté sous forme de pourcentage, le <span class=\"font-bold\">rendement cumulatif</span> d'un investissement est le montant total que l'investissement a gagné ou perdu au fil du temps.",
        para2:
          "Le <span class=\"font-bold\">rendement cumulé</span> peut être facilement déduit d'un graphique qui montre la croissance d'un investissement tel que celui ci-dessus. En utilisant uniquement la première et la dernière valeur du graphique, on peut obtenir le rendement cumulatif total pour une période de temps donnée.",
      },
      informationRatio: {
        title: "Ratio d'information",
        para1:
          "Investment strategies are often measured against a certain benchmark which mostly consists of one or a mix of many indices. Lorsqu'un gestionnaire d'investissement bat l'indice de référence, il est intéressant pour l'investisseur de savoir à quel prix l'objectif a été atteint. Le <span class=\"font-bold\">ratio d'information</span> est une mesure permettant de comparer le rendement excédentaire par rapport à l'indice de référence et le tracking error qui est une mesure de la cohérence entre la stratégie et l'indice de référence.",
        para2:
          "Le <span class=\"font-bold\">ratio d'information</span> est calculé de la même manière que le ratio de sharpe en partant du principe que l'indice de référence est égal à un rendement sans risque avec une volatilité de 0%.",
        para3:
          "Toutes choses égales par ailleurs, si ce ratio est positif, plus il est élevé, plus l'investissement est robuste. Un ratio élevé signifie que les fluctuations quotidiennes des excess returns par rapport à l'indice de référence sont faibles par rapport au excess return moyen par rapport à l'indice de référence.",
      },
      kurtosis: {
        title: "Kurtosis",
        para: "L'aplatissement indique l'ampleur des pics d'une distribution. Plus l'aplatissement d'une distribution est élevé, plus elle est concentrée autour de sa moyenne",
      },
      m2RiskAdjustedPerf: {
        title: "Mesure M2",
        para1:
          "La performance ajustée au risque M2 (M au carré), également connue sous le nom de mesure M2, évalue les rendements ajustés au risque d'un portefeuille d'investissement. Elle évalue les rendements du portefeuille en tenant compte de son risque par rapport à un indice de référence, tel que le marché. Essentiellement, il représente la différence entre le rendement excédentaire ajusté d'un portefeuille (P) et le marché, en ajustant le portefeuille pour qu'il corresponde à la volatilité du marché. Issu du ratio de Sharpe, il offre un avantage substantiel en exprimant les résultats en pourcentage de rendement, contrairement au ratio de Sharpe, qui est un chiffre non négatif et moins pratique pour de nombreux investisseurs. Cette caractéristique de la mesure de Modigliani la rend nettement plus facile à comprendre et à appliquer",
        para2:
          "Toutes choses égales par ailleurs, si le rendement de l'investissement est supérieur au risk-free rate, plus la mesure M2 est élevée, meilleur est l'investissement. En effet, dans ce cas, la mesure M2 augmente lorsque le rendement de la stratégie augmente, ou lorsque la volatilité de la stratégie diminue par rapport à celle de son indice de référence.",
      },
      maximumDrawdown: {
        title: "Drawdown maximal",
        para1:
          'Le <span class="font-bold">drawdown maximal</span> est la perte maximale observée entre un pic et un creux d\'un portefeuille, avant qu\'un nouveau pic ne soit atteint. Le <span class="font-bold">drawdown maximal</span> est un indicateur du risque de baisse sur une période donnée.',
        para2:
          'Le <span class="font-bold">drawdown maximal</span> est un indicateur utilisé pour évaluer le risque relatif d\'une stratégie par rapport à une autre, car il se concentre sur la préservation du capital, qui est une préoccupation essentielle pour la plupart des investisseurs. Un faible <span class="font-bold">drawdown maximal</span> est préférable car il indique que les pertes des investissements ont été faibles. Si un investissement ne perdait jamais un centime, le <span class="font-bold">drawdown maximal</span> serait de zéro. Le pire <span class="font-bold">drawdown maximal</span> possible serait de 100 %, ce qui signifie que l\'investissement n\'a aucune valeur.',
        para3:
          "Toutes choses étant égales par ailleurs, plus la valeur absolue du drawdown maximal est faible (c'est-à-dire plus le drawdown maximal est proche de 0 %), plus l'investissement est fiable.",
        keyPoints: "Point clés",
        keyPoint1:
          "Le drawdown maximal est une mesure de la plus grande chute du prix d'un actif entre un pic et un creux.",
        keyPoint2:
          "Le drawdown maximal est considéré comme un indicateur du risque de baisse, un drawdown maximum important suggérant que les mouvements de baisse pourraient être volatils.",
        keyPoint3:
          "Si le drawdown maximal mesure la perte la plus importante, il ne tient pas compte de la fréquence des pertes, ni de l'ampleur des gains éventuels.",
      },
      metaInfo: { title: "Plateforme - Base de connaissances" },
      omegaRatio: {
        title: "Ratio oméga",
        para1:
          "Le ratio oméga est une mesure de performance risque-rendement. Il calcule le ratio pondéré par la probabilité des gains par rapport aux pertes",
        para2:
          "Toutes choses égales par ailleurs, plus le ratio est élevé, meilleur est l'investissement.",
      },
      returnVolatilityRatio: {
        title: "Ratio rendement/volatilité",
        para1:
          'Le <span class="font-bold">ratio rendement/volatilité</span> représente le rendement moyen obtenu par unité de volatilité (écart-type), qui peut être interprété comme une unité de risque. Il renseigne donc sur la manière dont l\'investisseur est rémunéré pour le risque pris (plus le <span class="font-bold">rapport rendement/volatilité</span> est élevé, plus la compensation du risque est importante).',
        para2:
          'Généralement, tout <span class="font-bold">ratio rendement/volatilité</span> supérieur à 1.0 sur une période prolongée est considéré comme bon car cela signifie que les récompenses sont supérieures au risque pris. Par exemple, si la volatilité annualisée est inférieure au rendement annualisé, le <span class="font-bold">ratio rendement/volatilité</span> sera supérieur à 1.0.',
        para3:
          'Ainsi, le <span class="font-bold">ratio rendement/volatilité</span> permet aux investisseurs de comparer des investissements très différents selon les mêmes critères. Le <span class="font-bold">ratio rendement/volatilité</span> permet de comparer des pommes avec des oranges.',
        keyPoints: "Points clés",
        keyPoint1:
          "Un <span class=\"font-bold\">ratio rendement/volatilité</span> élevé indique que l'investisseur est bien rémunéré pour le risque qu'il a pris.",
        keyPoint2:
          "Le <span class=\"font-bold\">ratio rendement/volatilité</span> ajuste la performance passée d'un portefeuille au risque pris par l'investisseur et permet ainsi de comparer des investissements très différents.",
      },
      sharpeRatio: {
        title: "Ratio de sharpe",
        para1:
          "Le <span class=\"font-bold\">ratio de sharpe</span> est une mesure du rendement ajusté au risque. Il compare le rendement excédentaire par rapport à un investissement sans risque au risque qui a été pris pour obtenir ce rendement, le risque pour l'investisseur étant défini comme la volatilité. Le ratio permet de comprendre le rendement obtenu par l'unité de risque et peut être utilisé pour comparer plusieurs opportunités d'investissement.",
        para2:
          "Étant donné qu'aucun investissement n'est totalement sans risque, tout titre présentant des risques minimes peut être utilisé comme indicateur de rendement sans risque. En général, les obligations d'État de pays stables conviennent bien. Nous utilisons les taux LIBOR à court terme pour calculer le rendement sans risque.",
        para3:
          "Toutes choses égales par ailleurs, si ce ratio est défini (c'est-à-dire s'il est positif), plus il est élevé, plus l'investissement est robuste. Un ratio élevé signifie que les fluctuations quotidiennes des excess returns sont faibles par rapport à l'excess return moyen.",
      },
      skewness: {
        title: "Coefficient d'asymétrie",
        para1:
          'La fréquence d\'apparition de rendements importants dans une direction particulière est mesurée par l\'<span class="font-bold">asymétrie</span>. Si la fréquence d\'occurrence des grands rendements négatifs par rapport aux grands rendements positifs est plus élevée, alors la distribution présente une <span class="font-bold">asymétrie</span> négative. Si la fréquence des grands rendements positifs est supérieure à celle des grands rendements négatifs, la distribution présente une <span class="font-bold">asymétrie</span> positive.',
        para2:
          "Les investisseurs doivent s'intéresser à l'<span class=\"font-bold\">asymétrie</span> car elle prend en compte les extrêmes des rendements au lieu de se concentrer uniquement sur la moyenne. Les investisseurs à court et moyen terme, en particulier, doivent s'intéresser aux extrêmes, car ils sont moins susceptibles de conserver une stratégie suffisamment longtemps pour être sûrs que la moyenne s'arrangera d'elle-même.",
      },
      sortinoRatio: {
        title: "Ratio de Sortino",
        para1:
          "Le ratio de Sortino est similaire au ratio de Sharpe, sauf qu'il utilise le risque de baisse (volatilité à la baisse) dans le dénominateur. Étant donné que la variabilité à la hausse n'est pas nécessairement une mauvaise chose, le ratio de Sortino est parfois préférable au ratio de Sharpe",
        para2:
          "Toutes choses égales par ailleurs, plus le ratio est élevé, meilleur est l'investissement.",
      },
      trackingError: {
        title: "Ecart de suivi",
        para1:
          "L'<span class=\"font-bold\">erreur de suivi</span> est une mesure du risque d'un portefeuille d'investissement dû aux décisions de son gestionnaire de s'écarter de son indice de référence. Plus elle est élevée, plus le gestionnaire est actif dans le choix de s'écarter de son indice de référence.",
        para2:
          "Mathématiquement, une façon pertinente de l'estimer est de calculer l'écart type de la différence de rendement entre le portefeuille et le benchmark. Celle que nous présentons sur cette plateforme est annualisée.",
        para3:
          "Il est important de garder à l'esprit qu'elle ne représente pas la pertinence des décisions du gestionnaire, mais plutôt l'impact qu'elles ont sur le portefeuille final. Ainsi, deux portefeuilles aux performances excellentes ou mauvaises peuvent tous deux présenter des <span class=\"font-bold\">erreurs de suivi</span> élevées par rapport à leur indice de référence.",
        para4:
          "Une faible tracking error signifie qu'un investissement ne s'écarte pas beaucoup de son indice de référence. Une tracking error élevée signifie que l'investissement s'écarte beaucoup de son indice de référence.",
      },
      treynorRatio: {
        title: "Rapport de Treynor",
        para: 'Semblable au ratio de Sharpe, le ratio de Treynor est une mesure de l\'efficacité qui utilise la relation entre le rendement annualisé ajusté au risque et le risque. Contrairement au ratio de Sharpe, le ratio de Treynor utilise le risque "de marché" (bêta) au lieu du risque total (écart-type). Une bonne efficacité de la performance est mesurée par un ratio élevé',
      },
      valueAtRisk: {
        title: "Valeur à risque",
        para1:
          "La VaR est une statistique qui quantifie l'ampleur des pertes possibles au sein d'un portefeuille sur une période donnée et avec une probabilité définie. VaR 1% sur 1 mois = x% de perte en 1 mois avec une probabilité de 1%. Plus elle est basse, mieux c'est !",
        para2:
          "En pratique, il s'agit du quantile des rendements de la stratégie correspondant à la valeur p (1 % ou 5 %).",
      },
    },
  },
  home: {
    metaInfo: { title: (ctx) => `${ctx.named("orgName")} - Plateforme` },
    modifyInvestorProfile: "Modifier le profil d'investisseur",
    noProductsAvailable: "Aucun produit disponible pour votre rôle et votre profil d'investisseur.",
    search: "Recherche",
    showMoreProducts: "Afficher plus de produits",
  },
  products: {
    analysis: {
      analysisPeriod: "Période d'analyse",
      backtest: "Backtest",
      backtestOrLive: "Backtest ou live",
      benchmarks: "Benchmarks",
      live: "Live",
      liveProduct: (ctx) => `${ctx.named("productName")} live`,
      shareClass: "Classe d'action",
      graphs: {
        considerApproximation: "Ces valeurs doivent être considérées comme une approximation.",
        weHave: "Nous avons",
        weWant: "Nous voulons",
        titles: {
          assetClassesWeights: "Pondération des classes d'actifs",
          beta: "Beta",
          constituents: "Constituants",
          contango: "Contango",
          diffMonthlyReturns: "Diff. rendements mensuels",
          drawdowns: "Drawdown",
          exposureIndices: "Exposition aux indices",
          exposureOverTime: "Exposition dans le temps",
          exposureVolatility: "Exposition à la volatilité",
          indicators: "Indicateurs",
          monthlyReturns: "Rendements mensuels",
          performance: "Performance",
          ratioVixFutures: "Ratio VIX futures",
          recentDataOverview: "Aperçu des données récentes",
          relativeDrawdowns: "Drawdowns relatifs",
          relativePerformance: "Performance relative",
          riskAdjustedPerformance: "Performance ajustée au risque",
          rollingVRatioLevel: "Niveau du roulement du v-ratio",
          statistics: "Statistiques",
          returnProfile: "Profil de rendement",
          top10ConstituentsWeight: "Les 10 plus grands constituants actuels",
          top5ConstituentsWeight: "Poids des 5 premiers constituants",
          vegaIndicatorConditions: "Conditions de l'indicateur Vega",
          vix: "VIX",
          vixPremium: "VIX premium",
          volatility: "Volatilité",
          weightedPerformance: "Performance pondérée",
          yearlyReturns: "Rendements annuels",
        },
        tooltips: {
          beta: "Ce graphique montre le beta de notre produit par rapport à notre référence. Pour le calculer, nous utilisons les données de la dernière année disponible, c'est pourquoi la mesure est absente pendant la première année de vie de notre produit.",
          relativePerformance:
            "Ce graphique présente une comparaison de la performance de notre produit et de la performance de notre benchmark. Pour ce faire, nos rendements cumulés sont divisés par les rendements cumulés de notre benchmark. Cela signifie qu'à 100%, les deux performances sont similaires, sinon plus le chiffre est élevé, meilleure est la performance de notre produit par rapport au benchmark.",
          riskAdjustedPerformance:
            "Ce graphique montre la performance ajustée au risque. Pour la calculer, nous utilisons les données de la dernière année disponible, c'est pourquoi la mesure est absente pendant la première année de vie de notre produit. La performance ajustée au risque est définie comme le rapport entre le rendement annualisé et la volatilité annualisée.",
          volatility:
            "Ce graphique montre la volatilité annualisée (c'est-à-dire l'écart type). Pour la calculer, nous utilisons les données de la dernière année disponible, c'est pourquoi la mesure est manquante pendant la première année de vie de notre produit.",
        },
      },
      metaInfo: { title: (ctx) => `${ctx.named("productName")} - Analyse` },
      tab: {
        historical: "Historique",
        historicalText:
          'Pour les produits déjà lancés, nous distinguons la section "Analyse" entre "Analyse live" et "Analyse historique". La section "Analyse historique" vise à illustrer la performance du produit avant son lancement. Les performances sont basées sur une simulation réaliste de la stratégie. Cependant, aucun actif sous gestion n\'a été impliqué pendant la période correspondante.',
        internal: "Interne",
        live: "Live",
        liveText:
          'Pour les produits déjà lancés, nous distinguons la section "Analyse" entre "Analyse live" et "Analyse historique". La section "Analyse live" illustre les performances depuis le lancement du produit.',
      },
    },
    documentDownload: {
      failure:
        "Le téléchargement du fichier a échoué. Veuillez nous contacter si cela est inattendu.",
      needAuth:
        "Vous devez être authentifié pour télécharger ce document. Veuillez vous connecter.",
      preparing: "Le fichier est  en préparation pour le téléchargement....",
      seeProductOverview: "Voir l'aperçu du produit",
      success: "Le fichier a été téléchargé avec succès..",
      metaInfo: {
        title: (ctx) => `${ctx.named("productName")} - Documents à télécharger`,
      },
    },
    documents: {
      addFile: "Ajouter un fichier",
      document: "Document",
      nameValidationError:
        "Le nom est déjà utilisé par un autre de vos documents, veuillez en choisir un autre.",
      noDocument: "Aucun document n'est fourni pour ce produit.",
      noArchivedDocument: "Aucun document archivé pour ce produit.",
      noteDragAndDrop:
        "Remarque : Faites glisser et déposez les lignes pour réorganiser les documents.",
      shareClass: "Classe d'action",
      archiveIconTooltip: "Archiver",
      unarchiveIconTooltip: "Désarchiver",
      archivedDocumentsCardTitle: "Documents archivés",
      dialog: {
        fileDeletion: {
          title: "Suppression du fichier",
          text: "Vous allez supprimer complètement le fichier et vous ne pourrez plus y accéder. Vous êtes sûr ?",
        },
        fileArchiving: {
          title: "Archivage de fichiers",
          text: "Vous allez archiver ce fichier, ce qui signifie qu'il sera placé dans vos \"Documents archivés\" (juste en dessous des documents). Notez qu'il sera possible de le désarchiver. Voulez-vous l'archiver?",
        },
        fileUnarchiving: {
          title: "Désarchivage de fichiers",
          text: "Vous allez désarchiver ce fichier, ce qui signifie qu'il retournera dans vos documents normaux. Voulez-vous le désarchiver?",
        },
        needLogin: {
          text: "Pour télécharger ce document, vous devez être authentifié.",
          title: "Vous devez être authentifié",
        },
      },
      metaInfo: { title: (ctx) => `${ctx.named("productName")} - Documents` },
      notify: {
        savingDocumentsError:
          "Une erreur s'est produite lors de la mise à jour des documents. Réessayez plus tard ou contactez-nous.",
        savingDocumentsSuccess: "Les documents ont été mis à jour avec succès.",
        fileDownloadError: "Erreur lors du téléchargement du document, veuillez nous contacter.",
      },
      prompt: {
        fileDisplayName: "Nom d'affichage du fichier",
        loginRequired: "Connexion requise ?",
        uploadFile: {
          acceptText: "Sauvegarde de fichier",
          file: "Fichier",
          fileLanguage: "Langue du fichier",
          shouldConsiderLastFactsheet:
            "Ce fichier doit-il être considéré comme la dernière factsheet du produit?",
          title: "Sauvegarde du fichier",
        },
        editFile: {
          title: "Modifier le fichier",
        },
      },
    },
    embedded: {
      perfGraph: {
        metaInfo: {
          title: (ctx) => `${ctx.named("productName")} - Graphique de performance embarqué`,
        },
      },
      perfTable: {
        currency: "Monnaie",
        inceptionDate: "Date de création",
        name: "Nom",
        lastPriceAsOf: "Dernier prix au",
        mtd: "MAJ",
        si: "Depuis création",
        y1: "-1A",
        y3: "-3A",
        ytd: "YTD",
        metaInfo: {
          title: (ctx) => `${ctx.named("productName")} - Tableau de performance embarqué`,
        },
      },
      statsTable: {
        feesIncluded: "Les frais sont inclus.",
        metaInfo: {
          title: (ctx) => `${ctx.named("productName")} - Tableau des statistiques embarqué`,
        },
      },
    },
    events: {
      covidCrisis: "Crise COVID-19",
      crisis2022: "Crise de 2022",
      december2018Crisis: "Crise de décembre 2018",
      euroCrisis: "Crise de l'euro",
      financialCrisis: "Grande crise financière",
      alvolaLive: "Alvola Live",
      convexusLive: "Convexus Live",
      lstsLive: "LSTS Live",
      protechLive: "ProTech Live",
      sinceInception: "Depuis la création",
      ynnovationLive: "Ynnovation Live",
      calmgard: {
        calmgardCovid19Crisis: "Crise du Covid 19",
        calmgardEuroCrisisOne: "Crise de l'euro I",
        calmgardEuroCrisisTwo: "Crise de l'euro II",
        calmgardGreekAndChinaCrisis: "Crise grecque et chinoise",
        calmgardInterestRateSpeculationOne: "Spéculation sur les taux d'intérêt I",
        calmgardInterestRateSpeculationTwo: "Spéculation sur les taux d'intérêt II",
        calmgardPostFinancialCrisis: "L'après-crise financière",
        calmgardUkraineWar: "Guerre d'Ukraine",
      },
      falgom: {
        falgomCoronavirusPandemic: "Pandémie de coronavirus",
        falgomFedQuantitativeTapering: "Réduction politique quantitative FED",
        falgomGlobalFinancialCrisis: "Crise financière mondiale",
        falgomGrowthSlowdownChina: "Ralentissement croissance Chine",
        falgomRecessionEurope: "Récession Europe",
        falgomVolmageddon: "Volmageddon",
      },
    },
    factsheet: {
      firstDateToInclude: "Première date à inclure (aaaa-mm-jj)",
      lastDateToInclude: "Dernière date à inclure (aaaa-mm-jj)",
      nameValidationError:
        "Le nom est déjà utilisé par un autre de vos documents, veuillez en choisir un autre.",
      shareClass: "Classe d'action",
      year: "Année",
      cardTitle: {
        assetClasses: "Classes d'actifs",
        contacts: "Contacts",
        currencyExposure: "Risque d'exposition",
        description: "Description",
        disclaimer: "Clause de non-responsabilité",
        drawdowns: "Drawdown",
        historicalPrices: "Prix historiques",
        instrumentTypes: "Types d'instruments",
        monthlyComment: "Commentaire mensuel",
        monthlyPerformance: "Performance mensuelle",
        monthlyReturns: "Rendements mensuels",
        productDetails: "Détails du produit",
        regions: "Régions",
        relativePerformance: "Performance relative",
        riskProfile: "Indicateur synthétique de risque et de rendement",
        sectors: "Secteurs",
        shareClasses: "Classes d'action",
        topHoldings: "Principales positions",
        volatility: "Volatilité",
        yearlyPerformance: "Performance annuelle",
      },
      dragAndDrop: {
        addGenericComponent: "Ajouter un composant",
        availableGenericComponents: "Composants génériques",
        availablePredefinedComponents: "Composants prédéfinis",
        editFactsheet: "Modifier la factsheet",
        componentTooBig: "Le composant est trop grand pour être contenu sur une page entière.",
        saveSuccessText: "La configuration de la factsheet a été mise à jour avec succès.",
        stopEdit: "Arrêter la modification",
        footer: {
          title: "Pied de page",
          page: "Page",
        },
        forcedElementDeletionWarning: {
          componentsWereRemoved:
            "Certains composants ont été retirés de votre mise en page car leur taille dépasse la zone affichable d'une seule page ou la colonne qui les contient est trop grande. Les ID des composants retirés sont :",
          resolveSolution:
            "Pour résoudre ce problème, réduisez la hauteur des composants concernés, placez le composant dans une nouvelle ligne unique, ou contactez-nous.",
          title: "Retrait forcé de composants de la mise en page",
        },
        header: {
          title: "En-tête",
        },
        style: {
          title: "Style",
        },
      },
      generateFactsheet: {
        downloadFactsheet: "Télécharger la factsheet",
        generatingFactsheet: "Génération de la factsheet...",
        saveFactsheet: 'Sauvegarder la factsheet dans "Documents"',
        notify: {
          generatingError: "Erreur lors de la génération de la factsheet, veuillez nous contacter.",
          savedInDocumentsSection: "Votre factsheet a été enregistrée dans la section Documents.",
        },
        prompt: {
          acceptText: "Sauvegarder la factsheet",
          considerLastFactsheet:
            "Ce fichier doit-il être considéré comme la dernière factsheet du produit?",
          fileDisplayName: "Nom d'affichage du fichier",
          loginRequired: "Connexion requise?",
          title: "Génération et sauvegarde de la factsheet",
        },
      },
      metaInfo: { title: (ctx) => `${ctx.named("productName")} - Factsheet` },
      statistics: {
        title: "Statistiques",
        periods: {
          1: "1M",
          3: "3M",
          6: "6M",
          ytd: "YTD",
          12: "1A",
          36: "3A",
          60: "5A",
          max: "MAX",
        },
      },
      statisticsWithPeriods: {
        title: "Performance",
        periods: {
          1: "1M",
          3: "3M",
          6: "6M",
          ytd: "YTD",
          12: "1A",
          36: "3A",
          60: "5A",
          max: "MAX",
        },
      },
      shareClassesTable: {
        bloomberg: "Bloomberg",
        class: "Classe",
        currency: "Monnaie",
        inception: "Date de création",
        isin: "ISIN",
        mgmtFee: "Frais de gestion",
        mtdPerf: "Perf. MAJ",
        nav: "VNI",
        navDate: "Date du VNI",
        perfFee: "Frais de perf.",
        siPerf: "Perf. lanc.",
        ytdPerf: "Perf. YTD",
      },
    },
    months: {
      short: {
        jan: "Janv",
        feb: "Févr",
        mar: "Mars",
        apr: "Avr",
        may: "Mai",
        jun: "Juin",
        jul: "Juill",
        aug: "Août",
        sep: "Sept",
        oct: "Oct",
        nov: "Nov",
        dec: "Déc",
      },
    },
    overview: {
      assetClasses: "Classes d'actifs",
      currencyExposure: "Risque d'exposition",
      keyCharacteristics: "Caractéristiques clés",
      historicalPrices: "Prix historiques",
      itd: "Rendement ITD",
      instrumentTypes: "Types d'instruments",
      itdDescription: (ctx) =>
        `ITD (inception-to-date) est une période, à partir de la création du produit (${ctx.named(
          "date"
        )}), et allant jusqu'au jour présent.`,
      lastChange: "Dernier changement",
      lastDayChange: "Changement du dernier jour",
      lastWeekChange: "Changement de la dernière semaine",
      lastMonthChange: "Changement du dernier mois",
      lastQuarterChange: "Changement du dernier trimestre",
      lastYearChange: "Changement de la dernière année",
      lastPrice: "Dernier prix",
      monthlyReturns: "Rendements mensuels",
      performance: "Performance",
      productDetails: "Détails du produit",
      productTimeline: "Chronologie du produit",
      regions: "Régions",
      sectors: "Secteurs",
      shareClass: "Classe d'action",
      topHoldings: "Principales positions",
      yearlyReturns: "Rendements annuels",
      ytd: "YTD",
      ytdDescription:
        "Le cumul annuel jusqu'à ce jour ou plus simplement année à ce jour (AAJ) (en anglais, year-to-date ou YTD) est un terme utilisé en finance et en comptabilité pour définir la période qui s'étend du début de l'année en cours jusqu'au jour présent.",
      dragAndDrop: {
        addGenericComponent: "Ajouter un composant",
        availableGenericComponents: "Composants génériques",
        availablePredefinedComponents: "Composants prédéfinis",
        editOverview: "Modifier la vue d'ensemble",
        saveSuccessText: "La configuration de la vue d'ensemble a été mise à jour avec succès",
        stopEdit: "Arrêter l'édition",
      },
      follow: {
        buttonText: "Suivre",
        buttonTextFollowing: "Suivi",
        notify: {
          error:
            "Il y a eu une erreur. Essayez encore ou contactez-nous pour résoudre le problème.",
          followSuccess: (ctx) => `Vous suivez maintenant ${ctx.named("productName")}.`,
          unfollowSuccess: (ctx) => `Vous ne suivez plus ${ctx.named("productName")}.`,
        },
        promptNotFollowing: {
          acceptText: "Suivre",
          text: "En suivant ce produit, vous acceptez que nous vous envoyons des informations sur le produit de temps en temps. Vous serez toujours capable de ne plus suivre le produit pour arrêter de recevoir ce genre d'information.",
          title: "Suivre le produit",
        },
        promptFollowing: {
          acceptText: "Arrêter de suivre",
          text: "Vous suivez actuellement ce produit. Voulez-vous arrêter de le suivre, ce qui veut dire que vous ne recevrez plus d'information à ce sujet?",
          title: "Arrêter de suivre le produit",
        },
        promptNotLoggedIn: {
          cancelText: "Créer un compte",
          text: "Si vous voulez pouvoir suivre ce produit, vous devez être connecté à votre compte. Si vous n'avez pas encore de compte, vous pouvez en créer un gratuitement.",
          title: "Suivre le produit",
        },
      },
      metaInfo: { title: (ctx) => `${ctx.named("productName")} - Aperçu` },
      statsOptions: {
        1: "1 mois",
        3: "3 mois",
        6: "6 mois",
        ytd: "Depuis le début d'année",
        12: "1 an",
        36: "3 ans",
        60: "5 ans",
        max: "Depuis la création",
      },
    },
    useCase: {
      allocationAlvola: "Allocation à Alvola",
      biggestDrawdowns: (ctx) => `Les plus gros drawdowns de ${ctx.named("benchmark")}`,
      description:
        "Notre cas d'utilisation illustre comment l'ajout de la stratégie Alquant Alvola à un portefeuille d'actions existant peut augmenter les rendements à long terme tout en réduisant le drawdown maximum. Sélectionnez le portefeuille d'actions de votre choix et l'allocation à la stratégie Alquant Alvola et découvrez comment la combinaison aurait été performante dans le passé (avant le lancement de notre produit, le 02 juin 2021). En outre, vous trouverez également la performance pendant les 10 plus grandes périodes de drawdown de votre portefeuille d'actions sélectionné.",
      drawdowns: "Drawdowns",
      equityPortfolio: "Portefeuille d'actions",
      historicalResults: "Résultats historiques",
      portfolioAllocation: "Allocation du portefeuille",
      protectPortfolio: "Protégez votre portefeuille avec Alvola",
      metaInfo: {
        title: (ctx) => `${ctx.named("productName")} - Cas d'utilisation`,
      },
    },
  },
  settings: {
    metaInfo: { title: (ctx) => `Paramètres - ${ctx.named("name")}` },
    changePassword: {
      buttonReset: "Envoyer un e-mail pour réinitialiser le mot de passe",
      title: "Modifier le mot de passe",
      notify: {
        success: {
          title: "Email de réinitialisation envoyé",
          text: (ctx) =>
            `Un email de réinitialisation a été envoyé à la boîte de réception de ${ctx.named(
              "email"
            )}. S'il n'apparaît pas rapidement, vérifiez la boîte à spam.`,
        },
        failure:
          "Nous n'avons pas pu changer votre mot de passe, veuillez nous contacter en utilisant le formulaire de contact pour corriger cette erreur.",
      },
    },
    deleteAccount: {
      deleteYourAccount: "Supprimer votre compte",
      title: "Supprimer le compte",
      notify: {
        success: "Votre compte a été supprimé avec succès.",
      },
      dialog: {
        permanentlyDeleteData: {
          text: "La suppression de votre compte entraînera la suppression définitive de toutes vos données.",
          title: "Confirmer",
        },
        recentLoginNeeded: "Besoin d'une connexion récente",
      },
    },
    editProfile: {
      company: "Entreprise",
      email: "Email",
      firstName: "Prénom",
      investorProfileCountry: "Pays",
      investorProfileType: "Type d'investisseur",
      lastName: "Nom de famille",
      phone: "Téléphone",
      title: "Modifier le profil",
      requiredFields:
        'Les champs marqués d\'un <span class="text-danger">*</span> sont obligatoires.',
      dialog: {
        askValidation: {
          title: "Vérification des données personnelles",
          text: "Veuillez vérifier vos données personnelles et les enregistrer lorsque vous avez terminé.",
        },
      },
      notify: {
        errorText:
          "Nous n'avons pas pu mettre à jour vos données, veuillez nous contacter en utilisant le formulaire de contact pour corriger cette erreur.",
        successText: "La mise à jour a été un succès.",
      },
    },
    overview: {
      email: "Email",
      name: "Nom",
      title: "Aperçu du compte",
    },
  },
};
